const FacilitiesPoint = {
  podium: [
    {
      title: "Rainforest Canopy",
      unit: 1,
      top: 558,//[879,869,865,856,854,850,849,843,845,842,845,845,852,854,860,861,866,878,884,894,902,912,922,937,948,959,974,986,998,1014,1028,1041,1055,1073,1082,1101,1115,1125,1142,1156,1173,1181,1198,1210,1223,1234,1245,1256,1268,1279,1284,1294,1304,1307,1314,1314,1316,1325,1325,1323,1328,1318,1315,1312,1308,1302,1298,1293,1277,1271,1262,1256,1240,1236,1224,1203,1190,1183,1166,1151,1138,1125,1103,1092,1078,1060,1046,1030,1013,1003,986,980,963,948,934,926,918,905,891,880,878],
      left: 1064,//[1779,1794,1808,1829,1841,1863,1872,1896,1910,1927,1949,1962,1979,1996,2010,2029,2037,2060,2074,2087,2101,2111,2126,2136,2148,2155,2165,2169,2180,2184,2188,2189,2190,2196,2194,2194,2192,2192,2185,2180,2175,2166,2158,2149,2139,2132,2118,2110,2094,2079,2062,2050,2033,2016,2002,1987,1967,1951,1934,1916,1896,1885,1865,1849,1831,1812,1795,1783,1766,1745,1741,1728,1718,1707,1697,1683,1676,1675,1660,1660,1652,1652,1648,1643,1644,1649,1654,1655,1657,1661,1666,1671,1682,1696,1704,1717,1726,1741,1752,1768,1784],
      perspective: "rainforest",
      full360: "rainforest",
    listed: true,
},
    {
      title: "Nature Playscape",
      unit: 1,
      top: 483,//[917,931,948,965,978,992,1011,1025,1040,1059,1072,1088,1099,1124,1136,1152,1168,1181,1195,1208,1218,1229,1245,1253,1261,1264,1270,1279,1283,1289,1291,1290,1290,1290,1287,1289,1285,1281,1272,1270,1261,1252,1241,1233,1221,1210,1199,1185,1170,1162,1142,1128,1115,1092,1081,1066,1051,1034,1020,1000,986,974,953,938,925,911,896,883,868,861,845,834,824,814,806,794,791,786,782,782,774,775,772,778,770,774,778,782,788,791,803,809,818,826,837,846,860,873,883,902,910],
      left: 816,//[2191,2197,2205,2209,2215,2217,2225,2220,2220,2220,2217,2212,2207,2206,2196,2182,2180,2167,2159,2143,2130,2116,2106,2087,2073,2056,2036,2018,2000,1985,1962,1943,1927,1907,1890,1871,1851,1832,1815,1798,1779,1762,1746,1733,1716,1706,1694,1682,1670,1660,1651,1646,1641,1632,1630,1628,1623,1622,1624,1623,1629,1635,1638,1643,1652,1661,1670,1680,1690,1704,1717,1728,1743,1757,1774,1792,1811,1827,1847,1861,1879,1900,1918,1934,1954,1973,1990,2008,2025,2045,2062,2078,2092,2107,2123,2136,2149,2160,2169,2183,2194],
      perspective: "playscape",
      full360: null,
    listed: true,
},
    {
      title: "Study Garden",
      unit: 1,
      top: 497,//[985,964,941,920,900,883,859,844,825,802,793,772,768,755,739,731,728,715,713,712,710,706,700,708,710,712,720,733,736,748,759,770,780,800,811,832,846,865,887,903,926,947,968,992,1012,1040,1060,1087,1111,1126,1156,1176,1195,1223,1242,1263,1283,1306,1321,1340,1356,1374,1386,1402,1414,1414,1428,1436,1449,1452,1460,1458,1457,1452,1448,1445,1444,1432,1425,1411,1399,1388,1372,1348,1339,1314,1300,1284,1264,1230,1218,1196,1175,1151,1131,1105,1081,1057,1030,1008,984],
      left: 1200,//[1504,1513,1517,1532,1542,1555,1569,1586,1601,1625,1645,1667,1684,1708,1736,1746,1778,1808,1831,1862,1879,1900,1927,1967,1993,2017,2045,2070,2098,2118,2141,2161,2183,2207,2225,2243,2260,2278,2292,2303,2315,2323,2334,2340,2347,2348,2352,2350,2348,2348,2347,2336,2330,2322,2312,2294,2280,2268,2251,2232,2216,2188,2165,2147,2116,2094,2075,2041,2019,1989,1962,1935,1907,1878,1855,1820,1797,1777,1745,1720,1695,1673,1650,1625,1612,1594,1571,1555,1545,1530,1519,1508,1507,1504,1495,1492,1493,1495,1494,1499,1505],
      perspective: "study",
      full360: null,
    listed: true,
},
    {
      title: "Glamping Forest",
      unit: 1,
      top: 241,//[1371,1373,1374,1366,1367,1354,1354,1340,1328,1314,1303,1286,1272,1254,1235,1224,1208,1191,1173,1147,1130,1116,1093,1073,1055,1036,1014,997,979,964,942,921,906,891,873,857,847,834,820,810,800,790,784,770,764,761,757,748,744,742,745,744,747,747,756,765,767,777,784,790,801,818,836,856,863,880,894,921,930,953,972,990,1008,1027,1043,1065,1084,1100,1118,1142,1166,1190,1204,1221,1241,1262,1280,1300,1318,1344,1345,1354,1363,1373,1382,1394,1380,1378,1371,1386,1377],
      left: 981,//[1910,1891,1873,1850,1835,1794,1769,1740,1727,1707,1689,1673,1655,1640,1627,1609,1603,1600,1589,1582,1583,1578,1578,1574,1571,1576,1572,1575,1584,1588,1590,1593,1604,1614,1632,1637,1660,1673,1694,1710,1736,1750,1766,1782,1802,1828,1844,1861,1886,1904,1931,1938,1976,2006,2025,2042,2064,2081,2107,2129,2142,2160,2175,2194,2201,2217,2225,2239,2246,2256,2258,2271,2279,2286,2287,2290,2293,2281,2277,2268,2268,2265,2251,2238,2222,2214,2199,2180,2171,2147,2122,2096,2078,2054,2022,1993,1989,1973,1957,1933,1904],
      perspective: "glamping",
      full360: null,
    listed: true,
},
    {
      title: "Gourmet Kitchen",
      unit: 1,
      top: 636,//[696,735,761,806,844,887,922,970,1008,1054,1093,1132,1180,1221,1269,1309,1344,1390,1417,1457,1482,1517,1549,1573,1605,1629,1654,1660,1686,1701,1718,1722,1725,1727,1737,1735,1716,1717,1712,1697,1679,1666,1643,1626,1597,1579,1547,1517,1481,1452,1419,1383,1338,1302,1255,1212,1170,1135,1095,1054,1012,971,937,896,853,816,777,745,714,682,647,621,588,568,538,522,500,486,467,448,442,435,422,422,424,417,421,422,436,446,457,470,490,513,536,552,585,609,647,673,704],
      left: 582,//[2537,2567,2589,2612,2628,2639,2649,2670,2678,2680,2677,2675,2673,2661,2655,2636,2619,2599,2578,2547,2517,2490,2454,2410,2381,2346,2293,2245,2208,2161,2115,2067,2021,1978,1919,1876,1824,1775,1719,1685,1630,1582,1547,1509,1464,1433,1385,1354,1327,1295,1263,1247,1220,1209,1193,1178,1167,1165,1164,1159,1160,1175,1185,1191,1203,1224,1240,1262,1292,1325,1351,1380,1415,1455,1492,1523,1565,1606,1650,1689,1739,1782,1819,1868,1920,1961,2007,2050,2093,2139,2180,2228,2265,2309,2351,2384,2420,2453,2484,2515,2541],
      perspective: null,
      full360: null,
    listed: true,
},
    {
      title: "Pool Terrace",
      unit: 1,
      top: 587,//[850,869,903,932,945,973,1002,1018,1047,1074,1107,1145,1159,1185,1209,1257,1282,1297,1325,1349,1378,1384,1405,1420,1420,1434,1466,1478,1488,1507,1507,1512,1524,1519,1530,1529,1519,1514,1514,1512,1502,1492,1471,1456,1435,1417,1393,1377,1354,1332,1307,1284,1255,1228,1203,1173,1146,1126,1096,1065,1034,1024,973,947,916,890,864,834,814,800,780,763,759,744,725,708,705,675,663,649,640,612,614,610,616,613,613,621,631,636,645,664,671,685,698,719,730,749,770,791,817],
      left: 737,//[2344,2352,2364,2361,2382,2389,2401,2406,2408,2410,2410,2410,2396,2388,2376,2374,2361,2347,2333,2308,2299,2285,2261,2236,2219,2198,2165,2139,2110,2069,2046,2010,1969,1936,1897,1854,1825,1792,1760,1720,1706,1674,1645,1618,1590,1568,1532,1506,1488,1468,1457,1439,1436,1412,1404,1406,1401,1396,1398,1401,1403,1415,1413,1421,1427,1441,1459,1470,1486,1511,1523,1547,1562,1589,1615,1637,1662,1688,1718,1746,1778,1819,1855,1880,1922,1955,1997,2017,2048,2083,2104,2144,2163,2195,2208,2245,2257,2275,2301,2322,2346],
      perspective: null,
      full360: null,
    listed: true,
},
    {
      title: "Wading Pool",
      unit: 1,
      top: 650,//[739,753,778,802,831,867,888,920,947,974,1017,1040,1071,1105,1136,1171,1200,1229,1258,1285,1318,1344,1370,1405,1428,1457,1476,1498,1518,1531,1541,1560,1571,1581,1583,1587,1597,1591,1591,1578,1585,1574,1570,1549,1539,1519,1505,1482,1459,1444,1414,1388,1364,1333,1304,1279,1244,1223,1174,1146,1115,1085,1054,1033,995,972,930,907,880,855,827,795,765,751,730,708,690,671,659,637,610,612,600,500,582,580,576,580,579,580,584,589,597,607,617,627,651,664,677,703,728],
      left: 730,//[2340,2363,2387,2413,2430,2444,2458,2469,2475,2490,2496,2502,2501,2501,2500,2502,2482,2474,2467,2452,2428,2411,2394,2369,2346,2319,2294,2254,2233,2204,2171,2129,2090,2064,2026,1985,1953,1909,1874,1827,1792,1766,1722,1690,1653,1625,1590,1560,1523,1504,1476,1459,1434,1413,1403,1380,1369,1365,1349,1346,1337,1342,1340,1350,1350,1358,1362,1373,1378,1400,1424,1437,1469,1483,1501,1530,1560,1585,1620,1653,1684,1711,1746,1781,1818,1859,1881,1925,1960,1998,2029,2058,2096,2117,2161,2190,2228,2257,2281,2314,2340],
      perspective: null,
      full360: null,
    listed: true,
},
    {
      title: "Pool Shower",
      unit: 1,
      top: 682,//[686,702,718,737,753,773,805,826,846,877,908,939,965,997,1021,1047,1094,1118,1146,1174,1193,1233,1264,1284,1319,1341,1358,1380,1403,1419,1428,1450,1471,1489,1497,1509,1511,1518,1525,1529,1529,1525,1530,1513,1503,1493,1474,1483,1464,1460,1452,1424,1411,1385,1378,1350,1305,1278,1264,1237,1203,1181,1147,1128,1101,1069,1044,1013,984,961,932,909,880,864,839,807,791,766,753,735,715,704,683,670,659,653,657,647,639,630,634,628,637,640,644,646,661,666,680,689],
      left: 800,//[2196,2219,2251,2275,2301,2323,2341,2363,2379,2400,2409,2417,2429,2429,2441,2449,2439,2448,2437,2434,2420,2423,2407,2391,2380,2370,2340,2312,2299,2265,2229,2209,2178,2165,2133,2103,2066,2028,1992,1970,1935,1896,1865,1829,1800,1767,1733,1704,1683,1656,1628,1604,1574,1547,1533,1511,1485,1467,1459,1432,1431,1428,1418,1405,1405,1397,1399,1403,1404,1411,1413,1425,1434,1445,1459,1484,1500,1529,1541,1570,1600,1634,1648,1675,1701,1744,1769,1811,1837,1862,1893,1923,1950,1984,2021,2047,2079,2109,2138,2166,2198],
      perspective: null,
      full360: null,
    listed: true,
},
    {
      title: "Infinity Pool *25m lap",
      unit: 1,
      top: 650,//[741,747,754,761,769,770,772,784,794,803,810,820,830,846,860,883,901,915,932,946,968,986,1008,1024,1043,1062,1097,1124,1143,1154,1174,1196,1211,1237,1260,1279,1304,1318,1332,1337,1351,1371,1376,1400,1405,1410,1412,1417,1424,1427,1427,1427,1423,1416,1410,1403,1394,1390,1383,1360,1340,1326,1300,1271,1254,1245,1216,1195,1174,1147,1112,1098,1085,1068,1041,1019,993,979,955,946,928,897,885,870,852,844,826,804,788,779,775,763,752,747,737,738,741,743,745,746,746],
      left: 960,//[1952,1976,1993,2014,2039,2055,2066,2083,2102,2127,2131,2159,2166,2189,2206,2226,2242,2262,2275,2283,2284,2298,2305,2307,2307,2309,2300,2305,2299,2292,2280,2275,2270,2255,2233,2215,2196,2187,2162,2137,2110,2092,2069,2053,2024,1998,1976,1947,1925,1900,1873,1849,1826,1816,1800,1752,1741,1720,1687,1662,1645,1620,1596,1590,1579,1559,1551,1544,1540,1528,1522,1515,1530,1522,1529,1535,1547,1550,1562,1564,1577,1591,1611,1624,1639,1657,1683,1705,1721,1750,1774,1789,1817,1833,1859,1880,1895,1921,1940,1956],
      perspective: null,
      full360: null,
    listed: true,
},
    {
      title: "Music & Dance Room",
      unit: 1,
      top: 642,//[702,668,638,612,589,566,542,522,505,483,474,461,452,446,436,440,439,444,452,456,465,478,495,506,521,544,566,590,611,638,668,702,734,767,800.837,869,910,947,989,1034,1068,1108,1149,1186,1234,1273,1307,1346,1384,1415,1455,1486,1516,1543,1573,1601,1621,1640,1662,1682,1689,1700,1702,1718,1717,1716,1720,1710,1706,1692,1678,1670,1651,1631,1605,1580,1557,1532,1497,1467,1426,1389,1357,1311,1277,1239,1197,1158,1113,1073,1032,994,955,911,874,832,796,765,732,699],
      left: 1360,//[1335,1364,1395,1428,1463,1501,1532,1572,1610,1649,1694,1737,1786,1826,1867,1915,1951,1997,2041,2084,2126,2169,2204,2286,2320,2359,2394,2426,2459,2484,2517,2547,2571,2581,2603,2622,2638,2648,2652,2658,2662,2657,2649,2649,2634,2621,2608,2591,2567,2584,2519,2494,2462,2425,2390,2356,2313,2273,2225,2180,2132,2089,2039,1994,1942,1893,1853,1804,1747,1711,1669,1621,1576,1535,1501,1457,1421,1388,1349,1323,1295,1273,1254,1228,1214,1200,1191,1181,1178,1177,1180,1191,1196,1212,1216,1232,1249,1274,1301,1328],
      perspective: null,
      full360: null,
    listed: true,
},
    {
      title: "Pool Deck",
      unit: 1,
      top: 660,//[710,701,698,687,679,673,674,677,675,672,679,683,688,691,704,713,726,739,756,777,791,797,825,841,864,887,910,937,957,980,1004,1028,1054,1076,1100,1130,1157,1176,1200,1221,1256,1279,1299,1319,1342,1356,1377,1394,1413,1432,1446,1453,1456,1478,1477,1486,1488,1491,1493,1494,1494,1489,1484,1478,1466,1457,1441,1428,1415,1402,1379,1364,1341,1324,1301,1282,1257,1233,1207,1175,1151,1128,1102,1072,1049,1017,990,966,946,921,893,871,847,823,795,777,757,753,738,720,705],
      left: 1116,//[1699,1726,1754,1781,1812,1842,1871,1896,1927,1958,1990,2013,2042,2075,2094,2117,2141,2177,2197,2202,2237,2261,2285,2306,2325,2344,2354,2362,2369,2368,2388,2384,2391,2391,2389,2378,2388,2370,2367,2354,2350,2337,2322,2300,2286,2265,2244,2222,2197,2177,2151,2121,2079,2066,2039,2011,1985,1954,1922,1890,1858,1827,1802,1772,1743,1719,1691,1666,1640,1618,1584,1561,1547,1535,1507,1494,1478,1464,1461,1452,1444,1441,1444,1447,1446,1446,1450,1459,1469,1482,1496,1507,1521,1538,1558,1578,1603,1625,1650,1679,1700],
      perspective: null,
      full360: null,
    listed: true,
},
    {
      title: "Jacuzzi",
      unit: 1,
      top: 516,//[936,935,926,920,909,905,899,896,894,890,886,886,890,897,891,900,899,899,904,909,910,919,929,933,942,947,965,974,985,989,993,1002,1013,1022,1033,1040,1052,1062,1071,1089,1095,1104,1111,1124,1143,1147,1158,1168,1180,1187,1197,1203,1210,1219,1217,1228,1230,1232,1236,1237,1232,1235,1234,1230,1228,1225,1222,1216,1214,1210,1205,1200,1193,1189,1196,1197,1190,1187,1178,1166,1155,1142,1134,1118,1102,1096,1083,1071,1063,1051,1036,1026,1016,1006,993,984,973,967,957,950,941],
      left: 1066,//[1783,1785,1786,1792,1816,1820,1832,1847,1860,1876,1888,1907,1916,1939,1942,1964,1978,1987,2000,2017,2027,2033,2048,2056,2070,2076,2086,2096,2105,2111,2109,2119,2123,2128,2125,2133,2135,2137,2136,2137,2132,2140,2124,2123,2113,2109,2105,2091,2082,2071,2062,2052,2036,2020,2012,1998,1989,1977,1959,1944,1923,1916,1899,1883,1869,1854,1840,1825,1826,1816,1803,1792,1783,1766,1767,1756,1753,1745,1733,1730,1727,1717,1714,1711,1709,1702,1701,1701,1703,1704,1705,1707,1711,1724,1725,1725,1744,1756,1759,1777,1778],
      perspective: null,
      full360: null,
    listed: true,
},
    {
      title: "Lounger Deck",
      unit: 1,
      top: 600,//[852,840,825,815,801,795,781,777,768,769,764,760,761,756,761,756,762,766,770,776,785,796,801,816,828,842,861,877,890,909,925,948,964,982,1002,1023,1040,1064,1084,1111,1125,1145,1164,1191,1208,1223,1244,1258,1279,1292,1311,1322,1336,1350,1358,1372,1383,1390,1400,1397,1408,1408,1412,1409,1407,1407,1403,1394,1387,1385,1372,1365,1347,1336,1322,1305,1291,1285,1264,1243,1226,1216,1189,1174,1148,1138,1116,1093,1068,1060,1029,1013,997,974,952,934,914,904,885,828,847],
      left: 1140,//[1653,1677,1691,1710,1731,1753,1772,1791,1820,1841,1866,1885,1910,1935,1956,1977,2004,2021,2042,2069,2086,2103,2126,2147,2169,2184,2196,2214,2225,2240,2252,2262,2270,2275,2284,2289,2296,2297,2297,2297,2292,2289,2286,2277,2269,2260,2251,2234,2222,2209,2192,2174,2158,2144,2118,2099,2079,2055,2035,2006,1986,1960,1934,1914,1883,1861,1833,1816,1794,1771,1745,1720,1701,1677,1671,1656,1636,1621,1605,1595,1591,1579,1570,1561,1552,1549,1545,1544,1545,1543,1549,1554,1559,1565,1569,1578,1595,1603,1623,1638,1652],
      perspective: null,
      full360: null,
    listed: true,
},
    {
      title: "Pool Pilates",
      unit: 1,
      top: 520,//[956,949,932,922,910,901,891,895,881,876,862,862,858,853,853,849,848,847,850,851,855,860,864,870,874,881,891,900,908,917,926,934,946,960,969,981,994,1008,1021,1046,1056,1074,1089,1100,1121,1130,1147,1154,1173,1185,1196,1208,1219,1232,1245,1248,1261,1271,1280,1281,1291,1295,1304,1303,1309,1310,1311,1309,1309,1306,1300,1298,1294,1285,1280,1272,1264,1253,1240,1233,1219,1209,1198,1182,1171,1155,1142,1128,1115,1106,1090,1076,1058,1043,1030,1018,1002,993,978,970,955],
      left: 1130,//[1698,1708,1714,1727,1741,1752,1761,1782,1796,1805,1822,1842,1853,1867,1890,1902,1918,1934,1950,1967,1978,2001,2016,2029,2046,2059,2076,2088,2098,2106,2122,2130,2141,2149,2159,2162,2167,2175,2178,2181,2186,2181,2185,2185,2182,2178,2174,2170,2159,2150,2147,2139,2125,2119,2108,2093,2081,2068,2052,2038,2023,2010,1994,1974,1960,1942,1921,1904,1888,1870,1855,1835,1820,1809,1792,1776,1764,1749,1734,1724,1714,1702,1696,1686,1679,1673,1668,1666,1658,1659,1660,1650,1658,1662,1663,1671,1670,1679,1683,1693,1701],
      perspective: null,
      full360: null,
    listed: true,
},
    {
      title: "Aqua Gym",
      unit: 1,
      top: 550,//[894,898,891,885,887,891,889,897,898,907,908,917,922,928,934,943,946,956,970,984,989,997,1006,1023,1040,1050,1058,1070,1082,1095,1107,1116,1135,1143,1154,1165,1183,1190,1204,1208,1218,1224,1229,1237,1242,1249,1257,1255,1261,1265,1267,1267,1268,1264,1267,1261,1254,1250,1246,1244,1237,1231,1228,1223,1213,1205,1195,1191,1172,1157,1140,1138,1127,1110,1105,1090,1079,1072,1065,1051,1042,1033,1024,1005,1000,985,977,968,957,949,942,928,921,914,910,908,900,902,897,896,888],
      left: 990,//[1895,1906,1921,1935,1945,1964,1979,1986,2003,2013,2026,2039,2050,2057,2072,2075,2083,2092,2101,2112,2116,2127,2131,2136,2135,2133,2140,2142,2142,2142,2143,2136,2139,2132,2123,2113,2106,2094,2091,2079,2075,2058,2045,2033,2019,2004,1994,1978,1971,1958,1943,1936,1916,1905,1889,1873,1864,1848,1828,1819,1815,1792,1786,1779,1766,1757,1746,1739,1731,1722,1712,1709,1701,1696,1693,1703,1705,1701,1709,1714,1711,1722,1726,1726,1727,1733,1743,1752,1764,1760,1780,1793,1809,1817,1824,1841,1858,1866,1880,1880,1893],
      perspective: null,
      full360: null,
    listed: true,
},
    {
      title: "Cabana",
      unit: 1,
      top: 570,//[837,847,866,876,880,899,912,922,935,949,970,975,988,1010,1026,1031,1049,1065,1090,1098,1116,1130,1146,1168,1176,1191,1208,1224,1235,1246,1258,1259,1275,1279,1298,1300,1314,1315,1321,1327,1329,1326,1332,1330,1323,1327,1322,1319,1308,1305,1302,1293,1279,1269,1258,1243,1239,1230,1223,1207,1182,1173,1161,1140,1125,1107,1097,1079,1063,1053,1035,1019,1003,978,971,954,940,924,919,899,889,883,876,863,850,835,833,837,837,830,823,819,817,816,819,822,823,835,836,841,843],
      left: 895,//[2059,2076,2087,2103,2120,2134,2141,2152,2162,2169,2188,2186,2201,2206,2218,2211,2214,2213,2217,2214,2208,2205,2195,2196,2188,2182,2169,2160,2146,2140,2123,2108,2095,2076,2063,2052,2029,2015,1999,1979,1961,1945,1925,1902,1886,1870,1846,1830,1814,1798,1784,1771,1752,1736,1723,1710,1698,1688,1677,1671,1660,1648,1643,1637,1634,1633,1630,1628,1627,1629,1632,1630,1639,1643,1650,1664,1677,1686,1697,1711,1719,1731,1747,1773,1780,1795,1810,1834,1860,1871,1889,1910,1926,1942,1959,1971,1995,2011,2027,2043,2053],
      perspective: null,
      full360: null,
    listed: true,
},
    {
      title: "Hammock Nook",
      unit: 1,
      top: 300,//[1290,1296,1300,1301,1302,1309,1298,1303,1290,1294,1286,1280,1261,1252,1240,1226,1208,1198,1177,1166,1154,1147,1137,1130,1118,1105,1091,1079,1061,1045,1031,1016,994,984,973,966,945,935,913,914,903,887,878,865,863,848,835,826,820,816,805,800,802,799,802,800,800,804,807,807,807,811,814,822,836,844,860,870,881,895,908,917,922,938,942,961,981,993,1008,1028,1044,1060,1082,1103,1118,1128,1145,1163,1182,1191,1210,1237,1261,1272,1285,1295,1305,1303,1300,1294,1300],
      left: 930,//[1986,1975,1952,1927,1895,1884,1877,1848,1835,1804,1789,1780,1761,1743,1737,1731,1706,1697,1689,1681,1676,1672,1674,1670,1669,1665,1661,1657,1659,1656,1654,1654,1654,1658,1670,1680,1684,1683,1681,1710,1715,1726,1738,1743,1758,1763,1774,1782,1795,1812,1833,1848,1861,1875,1891,1910,1924,1940,1961,1976,1996,2018,2037,2062,2066,2085,2103,2110,2125,2132,2148,2162,2168,2173,2182,2192,2191,2194,2201,2199,2202,2198,2198,2187,2190,2191,2189,2176,2159,2159,2151,2140,2135,2118,2112,2089,2068,2052,2037,2021,2008],
      perspective: null,
      full360: null,
    listed: true,
},
    {
      title: "BBQ Terrace",
      unit: 1,
      top: 640,//[732,715,703,675,662,641,621,615,608,604,592,586,581,590,596,593,599,611,617,632,641,651,679,682,711,725,742,773,787,816,835,859,889,923,952,985,1001,1029,1078,1102,1133,1159,1189,1219,1248,1272,1302,1327,1352,1379,1402,1421,1444,1460,1480,1498,1511,1521,1531,1541,1546,1552,1555,1553,1557,1550,1552,1540,1535,1520,1503,1490,1468,1454,1427,1407,1387,1362,1340,1322,1295,1264,1233,1213,1183,1152,1135,1091,1067,1043,1010,989,955,930,906,880,847,822,798,772,755],
      left: 1220,//[1533,1557,1573,1606,1632,1667,1693,1723,1755,1801,1825,1860,1890,1932,1961,1994,2035,2066,2092,2119,2150,2183,2223,2238,2269,2292,2310,2336,2352,2371,2387,2406,2412,2424,2435,2449,2451,2455,2460,2458,2456,2453,2445,2434,2420,2413,2401,2386,2369,2344,2322,2301,2273,2247,2221,2187,2163,2130,2099,2066,2028,1993,1957,1918,1885,1848,1817,1776,1746,1708,1672,1649,1619,1579,1557,1534,1507,1487,1468,1445,1423,1414,1391,1389,1374,1372,1373,1369,1361,1364,1365,1373,1384,1396,1409,1429,1443,1461,1486,1495,1532],
      perspective: null,
      full360: null,
    listed: true,
},
    {
      title: "Dining Terrace",
      unit: 1,
      top: 680,//[671,656,633,618,597,587,576,563,555,548,540,539,539,542,541,550,555,563,575,587,606,623,644,662,686,714,741,773,797,824,846,875,910,941,978,1002,1037,1077,1112,1147,1176,1216,1252,1280,1314,1345,1381,1401,1435,1464,1490,1511,1538,1560,1581,1599,1609,1621,1634,1642,1650,1658,1650,1648,1639,1633,1619,1602,1599,1586,1573,1554,1526,1506,1473,1444,1422,1392,1363,1331,1296,1275,1231,1195,1167,1135,1103,1068,1035,1007,973,946,907,874,842,819,784,752,725,705,680],
      left: 1200,//[1500,1525,1546,1585,1615,1649,1690,1724,1760,1802,1842,1882,1925,1961,2000,2039,2080,2114,2154,2190,2225,2260,2291,2325,2352,2381,2404,2432,2455,2472,2492,2508,2520,2532,2545,2552,2558,2562,2558,2554,2547,2542,2536,2528,2518,2498,2469,2448,2427,2401,2384,2354,2324,2289,2253,2214,2171,2131,2093,2050,2009,1973,1919,1877,1831,1787,1748,1705,1677,1640,1598,1568,1532,1500,1468,1443,1417,1397,1376,1356,1341,1329,1319,1310,1307,1297,1294,1289,1294,1293,1301,1302,1318,1329,1348,1359,1380,1405,1436,1460,1500],
      perspective: null,
      full360: null,
    listed: true,
},
    {
      title: "Swing Nook",
      unit: 1,
      top: 585,//[822,785,758,738,724,703,695,674,671,661,645,641,636,638,635,638,643,645,648,661,662,676,688,699,710,730,749,760,780,803,822,842,859,881,910,938,959,980,1010,1038,1057,1096,1126,1150,1177,1207,1232,1256,1292,1305,1330,1361,1371,1395,1411,1433,1447,1457,1466,1478,1495,1503,1501,1506,1513,1510,1510,1507,1503,1497,1480,1471,1464,1448,1440,1424,1403,1388,1363,1342,1322,1294,1269,1253,1227,1203,1173,1147,1121,1095,1067,1040,1016,985,956,933,914,889,870,834,802],
      left: 1220,//[1544,1556,1581,1599,1625,1651,1677,1702,1734,1760,1793,1816,1844,1883,1917,1942,1972,2003,2033,2066,2090,2121,2149,2176,2196,2219,2244,2272,2289,2310,2310,2332,2344,2348,2373,2385,2392,2402,2407,2410,2416,2413,2405,2399,2393,2387,2378,2365,2354,2333,2312,2297,2278,2255,2231,2212,2186,2153,2127,2098,2070,2036,2005,1976,1941,1913,1881,1844,1816,1784,1753,1727,1696,1673,1644,1622,1596,1578,1552,1534,1519,1499,1485,1472,1462,1453,1446,1444,1439,1434,1436,1444,1448,1415,1458,1470,1481,1496,1512,1520,1536],
      perspective: null,
      full360: null,
    listed: true,
},
    {
      title: "Yoga Deck",
      unit: 1,
      top: 490,//[974,969,963,960,953,944,939,934,929,928,922,923,924,918,919,928,935,939,940,948,949,956,965,975,986,999,1015,1025,1037,1044,1044,1056,1067,1074,1091,1094,1094,1103,1102,1103,1121,1119,1129,1130,1130,1136,1143,1140,1155,1149,1150,1156,1158,1170,1172,1169,1171,1178,1180,1179,1180,1173,1178,1180,1177,1175,1176,1175,1172,1174,1167,1162,1161,1152,1152,1153,1161,1150,1145,1130,1129,1131,1121,1113,1109,1103,1093,1078,1072,1060,1048,1039,1035,1029,1023,1016,1006,997,991,981,975],
      left: 1040,//[1794,1786,1791,1814,1816,1827,1831,1845,1846,1857,1865,1875,1899,1899,1910,1925,1939,1948,1966,1967,1979,1993,2000,2006,2014,2031,2040,2045,2046,2042,2044,2046,2046,2043,2041,2035,2031,2031,2031,2024,2017,2007,2011,2010,2001,2003,2007,2003,1992,1985,1977,1980,1983,1974,1971,1967,1967,1960,1955,1945,1936,1918,1913,1894,1889,1876,1867,1864,1851,1855,1855,1856,1837,1828,1827,1823,1816,1813,1802,1803,1799,1787,1787,1778,1775,1773,1772,1777,1775,1776,1776,1776,1779,1776,1772,1778,1781,1790,1800,1805,1800],
      perspective: null,
      full360: null,
    listed: true,
},
    {
      title: "Sauna",
      unit: 1,
      top: 480,//[910,936,969,1007,1043,1080,1114,1155,1193,1235,1263,1298,1329,1358,1395,1417,1447,1474,1490,1519,1541,1558,1574,1589,1598,1606,1616,1623,1620,1617,1615,1615,1604,1595,1582,1567,1551,1529,1505,1479,1458,1429,1406,1377,1344,1314,1285,1246,1217,1184,1147,1112,1085,1047,1013,980,942,909,880,840,808,776,746,716,687,664,639,619,599,578,554,530,528,513,502,496,486,479,477,479,477,480,487,498,510,526,538,552,566,588,604,626,648,676,709,737,765,796,830,868,900],
      left: 590,//[2555,2563,2570,2575,2581,2584,2584,2575,2568,2558,2547,2532,2512,2483,2460,2431,2400,2368,2335,2302,2265,2228,2188,2147,2105,2065,2020,1979,1937,1893,1852,1807,1763,1722,1686,1646,1607,1572,1538,1506,1480,1448,1419,1399,1373,1356,1344,1329,1319,1313,1301,1300,1296,1296,1300,1301,1300,1308,1320,1327,1332,1347,1349,1392,1419,1441,1473,1503,1530,1562,1597,1628,1664,1702,1742,1781,1815,1857,1900,1934,1977,2014,2052,2095,2033,2169,2206,2245,2276,2310,2348,2378,2404,2432,2458,2486,2510,2533,2553,2569,2581],
      perspective: null,
      full360: null,
    listed: true,
},
    {
      title: "Kitchen",
      unit: 1,
      top: 320,//[1193,1211,1231,1244,1263,1279,1296,1307,1320,1335,1342,1349,1357,1365,1372,1370,1384,1377,1367,1373,1374,1367,1364,1356,1351,1339,1331,1320,1308,1297,1274,1255,1242,1228,1210,1197,1176,1159,1137,1120,1097,1078,1060,1034,1020,996,973,959,941,920,903,885,872,852,837,825,811,799,785,781,768,763,753,746,744,737,733,737,734,741,742,745,746,754,759,771,778,783,794,796,821,832,845,859,878,897,918,936,956,968,996,1012,1035,1054,1078,1089,1110,1132,1156,1170,1182],
      left: 780,//[2265,2244,2233,2221,2209,2199,2176,2158,2137,2116,2094,2075,2054,2031,2011,1982,1960,1936,1908,1885,1862,1844,1816,1796,1774,1755,1732,1710,1693,1677,1660,1644,1634,1616,1605,1593,1582,1578,1566,1564,1555,1551,1553,1555,1555,1556,1561,1569,1579,1582,1592,1606,1617,1632,1642,1655,1672,1694,1714,1733,1750,1774,1793,1814,1841,1862,1884,1908,1931,1953,1973,1997,2013,2034,2057,2074,2097,2110,2128,2155,2170,2189,2200,2216,2231,2241,2257,2265,2276,2283,2286,2286,2288,2287,2288,2287,2278,2276,2272,2262,2254],
      perspective: null,
      full360: null,
    listed: true,
},
    {
      title: "Laundry",
      unit: 1,
      top: 320,//[1177,1182,1191,1200,1214,1218,1226,1224,1229,1237,1242,1249,1248,1254,1253,1254,1255,1249,1238,1235,1230,1235,1225,1217,1208,1202,1193,1182,1174,1163,1150,1141,1128,1117,1102,1087,1070,1064,1050,1031,1019,1001,993,983,966,956,945,931,916,908,898,887,880,874,868,858,855,851,847,847,843,839,844,842,844,844,852,855,856,861,870,875,876,884,889,898,906,915,925,930,942,952,967,980,988,996,1015,1030,1041,1059,1071,1080,1092,1099,1111,1124,1139,1148,1156,1163,1172],
      left: 880,//[2099,2087,2078,2051,2045,2038,2026,2007,1992,1982,1967,1954,1932,1922,1905,1894,1881,1866,1854,1834,1820,1817,1797,1788,1777,1764,1754,1745,1734,1725,1715,1709,1698,1698,1694,1689,1688,1687,1687,1684,1686,1693,1695,1694,1708,1710,1723,1725,1728,1742,1749,1758,1767,1780,1792,1801,1816,1824,1844,1861,1869,1884,1901,1918,1930,1945,1962,1978,1989,2004,2019,2028,2039,2053,2064,2074,2086,2101,2102,2111,2122,2130,2137,2145,2145,2149,2148,2147,2151,2148,2149,2149,2142,2144,2139,2135,2130,2126,2118,2107,2099],
      perspective: null,
      full360: null,
    listed: true,
},
    {
      title: "MPH",
      unit: 1,
      top: 375,//[1110,1126,1136,1154,1168,1176,1192,1204,1215,1222,1230,1240,1246,1251,1259,1258,1259,1261,1266,1265,1261,1262,1257,1255,1252,1250,1249,1244,1237,1237,1228,1221,1213,1202,1189,1180,1176,1168,1153,1138,1124,1110,1097,1080,1067,1053,1045,1030,1016,996,984,970,960,943,931,925,914,897,883,875,865,858,846,840,835,825,818,816,810,804,804,802,804,818,818,815,825,828,837,845,859,864,873,879,890,902,917,926,937,958,970,982,993,1010,1026,1042,1056,1065,1082,1093,1108],
      left: 825,//[2187,2171,2169,2160,2151,2139,2127,2120,2110,2095,2084,2068,2047,2034,2018,2003,1981,1971,1949,1937,1919,1906,1887,1869,1858,1845,1825,1805,1790,1781,1770,1761,1743,1728,1717,1709,1704,1693,1690,1681,1678,1671,1662,1661,1661,1659,1666,1662,1661,1670,1671,1680,1681,1684,1694,1703,1704,1724,1730,1745,1755,1770,1784,1797,1811,1823,1838,1855,1872,1890,1913,1925,1943,1956,1979,1990,2005,2028,2037,2052,2069,2084,2094,2108,2124,2126,2141,2152,2158,2157,2171,2176,2179,2179,2183,2183,2186,2186,2182,2179,2174],
      perspective: null,
      full360: null,
    listed: true,
},
    {
      title: "Co-Working Space",
      unit: 1,
      top: 500,//[895,835,831,796,768,747,716,698,676,651,627,609,590,573,560,557,545,531,528,523,519,521,520,525,528,539,553,559,571,585,599,618,643,659,682,706,723,748,787,807,832,867,900,924,955,991,1028,1052,1092,1119,1155,1179,1220,1255,1287,1327,1350,1377,1402,1427,1448,1478,1500,1528,1535,1551,1565,1589,1595,1604,1611,1620,1617,1617,1609,1607,1594,1583,1569,1557,1539,1512,1494,1476,1448,1424,1386,1351,1321,1283,1249,1223,1182,1156,1121,1081,1044,1003,980,944,910],
      left: 1350,//[1314,1330,1351,1366,1386,1409,1426,1457,1487,1507,1539,1576,1606,1638,1677,1711,1747,1779,1816,1856,1894,1922,1967,1993,2039,2066,2101,2142,2171,2203,2238,2275,2304,2327,2358,2387,2401,2429,2455,2467,2490,2502,2518,2524,2531,2541,2545,2541,2542,2541,2540,2526,2519,2501,2491,2470,2443,2429,2406,2382,2359,2327,2291,2261,2223,2194,2153,2118,2070,2027,1990,1949,1904,1865,1824,1784,1744,1707,1667,1636,1601,1563,1531,1497,1468,1440,1407,1382,1361,1339,1327,1324,1311,1305,1298,1294,1290,1292,1298,1303,1323],
      perspective: null,
      full360: null,
    listed: true,
},
    {
      title: "Gym",
      unit: 1,
      top: 360,//[1145,1131,1114,1102,1089,1076,1064,1045,1039,1024,1010,1004,993,979,958,947,937,924,918,901,883,885,876,868,863,862,866,865,862,857,854,849,849,850,848,849,848,857,860,862,867,873,881,886,898,906,917,929,933,945,951,964,978,994,1004,1022,1031,1043,1065,1077,1089,1100,1109,1123,1137,1143,1153,1161,1172,1183,1193,1210,1215,1225,1232,1234,1241,1246,1254,1258,1261,1260,1261,1268,1269,1267,1258,1252,1247,1242,1235,1232,1224,1215,1207,1198,1187,1176,1165,1149,1145],
      left: 1110,//[1699,1692,1686,1678,1684,1684,1672,1677,1679,1684,1683,1691,1697,1705,1706,1711,1716,1731,1735,1751,1762,1770,1785,1797,1804,1813,1828,1848,1856,1875,1881,1900,1913,1925,1942,1960,1971,1985,2005,2016,2024,2037,2047,2061,2070,2082,2095,2108,2119,2131,2138,2146,2148,2153,2160,2160,2158,2160,2163,2164,2160,2160,2152,2145,2137,2126,2122,2115,2105,2093,2087,2085,2066,2049,2042,2034,2018,2002,1992,1971,1955,1944,1930,1910,1897,1883,1864,1852,1838,1817,1802,1794,1781,1764,1759,1744,1737,1726,1714,1704,1696],
      perspective: null,
      full360: null,
    listed: true,
},
  ],
  rooftop: [
    {
      title: "⁠Moonlit Skyrun *150m",
      unit: 1,
      top: 180,//[961,969,967,964,969,960,958,957,954,951,948,936,934,931,918,931,922,919,912,903,896,894,880,874,868,856,847,840,829,809,800,796,785,779,772,760,759,743,738,736,731,724,718,713,710,697,707,703,699,698,698,699,699,700,700,701,704,711,712,712,722,726,730,738,745,745,756,761,765,775,782,784,800,806,822,820,816,842,847,864,863,873,878,880,898,911,915,923,924,937,938,943,947,952,957,958,964,970,971,969,976],
      left: 970,//[1937,1925,1903,1896,1893,1873,1864,1852,1839,1836,1819,1813,1805,1809,1791,1791,1789,1780,1781,1759,1756,1743,1747,1742,1735,1733,1731,1730,1727,1734,1744,1741,1749,1751,1755,1762,1773,1774,1784,1783,1807,1818,1822,1830,1847,1844,1863,1869,1888,1900,1915,1922,1929,1948,1949,1963,1982,1993,1998,2009,2015,2026,2037,2045,2048,2054,2061,2073,2071,2077,2085,2089,2089,2099,2093,2100,2105,2101,2101,2103,2095,2095,2088,2090,2083,2068,2068,2066,2062,2052,2043,2035,2028,2020,1998,1990,1973,1973,1955,1946,1934],
      perspective: "skyrun",
      full360: "skyrun",
    listed: true,
},
    {
      title: "Outdoor Fitness",
      unit: 1,
      top: 105,//[1110,1120,1141,1156,1173,1182,1188,1198,1204,1212,1210,1211,1203,1199,1203,1215,1196,1170,1186,1147,1158,1154,1140,1134,1126,1083,1086,1059,1078,1037,997,966,955,924,898,884,854,837,804,785,759,753,728,703,688,668,648,620,596,586,576,557,551,539,526,509,503,499,495,489,475,481,484,476,475,480,482,475,491,504,497,517,527,527,548,555,561,585,600,619,634,652,683,717,740,763,782,810,821,857,873,901,931,945,974,1001,1005,1038,1060,1082,1103],
      left: 765,//[2294,2260,2243,2213,2176,2146,2121,2079,2054,2016,1980,1940,1906,1884,1854,1831,1795,1762,1733,1699,1677,1644,1617,1587,1565,1540,1518,1498,1480,1470,1460,1455,1441,1439,1427,1421,1420,1420,1432,1430,1455,1449,1449,1475,1479,1494,1514,1529,1537,1603,1591,1608,1635,1666,1686,1705,1737,1767,1800,1830,1845,1882,1918,1970,1968,2002,2030,2057,2084,2114,2135,2163,2193,2215,2244,2271,2292,2306,2325,2343,2362,2376,2382,2386,2386,2396,2415,2430,2427,2422,2422,2417,2401,2393,2388,2364,2366,2351,2326,2310,2286],
      perspective: null,
      full360: null,
    listed: true,
},
    {
      title: "Tabata Pad",
      unit: 1,
      top: 135,//[1045,1065,1059,1064,1084,1077,1088,1083,1087,1085,1087,1086,1088,1077,1076,1068,1056,1040,1043,1041,1032,1019,1010,1008,985,970,967,956,936,917,898,891,869,859,848,826,812,806,780,766,749,741,720,702,691,683,679,656,651,641,619,616,604,601,601,591,588,589,584,589,595,592,603,601,599,604,608,615,625,631,643,641,662,663,681,691,705,716,740,748,762,780,794,807,825,837,860,869,894,900,906,924,944,957,978,991,1005,1009,1012,1026,1029],
      left: 850,//[2104,2081,2073,2034,2024,2004,1984,1955,1942,1935,1905,1901,1872,1854,1839,1818,1788,1755,1747,1744,1721,1697,1691,1679,1662,1648,1642,1632,1616,1610,1604,1594,1591,1586,1585,1581,1586,1598,1589,1597,1613,1619,1631,1634,1641,1654,1662,1679,1684,1703,1730,1743,1767,1787,1816,1835,1842,1870,1887,1915,1936,1961,1982,1997,2013,2026,2045,2055,2072,2094,2105,2113,2131,2149,2165,2184,2185,2192,2197,2219,2217,2226,2228,2231,2230,2230,2232,2235,2234,2209,2208,2213,2205,2192,2184,2178,2159,2148,2117,2104,2089],
      perspective: null,
      full360: null,
    listed: true,
},
    {
      title: "Rest Corner",
      unit: 1,
      top: 220,//[860,871,875,889,910,916,918,917,932,952,955,954,957,960,980,971,977,978,961,971,969,953,973,964,968,964,952,947,942,946,938,927,922,915,912,898,885,880,871,862,859,841,835,830,815,805,800,787,785,780,767,763,756,745,747,738,728,728,720,717,710,703,702,694,694,687,682,688,684,684,680,682,677,681,682,683,690,688,693,697,704,707,710,716,726,734,740,749,761,767,778,773,789,804,807,813,825,838,842,857,860],
      left: 865,//[2087,2097,2103,2094,2088,2086,2072,2066,2066,2062,2051,2035,2024,2007,1996,1990,1978,1949,1963,1949,1941,1921,1910,1899,1877,1873,1856,1829,1834,1824,1805,1800,1794,1791,1777,1763,1769,1754,1757,1741,1741,1740,1724,1728,1746,1725,1734,1728,1718,1725,1734,1737,1745,1753,1765,1764,1772,1784,1795,1799,1810,1820,1829,1833,1846,1858,1867,1878,1890,1900,1913,1922,1936,1950,1957,1973,1979,2001,1999,2017,2032,2038,2042,2048,2064,2067,2071,2079,2087,2084,2090,2095,2093,2091,2093,2091,2092,2086,2086,2090,2087],
      perspective: null,
      full360: null,
    listed: true,
},
    {
      title: "Reflexology Garden",
      unit: 1,
      top: 160,//[999,961,947,931,920,895,887,870,842,832,823,803,789,773,763,736,735,707,695,678,657,655,639,626,621,606,601,582,578,579,565,562,553,560,544,548,554,546,558,545,556,568,571,575,570,585,596,607,613,642,683,662,663,685,703,731,755,774,799,792,818,842,855,875,898,910,927,940,952,970,982,994,1005,1015,1033,1038,1057,1054,1073,1084,1077,1090,1089,1071,1097,1092,1101,1106,1098,1082,1080,1062,1060,1058,1039,1031,1021,993,994,994,976],
      left: 1145,//[1611,1613,1606,1589,1587,1582,1572,1567,1574,1568,1561,1556,1556,1562,1559,1585,1641,1596,1600,1601,1612,1637,1639,1666,1690,1683,1724,1742,1780,1770,1795,1845,1846,1875,1890,1911,1936,1955,1976,2008,2023,2041,2063,2093,2102,2185,2149,2156,2172,2155,2180,2222,2233,2248,2259,2265,2271,2275,2280,2309,2293,2279,2284,2265,2257,2251,2247,2248,2235,2215,2212,2184,2180,2159,2146,2131,2109,2076,2064,2046,2018,1995,1977,1942,1941,1891,1882,1858,1832,1806,1780,1763,1743,1739,1712,1697,1680,1658,1646,1641,1621],
      perspective: null,
      full360: null,
    listed: true,
},
    {
      title: "Stretch Loop",
      unit: 1,
      top: 110,//[1070,1045,1020,987,962,932,896,870,840,817,779,753,719,686,660,640,607,583,567,538,522,494,493,465,448,437,428,422,424,421,404,402,398,408,410,416,412,419,429,430,440,450,463,464,483,500,516,534,546,569,591,617,641,668,701,728,750,783,817,847,873,910,946,972,1006,1027,1062,1090,1115,1135,1163,1192,1208,1233,1240,1258,1277,1298,1305,1317,1328,1331,1332,1331,1332,1330,1320,1316,1299,1285,1266,1249,1232,1213,0,0,0,0,1092,1074,1063],
      left: 1250,//[1365,1345,1333,1317,1310,1308,1299,1295,1302,1310,1310,1315,1331,1335,1356,1374,1396,1405,1434,1455,1482,1511,1541,1559,1594,1640,1664,1713,1731,1770,1794,1835,1851,1891,1928,1960,2001,2023,2057,2099,2128,2161,2165,2218,2235,2256,2293,2294,2328,2357,2378,2461,2477,2489,2500,2516,2507,2524,2533,2540,2537,2536,2539,2529,2516,2501,2487,2469,2452,2435,2408,2384,2354,2330,2294,2256,2215,2180,2137,2100,2057,2003,1954,1900,1864,1818,1765,1738,1691,1646,1608,1530,1538,1501,0,0,0,0,1380,1371,1353],
      perspective: null,
      full360: null,
    listed: true,
},
  ],level20: [
    {
      title: "Edible Garden Lv30",
      unit: 1,
      top: 310,//[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,400,378,374,377,385,386,386,394,397,407,420,430,463,448,490,485,518,534,568,579,608,646,669,722,752,772,794,829,864,893,928,970,1009,1047,1078,1165,1154,1196,1224,1258,1273,1306,1331,1363,1383,1405,1423,1440,1455,1480,1483,1487,1493,1493,1491,1497,1485,1476,1472,1461,1445,1430,1413,1390,1373,1340,1315,1286,1252,1218,0,0,0,0,0,0,0,0,0,0],
      left: 1415,//[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1824,1857,1887,1915,1947,1976,2016,2048,2087,2121,2156,2209,2266,2277,2334,2364,2393,2423,2461,2489,2503,2538,2562,2577,2586,2608,2626,2638,2647,2647,2644,2653,2641,2640,2628,2609,2599,2572,2550,2528,2500,2465,2438,2402,2366,2320,2283,2240,2191,2143,2101,2051,2003,1956,1911,1857,1815,1769,1713,1674,1623,1582,1541,1491,1453,1417,1375,1349,1313,1292,0,0,0,0,0,0,0,0,0,0],
      perspective: "edible",
      full360: null,
    listed: true,
},
    {
      title: "Edible Garden Lv20",
      unit: 1,
      top: 490,//[0,0,0,0,0,0,0,0,0,0,0,0,0,1564,1594,1621,1644,1668,1689,1722,1729,1739,1745,1776,1758,1775,1762,1771,1759,1754,1734,1727,1697,1690,1678,1647,1630,1612,1588,1544,1534,1502,1466,1429,1401,1376,1338,1305,1272,1232,1246,1173,1139,1109,1075,1047,1016,983,947,925,901,869,847,832,806,781,766,755,738,725,706,700,695,690,686,686,678,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
      left: 550,//[0,0,0,0,0,0,0,0,0,0,0,0,0,2461,2417,2375,2332,2285,2233,2189,2142,2095,2077,2015,1988,1925,1885,1843,1800,1755,1710,1668,1639,1602,1544,1494,1462,1425,1415,1388,1356,1325,1314,1275,1288,1278,1267,1273,1272,1262,1216,1254,1249,1258,1269,1283,1307,1325,1333,1354,1379,1405,1427,1460,1491,1521,1552,1585,1621,1654,1703,1740,1774,1803,1845,1883,1905,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
      perspective: "edible",
      full360: null,
    listed: false,
},
    {
      title: "Picnic Courtyard Lv30",
      unit: 1,
      top: 435,//[688,657,631,599,572,542,521,499,475,453,439,419,406,394,386,375,374,362,362,367,355,367,370,373,390,396,404,414,445,461,481,506,530,548,576,598,632,659,696,731,756,790,833,861,894,937,972,1002,1047,1084,1138,1149,1186,1221,1251,1283,1310,1341,1371,1391,1412,1433,1456,1371,1484,1499,1505,1514,1521,1520,1522,1516,1510,1503,1490,1475,1458,1439,1418,1392,1375,1345,1312,1278,1252,1220,1184,1153,1117,1080,1039,1002,968,928,893,856,824,782,748,719,691],
      left: 1415,//[1249,1261,1288,1311,1344,1376,1406,1438,1472,1512,1546,1587,1625,1665,1706,1751,1799,1830,1888,1929,1978,2009,2051,2097,2142,2180,2222,2263,2330,2347,2377,2409,2441,2465,2506,2533,2550,2577,2591,2623,2629,2651,2656,2671,2675,2685,2674,2669,2667,2663,2654,2623,2605,2582,2557,2532,2502,2468,2432,2391,2356,2311,2268,2222,2175,2125,2076,2027,1974,1926,1870,1824,1770,1723,1672,1628,1580,1535,1489,1444,1412,1379,1338,1312,1283,1262,1237,1217,1199,1189,1178,1170,1165,1164,1169,1172,1182,1192,1204,1223,1247],
      perspective: null,
      full360: null,
    listed: true,
},
    {
      title: "Community Garden Lv30",
      unit: 1,
      top: 365,//[0,0,0,0,0,0,0,0,0,0,0,0,550,547,533,526,518,513,501,502,499,492,507,514,520,520,523,527,547,554,562,569,590,604,630,642,654,690,706,724,741,770,800,820,845,874,911,920,945,944,1019,1030,1048,1072,1094,1115,1134,1156,1179,1190,1198,1234,1233,1249,1263,1275,1284,1306,1300,1307,1307,1319,1313,1310,1310,1308,1288,1284,1274,1259,1236,1217,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
      left: 1285,//[0,0,0,0,0,0,0,0,0,0,0,0,1641,1657,1683,1725,1770,1799,1830,1859,1886,1914,1947,1972,2006,2044,2171,2105,2142,2158,2190,2225,2241,2263,2284,2310,2338,2339,2368,2372,2392,2419,2423,2427,2435,2439,2446,2451,2436,2426,2437,2411,2403,2387,2373,2360,2341,2325,2297,2282,2257,2243,2201,2183,2157,2129,2107,2056,2038,2005,1964,1926,1895,1850,1815,1786,1741,1710,1712,1675,1662,1591,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
      perspective: null,
      full360: null,
    listed: true,
},
    {
      title: "Picnic Courtyard Lv20",
      unit: 1,
      top: 585,//[0,0,0,0,0,0,0,0,0,0,1353,1400,1430,1466,1497,1531,1563,1595,1623,1645,1655,1696,1713,1722,1743,1760,1776,1786,1789,1792,1796,1800,1792,1784,1772,1767,1756,1727,1719,1691,1675,1651,1628,1594,1564,1535,1517,1477,1440,1408,1372,1337,1307,1274,1227,1195,1162,1128,1096,1060,1024,993,960,931,903,871,848,827,802,782,760,743,724,710,693,681,671,664,660,657,652,652,660,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
      left: 565,//[0,0,0,0,0,0,0,0,0,0,2611,2600,2587,2572,2554,2519,2510,2472,2442,2407,2370,2337,2296,2244,2206,2160,2115,2076,2028,1984,1931,1886,1840,1791,1742,1686,1654,1610,1566,1523,1484,1451,1413,1388,1359,1324,1301,1277,1255,1255,1216,1226,1218,1212,1210,1210,1217,1220,1230,1239,1255,1271,1291,1311,1335,1361,1390,1420,1452,1483,1515,1553,1587,1628,1664,1704,1745,1782,1831,1867,1909,1949,1991,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
      perspective: null,
      full360: null,
    listed: false,
},
    {
      title: "Community Garden Lv20",
      unit: 1,
      top: 535,//[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,1481,1494,1517,1539,1554,1569,1574,1581,1590,1605,1619,1610,1619,1623,1615,1624,1608,1597,1596,1580,1589,1566,1552,1541,1523,1510,1500,1470,1455,1436,1414,1388,1365,1349,1329,1325,1268,1249,1216,1185,1158,1138,1112,1084,1057,1029,1005,976,951,933,911,896,874,860,838,827,818,808,796,791,781,775,786,777,776,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
      left: 665,//[0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,2390,2346,2303,2257,2234,2209,2167,2132,2098,2080,2057,2010,1962,1945,1914,1866,1851,1804,1777,1740,1720,1693,1654,1616,1610,1572,1562,1522,1514,1490,1493,1472,1448,1434,1429,1416,1401,1400,1401,1405,1399,1414,1405,1413,1430,1453,1453,1467,1478,1503,1521,1546,1571,1597,1631,1657,1685,1711,1745,1773,1803,1842,1860,1904,1939,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0,0],
      perspective: null,
      full360: null,
    listed: false,
}
  ]
}

export default FacilitiesPoint