import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import {
  Row,
  Badge,
  Col,
  CardBody,
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardSubtitle,
  CardText,
  Alert,
  Container,
  Modal,
  Collapse,
  Spinner,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// actions
import {
  registerUser,
  loginUser,
  apiError,
  socialLogin,
  showJoinNowAction,
} from "store/actions"

const imageUnit = require.context("assets/images/rainfora/unit/floor", true)
const imageUnitList = imageUnit.keys().map(image => imageUnit(image))

import imageRetail from "assets/images/rainfora/retail/retail_floor.webp"

import floorPlanU1F1 from "assets/images/rainfora/retail/retail_floorplan/retail-1-1.webp"
import floorPlanU1F2 from "assets/images/rainfora/retail/retail_floorplan/retail-1-2.webp"
import floorPlanU1F3 from "assets/images/rainfora/retail/retail_floorplan/retail-1-3.webp"
import floorPlanU2F1 from "assets/images/rainfora/retail/retail_floorplan/retail-2-1.webp"
import floorPlanU2F2 from "assets/images/rainfora/retail/retail_floorplan/retail-2-2.webp"
import floorPlanU2F3 from "assets/images/rainfora/retail/retail_floorplan/retail-2-3.webp"
import floorPlanU3F1 from "assets/images/rainfora/retail/retail_floorplan/retail-3-1.webp"
import floorPlanU3F2 from "assets/images/rainfora/retail/retail_floorplan/retail-3-2.webp"
import floorPlanU4F1 from "assets/images/rainfora/retail/retail_floorplan/retail-4-1.webp"
import floorPlanU4F2 from "assets/images/rainfora/retail/retail_floorplan/retail-4-2.webp"
import floorPlanU5F1 from "assets/images/rainfora/retail/retail_floorplan/retail-5-1.webp"
import floorPlanU5F2 from "assets/images/rainfora/retail/retail_floorplan/retail-5-2.webp"
import floorPlanU6F1 from "assets/images/rainfora/retail/retail_floorplan/retail-6-1.webp"
import floorPlanU6F2 from "assets/images/rainfora/retail/retail_floorplan/retail-6-2.webp"
import floorPlanU7F1 from "assets/images/rainfora/retail/retail_floorplan/retail-7-1.webp"
import floorPlanU7F2 from "assets/images/rainfora/retail/retail_floorplan/retail-7-2.webp"
import floorPlanU8F1 from "assets/images/rainfora/retail/retail_floorplan/retail-8-1.webp"
import floorPlanU8F2 from "assets/images/rainfora/retail/retail_floorplan/retail-8-2.webp"
import floorPlanU9F1 from "assets/images/rainfora/retail/retail_floorplan/retail-9-1.webp"
import floorPlanU9F2 from "assets/images/rainfora/retail/retail_floorplan/retail-9-2.webp"

const unitInfo = [
  {
    title: "Retail 1",
    unit: 1,
    top: 43,
    left: 21,
    width: 11,
    height: 24,
    floor_plan: [
      { floor: "1st Floor", level: "01-01", floor_plan: floorPlanU1F1, size: "4,088 Sq.ft" },
      { floor: "2nd Floor", level: "01-02", floor_plan: floorPlanU1F2, size: "5,840 Sq.ft" },
      { floor: "3rd Floor", level: "01-03", floor_plan: floorPlanU1F3, size: "5,697 Sq.ft" },
    ],
  },
  {
    title: "Retail 2",
    unit: 2,
    top: 34,
    left: 26,
    width: 11,
    height: 10,
    floor_plan: [
      { floor: "1st Floor", level: "02-01", floor_plan: floorPlanU2F1, size: "1,737 Sq.ft" },
      { floor: "2nd Floor", level: "02-02", floor_plan: floorPlanU2F2, size: "2,670 Sq.ft" },
      { floor: "3rd Floor", level: "02-03", floor_plan: floorPlanU2F3, size: "1,918 Sq.ft" },
    ],
  },
  {
    title: "Retail 3",
    unit: 3,
    top: 34,
    left: 37,
    width: 5,
    height: 22,
    floor_plan: [
      { floor: "1st Floor", level: "03-01", floor_plan: floorPlanU3F1, size: "2,161 Sq.ft" },
      { floor: "2nd Floor", level: "03-02", floor_plan: floorPlanU3F2, size: "2,334 Sq.ft" },
    ],
  },
  {
    title: "Retail 3A",
    unit: 4,
    top: 34,
    left: 42,
    width: 6,
    height: 22,
    floor_plan: [
      { floor: "1st Floor", level: "03A-01", floor_plan: floorPlanU4F1, size: "2,337 Sq.ft" },
      { floor: "2nd Floor", level: "03A-02", floor_plan: floorPlanU4F2, size: "3,084 Sq.ft" },
    ],
  },
  {
    title: "Retail 5",
    unit: 5,
    top: 34,
    left: 52,
    width: 6,
    height: 22,
    floor_plan: [
      { floor: "1st Floor", level: "05-01", floor_plan: floorPlanU5F1, size: "2,413 Sq.ft" },
      { floor: "2nd Floor", level: "05-02", floor_plan: floorPlanU5F2, size: "2,905 Sq.ft" },
    ],
  },
  {
    title: "Retail 6",
    unit: 6,
    top: 34,
    left: 58,
    width: 6,
    height: 22,
    floor_plan: [
      { floor: "1st Floor", level: "06-01", floor_plan: floorPlanU6F1, size: "2,155 Sq.ft" },
      { floor: "2nd Floor", level: "06-02", floor_plan: floorPlanU6F2, size: "2,334 Sq.ft" },
    ],
  },
  {
    title: "Retail 7",
    unit: 7,
    top: 34,
    left: 64,
    width: 10,
    height: 12,
    floor_plan: [
      { floor: "1st Floor", level: "07-01", floor_plan: floorPlanU7F1, size: "1,737 Sq.ft" },
      { floor: "2nd Floor", level: "07-02", floor_plan: floorPlanU7F2, size: "2,668 Sq.ft" },
    ],
  },
  {
    title: "Retail 8",
    unit: 8,
    top: 34,
    left: 74,
    width: 6,
    height: 18,
    floor_plan: [
      { floor: "1st Floor", level: "08-01", floor_plan: floorPlanU8F1, size: "1,981 Sq.ft" },
      { floor: "2nd Floor", level: "08-02", floor_plan: floorPlanU8F2, size: "1,871 Sq.ft" },
    ],
  },
  {
    title: "Retail 9",
    unit: 9,
    top: 52,
    left: 69,
    width: 11,
    height: 9,
    floor_plan: [
      { floor: "1st Floor", level: "09-01", floor_plan: floorPlanU9F1, size: "1,697 Sq.ft" },
      { floor: "2nd Floor", level: "09-02", floor_plan: floorPlanU9F2, size: "2,454 Sq.ft" },
    ],
  },
]

const Unit = props => {
  const history = useHistory()

  useEffect(() => {
    if(props.leftSideBarType == "default")
      setLeftSideBarSize(200)
    else
      setLeftSideBarSize(70)
  }, [props.leftSideBarType])

  const [leftSideBarSize, setLeftSideBarSize] = useState(70)
  
  const [def, setdef] = useState(0)
  const [dayNight, setDayNight] = useState(false)

  const [comparisonView, setComparisonView] = useState(false)

  const [currentCompareItem, setCurrentCompareItem] = useState([])

  return (
    <React.Fragment>
      <MetaTags>
        <title>Rainfora</title>
      </MetaTags>
      <div
        className="page-content p-0"
        style={{ backgroundImage: "radial-gradient(#164A3D, #01221F)" }}
      >
        <Row className="">
          <Col className="">
            <Card className="p-0 mb-0">
              {!comparisonView && (
                <CardBody
                  className="p-0"
                  style={{
                    backgroundImage: "radial-gradient(#164A3D, #01221F)",
                  }}
                >
                  <Row className="justify-content-center">
                    <Col
                      className="col-9"
                      style={{ overflowY: "-moz-hidden-unscrollable" }}
                    >
                      <Row className="m-0">
                        <Col className="p-0">
                          <div style={{ position: "relative" }}>
                            {unitInfo.map((item, index) => (
                              <button
                                key={"unit-info-" + index}
                                className={
                                  "btn btn-primary font-weight-normal font-size-16 px-3 bg-transparent"
                                }
                                style={{
                                  position: "absolute",
                                  top: item.top + "%",
                                  left: item.left + "%",
                                  borderWidth: 0,
                                  width: item.width + "%",
                                  height: item.height + "%",
                                  // borderRadius: "25px",
                                  // whiteSpace: "nowrap",
                                  // height: "50px",
                                }}
                                onClick={() => {
                                  setCurrentCompareItem(item)
                                  setComparisonView(true)
                                }}
                              >
                                <span>{""}</span>
                              </button>
                            ))}
                            <CardImg
                              src={imageRetail}
                              // className="p-5"
                              style={{
                                // height: "295px",
                                height: "100vh",
                                objectFit: "contain",
                              }}
                            />
                          </div>
                        </Col>
                      </Row>
                      <Row
                        style={{
                          position: "fixed",
                          top: 0,
                          left: 0,
                          right: 0,
                          paddingTop: 16,
                          paddingLeft: 32,
                          paddingRight: 32,
                        }}
                      >
                        <Col>
                          <span className="text-primary font-size-56 font-weight-bold ">
                            {"RETAIL"}
                          </span>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              )}
              <Modal
                isOpen={comparisonView}
                toggle={() => {
                  setComparisonView(!comparisonView)
                }}
                size="xl"
                className="modal-fullscreen"
              >
                <div
                  className="modal-header border-none"
                  style={{ backgroundColor: "#002521" }}
                >
                  <span className="text-primary font-size-56 font-weight-bold ">
                    {comparisonView &&
                      currentCompareItem &&
                      currentCompareItem.title}
                  </span>
                </div>
                <div
                  className="modal-body border-none"
                  style={{ backgroundColor: "#002521" }}
                >
                  {comparisonView && (
                    <Row>
                      <Col>
                        <Row className="justify-content-center px-3">
                          {currentCompareItem &&
                            currentCompareItem.floor_plan.map((item, index) => (
                              <Col
                                className="col-4"
                                key={"compare-1-floor-plan-" + index}
                              >
                                <Card
                                  className="m-3"
                                  style={{
                                    background: "rgb(97, 100, 99, 0.35)",
                                    borderRadius: "20px",
                                  }}
                                >
                                  <CardBody
                                    className="p-3 px-4 mb-3"
                                    style={{
                                      borderRadius: "20px",
                                    }}
                                  >
                                    <Row className="align-items-center">
                                      <Col>
                                        <CardTitle className="my-0 text-primary font-size-40 ">
                                          {item.floor}
                                        </CardTitle>
                                      </Col>
                                      <Col className="col-auto">
                                        <CardSubtitle className="my-0 text-white font-size-24 ">
                                          {item.level}
                                        </CardSubtitle>
                                      </Col>
                                    </Row>
                                    <Row className="justify-content-between">
                                      <Col>
                                        <CardSubtitle className="mt-3 font-14 text-white">
                                          <span className="text-start">
                                            {item.size}
                                          </span>
                                        </CardSubtitle>
                                      </Col>
                                      <Col>
                                        <CardSubtitle className="mt-3 font-14 text-white">
                                          <span>
                                            {/* {comparisonItem1 &&
                                        comparisonItem1.bedroom}{" "}
                                      Bedroom{" "}
                                      {comparisonItem1 &&
                                        comparisonItem1.bathroom}{" "}
                                      Bathroom */}
                                          </span>
                                        </CardSubtitle>
                                      </Col>
                                    </Row>
                                    <CardImg
                                      src={item.floor_plan}
                                      style={{
                                        // height: "295px",
                                        // height: "100vh",
                                        maxHeight: "65vh",
                                        objectFit: "contain",
                                      }}
                                    />
                                  </CardBody>
                                </Card>
                              </Col>
                            ))}
                        </Row>
                      </Col>
                    </Row>
                  )}
                  <button
                    className={
                      "btn btn-primary text-color-919695 font-weight-normal font-size-16 border-none"
                    }
                    style={{
                      backgroundColor: "#002521CC",
                      backdropFilter: "blur(20px)",
                      height: "60px",
                      // width: "60px",
                      position: "absolute",
                      bottom: 16,
                      right: 32,
                      borderRadius: "30px",
                    }}
                    onClick={() => {
                      setComparisonView(false)
                    }}
                  >
                    Close <i className="ph ph-x"></i>
                  </button>
                </div>
              </Modal>
              {/* {comparisonView && (
                <CardBody
                  className="p-0"
                  style={{
                    backgroundImage: "radial-gradient(#164A3D, #01221F)",
                    height: "100vh",
                  }}
                >
                  <Row
                    style={{
                      position: "fixed",
                      top: 0,
                      left: 0,
                      right: 0,
                      paddingTop: 16,
                      paddingLeft: 32,
                      paddingRight: 32,
                    }}
                  >
                    <Col>
                      <span className="text-primary font-size-56 font-weight-bold ">
                        {"RETAIL"}
                      </span>
                    </Col>
                    <Col className="col-auto">
                      <button
                        className={
                          "btn text-white btn-primary font-weight-normal font-size-24 p-3"
                        }
                        style={{
                          height: "60px",
                          width: "60px",
                          borderRadius: "30px",
                        }}
                        onClick={() => {
                          setComparisonView(false)
                        }}
                      >
                        <i className="ph ph-x"></i>
                      </button>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "90px" }}>
                    <Col>
                      <Row className="justify-content-center px-3">
                        {currentCompareItem &&
                          currentCompareItem.floor_plan.map((item, index) => (
                            <Col
                              className="col-4"
                              key={"compare-1-floor-plan-" + index}
                            >
                              <Card
                                className="m-3"
                                style={{
                                  background: "rgb(97, 100, 99, 0.35)",
                                  borderRadius: "20px",
                                }}
                              >
                                <CardBody
                                  className="p-3 px-4 mb-3"
                                  style={{
                                    borderRadius: "20px",
                                  }}
                                >
                                  <Row className="align-items-center">
                                    <Col>
                                      <CardTitle className="my-0 text-primary font-size-40 ">
                                        {item.floor}
                                      </CardTitle>
                                    </Col>
                                    <Col className="col-auto">
                                      <CardSubtitle className="my-0 text-white font-size-24 ">
                                        {item.level}
                                      </CardSubtitle>
                                    </Col>
                                  </Row>
                                  <Row className="justify-content-between">
                                    <Col>
                                      <CardSubtitle className="mt-3 font-14 text-white">
                                        <span className="text-start">
                                          {item.size}
                                        </span>
                                      </CardSubtitle>
                                    </Col>
                                    <Col>
                                      <CardSubtitle className="mt-3 font-14 text-white">
                                        <span>
                                      
                                        </span>
                                      </CardSubtitle>
                                    </Col>
                                  </Row>
                                  <CardImg
                                    src={item.floor_plan}
                                    style={{
                                      // height: "295px",
                                      // height: "100vh",
                                      maxHeight: "65vh",
                                      objectFit: "contain",
                                    }}
                                  />
                                </CardBody>
                              </Card>
                            </Col>
                          ))}
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              )} */}
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { leftMenu, leftSideBarType } = state.Layout
  return { leftMenu, leftSideBarType }
}

export default withRouter(
  connect(mapStateToProps, { showJoinNowAction })(Unit)
)

Unit.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
}
