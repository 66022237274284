const UnitPoint = [
  [
    {
      type: "F(M)",
      block: "B",
      level: "09",
      unit: "06",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 276,
      left: 1178,
      width: 169,
      height: 131,
      face: "East",
    },
    {
      type: "C(M)",
      block: "B",
      level: "09",
      unit: "07",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 276,
      left: 1351,
      width: 129,
      height: 142,
      face: "East",
    },
    {
      type: "D(M)",
      block: "B",
      level: "09",
      unit: "08",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 281,
      left: 1488,
      width: 153,
      height: 137,
      face: "East",
    },
    {
      type: "G(M)",
      block: "B",
      level: "09",
      unit: "09",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 278,
      left: 1649,
      width: 175,
      height: 147,
      face: "East",
    },
    {
      type: "B(M)",
      block: "B",
      level: "09",
      unit: "10",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 446,
      left: 1665,
      width: 160,
      height: 90,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "09",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 542,
      left: 1665,
      width: 157,
      height: 86,
      face: "South",
    },
    {
      type: "B(M)",
      block: "B",
      level: "09",
      unit: "12",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 631,
      left: 1666,
      width: 156,
      height: 87,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "09",
      unit: "13",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 720,
      left: 1666,
      width: 160,
      height: 89,
      face: "South",
    },
    {
      type: "G(M)",
      block: "B",
      level: "09",
      unit: "13A",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 814,
      left: 1665,
      width: 145,
      height: 166,
      face: "South",
    },
    {
      type: "F",
      block: "A",
      level: "09",
      unit: "07",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 861,
      width: 166,
      height: 139,
      face: "East",
    },
    {
      type: "C",
      block: "A",
      level: "09",
      unit: "08",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 725,
      width: 131,
      height: 141,
      face: "East",
    },
    {
      type: "D",
      block: "A",
      level: "09",
      unit: "09",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 563,
      width: 158,
      height: 142,
      face: "East",
    },
    {
      type: "G",
      block: "A",
      level: "09",
      unit: "10",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 386,
      width: 173,
      height: 138,
      face: "East",
    },
    {
      type: "B",
      block: "A",
      level: "09",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 448,
      left: 386,
      width: 155,
      height: 92,
      face: "North",
    },
  ],
  [
    {
      type: "F(M)",
      block: "B",
      level: "10",
      unit: "06",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 276,
      left: 1178,
      width: 169,
      height: 131,
      face: "East",
    },
    {
      type: "C(M)",
      block: "B",
      level: "10",
      unit: "07",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 276,
      left: 1351,
      width: 129,
      height: 142,
      face: "East",
    },
    {
      type: "D(M)",
      block: "B",
      level: "10",
      unit: "08",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 281,
      left: 1488,
      width: 153,
      height: 137,
      face: "East",
    },
    {
      type: "G(M)",
      block: "B",
      level: "10",
      unit: "09",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 278,
      left: 1649,
      width: 175,
      height: 147,
      face: "East",
    },
    {
      type: "B(M)",
      block: "B",
      level: "10",
      unit: "10",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 446,
      left: 1665,
      width: 160,
      height: 90,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "10",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 542,
      left: 1665,
      width: 157,
      height: 86,
      face: "South",
    },
    {
      type: "B(M)",
      block: "B",
      level: "10",
      unit: "12",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 631,
      left: 1666,
      width: 156,
      height: 87,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "10",
      unit: "13",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 720,
      left: 1666,
      width: 160,
      height: 89,
      face: "South",
    },
    {
      type: "G(M)",
      block: "B",
      level: "10",
      unit: "13A",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 814,
      left: 1665,
      width: 145,
      height: 166,
      face: "South",
    },
    {
      type: "F",
      block: "A",
      level: "10",
      unit: "07",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 861,
      width: 166,
      height: 139,
      face: "East",
    },
    {
      type: "C",
      block: "A",
      level: "10",
      unit: "08",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 725,
      width: 131,
      height: 141,
      face: "East",
    },
    {
      type: "D",
      block: "A",
      level: "10",
      unit: "09",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 563,
      width: 158,
      height: 142,
      face: "East",
    },
    {
      type: "G",
      block: "A",
      level: "10",
      unit: "10",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 386,
      width: 173,
      height: 138,
      face: "East",
    },
    {
      type: "B",
      block: "A",
      level: "10",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 448,
      left: 386,
      width: 155,
      height: 92,
      face: "North",
    },
  ],
  [
    {
      type: "E(M)",
      block: "B",
      level: "11",
      unit: "01",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 797,
      left: 1484,
      width: 154,
      height: 169,
      face: "North",
    },
    {
      type: "B",
      block: "B",
      level: "11",
      unit: "02",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 713,
      left: 1483,
      width: 155,
      height: 87,
      face: "North",
    },
    {
      type: "B1(M)",
      block: "B",
      level: "11",
      unit: "03",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 622,
      left: 1485,
      width: 152,
      height: 89,
      face: "North",
    },
    {
      type: "B(M)",
      block: "B",
      level: "11",
      unit: "03A",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 444,
      left: 1375,
      width: 89,
      height: 154,
      face: "West",
    },
    {
      type: "E",
      block: "B",
      level: "11",
      unit: "05",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 441,
      left: 1193,
      width: 176,
      height: 151,
      face: "West",
    },
    {
      type: "F(M)",
      block: "B",
      level: "11",
      unit: "06",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 276,
      left: 1178,
      width: 169,
      height: 131,
      face: "East",
    },
    {
      type: "C(M)",
      block: "B",
      level: "11",
      unit: "07",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 276,
      left: 1351,
      width: 129,
      height: 142,
      face: "East",
    },
    {
      type: "D(M)",
      block: "B",
      level: "11",
      unit: "08",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 281,
      left: 1488,
      width: 153,
      height: 137,
      face: "East",
    },
    {
      type: "G(M)",
      block: "B",
      level: "11",
      unit: "09",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 278,
      left: 1649,
      width: 175,
      height: 147,
      face: "East",
    },
    {
      type: "B(M)",
      block: "B",
      level: "11",
      unit: "10",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 446,
      left: 1665,
      width: 160,
      height: 90,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "11",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 542,
      left: 1665,
      width: 157,
      height: 86,
      face: "South",
    },
    {
      type: "B(M)",
      block: "B",
      level: "11",
      unit: "12",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 631,
      left: 1666,
      width: 156,
      height: 87,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "11",
      unit: "13",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 720,
      left: 1666,
      width: 160,
      height: 89,
      face: "South",
    },
    {
      type: "G(M)",
      block: "B",
      level: "11",
      unit: "13A",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 814,
      left: 1665,
      width: 145,
      height: 166,
      face: "South",
    },
    {
      type: "B",
      block: "A",
      level: "11",
      unit: "05",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 424,
      left: 743,
      width: 92,
      height: 152,
      face: "West",
    },
    {
      type: "E(M)",
      block: "A",
      level: "11",
      unit: "06",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 444,
      left: 838,
      width: 178,
      height: 146,
      face: "West",
    },
    {
      type: "F",
      block: "A",
      level: "11",
      unit: "07",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 861,
      width: 166,
      height: 139,
      face: "East",
    },
    {
      type: "C",
      block: "A",
      level: "11",
      unit: "08",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 725,
      width: 131,
      height: 141,
      face: "East",
    },
    {
      type: "D",
      block: "A",
      level: "11",
      unit: "09",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 563,
      width: 158,
      height: 142,
      face: "East",
    },
    {
      type: "G",
      block: "A",
      level: "11",
      unit: "10",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 386,
      width: 173,
      height: 138,
      face: "East",
    },
    {
      type: "B",
      block: "A",
      level: "11",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 448,
      left: 386,
      width: 155,
      height: 92,
      face: "North",
    },
  ],
  [
    {
      type: "E(M)",
      block: "B",
      level: "12",
      unit: "01",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 797,
      left: 1484,
      width: 154,
      height: 169,
      face: "North",
    },
    {
      type: "B",
      block: "B",
      level: "12",
      unit: "02",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 713,
      left: 1483,
      width: 155,
      height: 87,
      face: "North",
    },
    {
      type: "B1(M)",
      block: "B",
      level: "12",
      unit: "03",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 622,
      left: 1485,
      width: 152,
      height: 89,
      face: "North",
    },
    {
      type: "B(M)",
      block: "B",
      level: "12",
      unit: "03A",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 444,
      left: 1375,
      width: 89,
      height: 154,
      face: "West",
    },
    {
      type: "E",
      block: "B",
      level: "12",
      unit: "05",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 441,
      left: 1193,
      width: 176,
      height: 151,
      face: "West",
    },
    {
      type: "F(M)",
      block: "B",
      level: "12",
      unit: "06",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 276,
      left: 1178,
      width: 169,
      height: 131,
      face: "East",
    },
    {
      type: "C(M)",
      block: "B",
      level: "12",
      unit: "07",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 276,
      left: 1351,
      width: 129,
      height: 142,
      face: "East",
    },
    {
      type: "D(M)",
      block: "B",
      level: "12",
      unit: "08",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 281,
      left: 1488,
      width: 153,
      height: 137,
      face: "East",
    },
    {
      type: "G(M)",
      block: "B",
      level: "12",
      unit: "09",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 278,
      left: 1649,
      width: 175,
      height: 147,
      face: "East",
    },
    {
      type: "B(M)",
      block: "B",
      level: "12",
      unit: "10",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 446,
      left: 1665,
      width: 160,
      height: 90,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "12",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 542,
      left: 1665,
      width: 157,
      height: 86,
      face: "South",
    },
    {
      type: "B(M)",
      block: "B",
      level: "12",
      unit: "12",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 631,
      left: 1666,
      width: 156,
      height: 87,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "12",
      unit: "13",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 720,
      left: 1666,
      width: 160,
      height: 89,
      face: "South",
    },
    {
      type: "G(M)",
      block: "B",
      level: "12",
      unit: "13A",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 814,
      left: 1665,
      width: 145,
      height: 166,
      face: "South",
    },
    {
      type: "B",
      block: "A",
      level: "12",
      unit: "05",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 424,
      left: 743,
      width: 92,
      height: 152,
      face: "West",
    },
    {
      type: "E(M)",
      block: "A",
      level: "12",
      unit: "06",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 444,
      left: 838,
      width: 178,
      height: 146,
      face: "West",
    },
    {
      type: "F",
      block: "A",
      level: "12",
      unit: "07",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 861,
      width: 166,
      height: 139,
      face: "East",
    },
    {
      type: "C",
      block: "A",
      level: "12",
      unit: "08",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 725,
      width: 131,
      height: 141,
      face: "East",
    },
    {
      type: "D",
      block: "A",
      level: "12",
      unit: "09",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 563,
      width: 158,
      height: 142,
      face: "East",
    },
    {
      type: "G",
      block: "A",
      level: "12",
      unit: "10",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 386,
      width: 173,
      height: 138,
      face: "East",
    },
    {
      type: "B",
      block: "A",
      level: "12",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 448,
      left: 386,
      width: 155,
      height: 92,
      face: "North",
    },
  ],
  [
    {
      type: "E(M)",
      block: "B",
      level: "13",
      unit: "01",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 797,
      left: 1484,
      width: 154,
      height: 169,
      face: "North",
    },
    {
      type: "B",
      block: "B",
      level: "13",
      unit: "02",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 713,
      left: 1483,
      width: 155,
      height: 87,
      face: "North",
    },
    {
      type: "B1(M)",
      block: "B",
      level: "13",
      unit: "03",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 622,
      left: 1485,
      width: 152,
      height: 89,
      face: "North",
    },
    {
      type: "B(M)",
      block: "B",
      level: "13",
      unit: "03A",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 444,
      left: 1375,
      width: 89,
      height: 154,
      face: "West",
    },
    {
      type: "E",
      block: "B",
      level: "13",
      unit: "05",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 441,
      left: 1193,
      width: 176,
      height: 151,
      face: "West",
    },
    {
      type: "F(M)",
      block: "B",
      level: "13",
      unit: "06",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 276,
      left: 1178,
      width: 169,
      height: 131,
      face: "East",
    },
    {
      type: "C(M)",
      block: "B",
      level: "13",
      unit: "07",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 276,
      left: 1351,
      width: 129,
      height: 142,
      face: "East",
    },
    {
      type: "D(M)",
      block: "B",
      level: "13",
      unit: "08",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 281,
      left: 1488,
      width: 153,
      height: 137,
      face: "East",
    },
    {
      type: "G(M)",
      block: "B",
      level: "13",
      unit: "09",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 278,
      left: 1649,
      width: 175,
      height: 147,
      face: "East",
    },
    {
      type: "B(M)",
      block: "B",
      level: "13",
      unit: "10",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 446,
      left: 1665,
      width: 160,
      height: 90,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "13",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 542,
      left: 1665,
      width: 157,
      height: 86,
      face: "South",
    },
    {
      type: "B(M)",
      block: "B",
      level: "13",
      unit: "12",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 631,
      left: 1666,
      width: 156,
      height: 87,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "13",
      unit: "13",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 720,
      left: 1666,
      width: 160,
      height: 89,
      face: "South",
    },
    {
      type: "G(M)",
      block: "B",
      level: "13",
      unit: "13A",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 814,
      left: 1665,
      width: 145,
      height: 166,
      face: "South",
    },
    {
      type: "B",
      block: "A",
      level: "13",
      unit: "05",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 424,
      left: 743,
      width: 92,
      height: 152,
      face: "West",
    },
    {
      type: "E(M)",
      block: "A",
      level: "13",
      unit: "06",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 444,
      left: 838,
      width: 178,
      height: 146,
      face: "West",
    },
    {
      type: "F",
      block: "A",
      level: "13",
      unit: "07",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 861,
      width: 166,
      height: 139,
      face: "East",
    },
    {
      type: "C",
      block: "A",
      level: "13",
      unit: "08",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 725,
      width: 131,
      height: 141,
      face: "East",
    },
    {
      type: "D",
      block: "A",
      level: "13",
      unit: "09",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 563,
      width: 158,
      height: 142,
      face: "East",
    },
    {
      type: "G",
      block: "A",
      level: "13",
      unit: "10",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 386,
      width: 173,
      height: 138,
      face: "East",
    },
    {
      type: "B",
      block: "A",
      level: "13",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 448,
      left: 386,
      width: 155,
      height: 92,
      face: "North",
    },
  ],
  [
    {
      type: "E(M)",
      block: "B",
      level: "14",
      unit: "01",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 797,
      left: 1484,
      width: 154,
      height: 169,
      face: "North",
    },
    {
      type: "B",
      block: "B",
      level: "14",
      unit: "02",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 713,
      left: 1483,
      width: 155,
      height: 87,
      face: "North",
    },
    {
      type: "B1(M)",
      block: "B",
      level: "14",
      unit: "03",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 622,
      left: 1485,
      width: 152,
      height: 89,
      face: "North",
    },
    {
      type: "B(M)",
      block: "B",
      level: "14",
      unit: "03A",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 444,
      left: 1375,
      width: 89,
      height: 154,
      face: "West",
    },
    {
      type: "E",
      block: "B",
      level: "14",
      unit: "05",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 441,
      left: 1193,
      width: 176,
      height: 151,
      face: "West",
    },
    {
      type: "F(M)",
      block: "B",
      level: "14",
      unit: "06",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 276,
      left: 1178,
      width: 169,
      height: 131,
      face: "East",
    },
    {
      type: "C(M)",
      block: "B",
      level: "14",
      unit: "07",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 276,
      left: 1351,
      width: 129,
      height: 142,
      face: "East",
    },
    {
      type: "D(M)",
      block: "B",
      level: "14",
      unit: "08",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 281,
      left: 1488,
      width: 153,
      height: 137,
      face: "East",
    },
    {
      type: "G(M)",
      block: "B",
      level: "14",
      unit: "09",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 278,
      left: 1649,
      width: 175,
      height: 147,
      face: "East",
    },
    {
      type: "B(M)",
      block: "B",
      level: "14",
      unit: "10",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 446,
      left: 1665,
      width: 160,
      height: 90,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "14",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 542,
      left: 1665,
      width: 157,
      height: 86,
      face: "South",
    },
    {
      type: "B(M)",
      block: "B",
      level: "14",
      unit: "12",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 631,
      left: 1666,
      width: 156,
      height: 87,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "14",
      unit: "13",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 720,
      left: 1666,
      width: 160,
      height: 89,
      face: "South",
    },
    {
      type: "G(M)",
      block: "B",
      level: "14",
      unit: "13A",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 814,
      left: 1665,
      width: 145,
      height: 166,
      face: "South",
    },
    {
      type: "B",
      block: "A",
      level: "14",
      unit: "05",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 424,
      left: 743,
      width: 92,
      height: 152,
      face: "West",
    },
    {
      type: "E(M)",
      block: "A",
      level: "14",
      unit: "06",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 444,
      left: 838,
      width: 178,
      height: 146,
      face: "West",
    },
    {
      type: "F",
      block: "A",
      level: "14",
      unit: "07",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 861,
      width: 166,
      height: 139,
      face: "East",
    },
    {
      type: "C",
      block: "A",
      level: "14",
      unit: "08",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 725,
      width: 131,
      height: 141,
      face: "East",
    },
    {
      type: "D",
      block: "A",
      level: "14",
      unit: "09",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 563,
      width: 158,
      height: 142,
      face: "East",
    },
    {
      type: "G",
      block: "A",
      level: "14",
      unit: "10",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 386,
      width: 173,
      height: 138,
      face: "East",
    },
    {
      type: "B",
      block: "A",
      level: "14",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 448,
      left: 386,
      width: 155,
      height: 92,
      face: "North",
    },
  ],
  [
    {
      type: "E(M)",
      block: "B",
      level: "15",
      unit: "01",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 797,
      left: 1484,
      width: 154,
      height: 169,
      face: "North",
    },
    {
      type: "B",
      block: "B",
      level: "15",
      unit: "02",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 713,
      left: 1483,
      width: 155,
      height: 87,
      face: "North",
    },
    {
      type: "B1(M)",
      block: "B",
      level: "15",
      unit: "03",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 622,
      left: 1485,
      width: 152,
      height: 89,
      face: "North",
    },
    {
      type: "B(M)",
      block: "B",
      level: "15",
      unit: "03A",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 444,
      left: 1375,
      width: 89,
      height: 154,
      face: "West",
    },
    {
      type: "E",
      block: "B",
      level: "15",
      unit: "05",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 441,
      left: 1193,
      width: 176,
      height: 151,
      face: "West",
    },
    {
      type: "F(M)",
      block: "B",
      level: "15",
      unit: "06",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 276,
      left: 1178,
      width: 169,
      height: 131,
      face: "East",
    },
    {
      type: "C(M)",
      block: "B",
      level: "15",
      unit: "07",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 276,
      left: 1351,
      width: 129,
      height: 142,
      face: "East",
    },
    {
      type: "D(M)",
      block: "B",
      level: "15",
      unit: "08",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 281,
      left: 1488,
      width: 153,
      height: 137,
      face: "East",
    },
    {
      type: "G(M)",
      block: "B",
      level: "15",
      unit: "09",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 278,
      left: 1649,
      width: 175,
      height: 147,
      face: "East",
    },
    {
      type: "B(M)",
      block: "B",
      level: "15",
      unit: "10",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 446,
      left: 1665,
      width: 160,
      height: 90,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "15",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 542,
      left: 1665,
      width: 157,
      height: 86,
      face: "South",
    },
    {
      type: "B(M)",
      block: "B",
      level: "15",
      unit: "12",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 631,
      left: 1666,
      width: 156,
      height: 87,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "15",
      unit: "13",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 720,
      left: 1666,
      width: 160,
      height: 89,
      face: "South",
    },
    {
      type: "G(M)",
      block: "B",
      level: "15",
      unit: "13A",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 814,
      left: 1665,
      width: 145,
      height: 166,
      face: "South",
    },
    {
      type: "B",
      block: "A",
      level: "15",
      unit: "05",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 424,
      left: 743,
      width: 92,
      height: 152,
      face: "West",
    },
    {
      type: "E(M)",
      block: "A",
      level: "15",
      unit: "06",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 444,
      left: 838,
      width: 178,
      height: 146,
      face: "West",
    },
    {
      type: "F",
      block: "A",
      level: "15",
      unit: "07",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 861,
      width: 166,
      height: 139,
      face: "East",
    },
    {
      type: "C",
      block: "A",
      level: "15",
      unit: "08",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 725,
      width: 131,
      height: 141,
      face: "East",
    },
    {
      type: "D",
      block: "A",
      level: "15",
      unit: "09",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 563,
      width: 158,
      height: 142,
      face: "East",
    },
    {
      type: "G",
      block: "A",
      level: "15",
      unit: "10",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 386,
      width: 173,
      height: 138,
      face: "East",
    },
    {
      type: "B",
      block: "A",
      level: "15",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 448,
      left: 386,
      width: 155,
      height: 92,
      face: "North",
    },
  ],
  [
    {
      type: "E(M)",
      block: "B",
      level: "16",
      unit: "01",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 797,
      left: 1484,
      width: 154,
      height: 169,
      face: "North",
    },
    {
      type: "B",
      block: "B",
      level: "16",
      unit: "02",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 713,
      left: 1483,
      width: 155,
      height: 87,
      face: "North",
    },
    {
      type: "B1(M)",
      block: "B",
      level: "16",
      unit: "03",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 622,
      left: 1485,
      width: 152,
      height: 89,
      face: "North",
    },
    {
      type: "B(M)",
      block: "B",
      level: "16",
      unit: "03A",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 444,
      left: 1375,
      width: 89,
      height: 154,
      face: "West",
    },
    {
      type: "E",
      block: "B",
      level: "16",
      unit: "05",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 441,
      left: 1193,
      width: 176,
      height: 151,
      face: "West",
    },
    {
      type: "F(M)",
      block: "B",
      level: "16",
      unit: "06",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 276,
      left: 1178,
      width: 169,
      height: 131,
      face: "East",
    },
    {
      type: "C(M)",
      block: "B",
      level: "16",
      unit: "07",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 276,
      left: 1351,
      width: 129,
      height: 142,
      face: "East",
    },
    {
      type: "D(M)",
      block: "B",
      level: "16",
      unit: "08",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 281,
      left: 1488,
      width: 153,
      height: 137,
      face: "East",
    },
    {
      type: "G(M)",
      block: "B",
      level: "16",
      unit: "09",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 278,
      left: 1649,
      width: 175,
      height: 147,
      face: "East",
    },
    {
      type: "B(M)",
      block: "B",
      level: "16",
      unit: "10",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 446,
      left: 1665,
      width: 160,
      height: 90,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "16",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 542,
      left: 1665,
      width: 157,
      height: 86,
      face: "South",
    },
    {
      type: "B(M)",
      block: "B",
      level: "16",
      unit: "12",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 631,
      left: 1666,
      width: 156,
      height: 87,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "16",
      unit: "13",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 720,
      left: 1666,
      width: 160,
      height: 89,
      face: "South",
    },
    {
      type: "G(M)",
      block: "B",
      level: "16",
      unit: "13A",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 814,
      left: 1665,
      width: 145,
      height: 166,
      face: "South",
    },
    {
      type: "B",
      block: "A",
      level: "16",
      unit: "05",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 424,
      left: 743,
      width: 92,
      height: 152,
      face: "West",
    },
    {
      type: "E(M)",
      block: "A",
      level: "16",
      unit: "06",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 444,
      left: 838,
      width: 178,
      height: 146,
      face: "West",
    },
    {
      type: "F",
      block: "A",
      level: "16",
      unit: "07",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 861,
      width: 166,
      height: 139,
      face: "East",
    },
    {
      type: "C",
      block: "A",
      level: "16",
      unit: "08",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 725,
      width: 131,
      height: 141,
      face: "East",
    },
    {
      type: "D",
      block: "A",
      level: "16",
      unit: "09",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 563,
      width: 158,
      height: 142,
      face: "East",
    },
    {
      type: "G",
      block: "A",
      level: "16",
      unit: "10",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 386,
      width: 173,
      height: 138,
      face: "East",
    },
    {
      type: "B",
      block: "A",
      level: "16",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 448,
      left: 386,
      width: 155,
      height: 92,
      face: "North",
    },
  ],
  [
    {
      type: "E(M)",
      block: "B",
      level: "17",
      unit: "01",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 797,
      left: 1484,
      width: 154,
      height: 169,
      face: "North",
    },
    {
      type: "B",
      block: "B",
      level: "17",
      unit: "02",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 713,
      left: 1483,
      width: 155,
      height: 87,
      face: "North",
    },
    {
      type: "B1(M)",
      block: "B",
      level: "17",
      unit: "03",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 622,
      left: 1485,
      width: 152,
      height: 89,
      face: "North",
    },
    {
      type: "B(M)",
      block: "B",
      level: "17",
      unit: "03A",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 444,
      left: 1375,
      width: 89,
      height: 154,
      face: "West",
    },
    {
      type: "E",
      block: "B",
      level: "17",
      unit: "05",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 441,
      left: 1193,
      width: 176,
      height: 151,
      face: "West",
    },
    {
      type: "F(M)",
      block: "B",
      level: "17",
      unit: "06",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 276,
      left: 1178,
      width: 169,
      height: 131,
      face: "East",
    },
    {
      type: "C(M)",
      block: "B",
      level: "17",
      unit: "07",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 276,
      left: 1351,
      width: 129,
      height: 142,
      face: "East",
    },
    {
      type: "D(M)",
      block: "B",
      level: "17",
      unit: "08",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 281,
      left: 1488,
      width: 153,
      height: 137,
      face: "East",
    },
    {
      type: "G(M)",
      block: "B",
      level: "17",
      unit: "09",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 278,
      left: 1649,
      width: 175,
      height: 147,
      face: "East",
    },
    {
      type: "B(M)",
      block: "B",
      level: "17",
      unit: "10",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 446,
      left: 1665,
      width: 160,
      height: 90,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "17",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 542,
      left: 1665,
      width: 157,
      height: 86,
      face: "South",
    },
    {
      type: "B(M)",
      block: "B",
      level: "17",
      unit: "12",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 631,
      left: 1666,
      width: 156,
      height: 87,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "17",
      unit: "13",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 720,
      left: 1666,
      width: 160,
      height: 89,
      face: "South",
    },
    {
      type: "G(M)",
      block: "B",
      level: "17",
      unit: "13A",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 814,
      left: 1665,
      width: 145,
      height: 166,
      face: "South",
    },
    {
      type: "B",
      block: "A",
      level: "17",
      unit: "05",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 424,
      left: 743,
      width: 92,
      height: 152,
      face: "West",
    },
    {
      type: "E(M)",
      block: "A",
      level: "17",
      unit: "06",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 444,
      left: 838,
      width: 178,
      height: 146,
      face: "West",
    },
    {
      type: "F",
      block: "A",
      level: "17",
      unit: "07",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 861,
      width: 166,
      height: 139,
      face: "East",
    },
    {
      type: "C",
      block: "A",
      level: "17",
      unit: "08",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 725,
      width: 131,
      height: 141,
      face: "East",
    },
    {
      type: "D",
      block: "A",
      level: "17",
      unit: "09",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 563,
      width: 158,
      height: 142,
      face: "East",
    },
    {
      type: "G",
      block: "A",
      level: "17",
      unit: "10",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 386,
      width: 173,
      height: 138,
      face: "East",
    },
    {
      type: "B",
      block: "A",
      level: "17",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 448,
      left: 386,
      width: 155,
      height: 92,
      face: "North",
    },
  ],
  [
    {
      type: "E(M)",
      block: "B",
      level: "18",
      unit: "01",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 797,
      left: 1484,
      width: 154,
      height: 169,
      face: "North",
    },
    {
      type: "B",
      block: "B",
      level: "18",
      unit: "02",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 713,
      left: 1483,
      width: 155,
      height: 87,
      face: "North",
    },
    {
      type: "B1(M)",
      block: "B",
      level: "18",
      unit: "03",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 622,
      left: 1485,
      width: 152,
      height: 89,
      face: "North",
    },
    {
      type: "B(M)",
      block: "B",
      level: "18",
      unit: "03A",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 444,
      left: 1375,
      width: 89,
      height: 154,
      face: "West",
    },
    {
      type: "E",
      block: "B",
      level: "18",
      unit: "05",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 441,
      left: 1193,
      width: 176,
      height: 151,
      face: "West",
    },
    {
      type: "F(M)",
      block: "B",
      level: "18",
      unit: "06",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 276,
      left: 1178,
      width: 169,
      height: 131,
      face: "East",
    },
    {
      type: "C(M)",
      block: "B",
      level: "18",
      unit: "07",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 276,
      left: 1351,
      width: 129,
      height: 142,
      face: "East",
    },
    {
      type: "D(M)",
      block: "B",
      level: "18",
      unit: "08",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 281,
      left: 1488,
      width: 153,
      height: 137,
      face: "East",
    },
    {
      type: "G(M)",
      block: "B",
      level: "18",
      unit: "09",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 278,
      left: 1649,
      width: 175,
      height: 147,
      face: "East",
    },
    {
      type: "B(M)",
      block: "B",
      level: "18",
      unit: "10",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 446,
      left: 1665,
      width: 160,
      height: 90,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "18",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 542,
      left: 1665,
      width: 157,
      height: 86,
      face: "South",
    },
    {
      type: "B(M)",
      block: "B",
      level: "18",
      unit: "12",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 631,
      left: 1666,
      width: 156,
      height: 87,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "18",
      unit: "13",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 720,
      left: 1666,
      width: 160,
      height: 89,
      face: "South",
    },
    {
      type: "G(M)",
      block: "B",
      level: "18",
      unit: "13A",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 814,
      left: 1665,
      width: 145,
      height: 166,
      face: "South",
    },
    {
      type: "B",
      block: "A",
      level: "18",
      unit: "05",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 424,
      left: 743,
      width: 92,
      height: 152,
      face: "West",
    },
    {
      type: "E(M)",
      block: "A",
      level: "18",
      unit: "06",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 444,
      left: 838,
      width: 178,
      height: 146,
      face: "West",
    },
    {
      type: "F",
      block: "A",
      level: "18",
      unit: "07",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 861,
      width: 166,
      height: 139,
      face: "East",
    },
    {
      type: "C",
      block: "A",
      level: "18",
      unit: "08",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 725,
      width: 131,
      height: 141,
      face: "East",
    },
    {
      type: "D",
      block: "A",
      level: "18",
      unit: "09",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 563,
      width: 158,
      height: 142,
      face: "East",
    },
    {
      type: "G",
      block: "A",
      level: "18",
      unit: "10",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 386,
      width: 173,
      height: 138,
      face: "East",
    },
    {
      type: "B",
      block: "A",
      level: "18",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 448,
      left: 386,
      width: 155,
      height: 92,
      face: "North",
    },
  ],
  [
    {
      type: "E(M)",
      block: "B",
      level: "19",
      unit: "01",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 797,
      left: 1484,
      width: 154,
      height: 169,
      face: "North",
    },
    {
      type: "B",
      block: "B",
      level: "19",
      unit: "02",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 713,
      left: 1483,
      width: 155,
      height: 87,
      face: "North",
    },
    {
      type: "B1(M)",
      block: "B",
      level: "19",
      unit: "03",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 622,
      left: 1485,
      width: 152,
      height: 89,
      face: "North",
    },
    {
      type: "B(M)",
      block: "B",
      level: "19",
      unit: "03A",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 444,
      left: 1375,
      width: 89,
      height: 154,
      face: "West",
    },
    {
      type: "E",
      block: "B",
      level: "19",
      unit: "05",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 441,
      left: 1193,
      width: 176,
      height: 151,
      face: "West",
    },
    {
      type: "F(M)",
      block: "B",
      level: "19",
      unit: "06",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 276,
      left: 1178,
      width: 169,
      height: 131,
      face: "East",
    },
    {
      type: "C(M)",
      block: "B",
      level: "19",
      unit: "07",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 276,
      left: 1351,
      width: 129,
      height: 142,
      face: "East",
    },
    {
      type: "D(M)",
      block: "B",
      level: "19",
      unit: "08",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 281,
      left: 1488,
      width: 153,
      height: 137,
      face: "East",
    },
    {
      type: "G(M)",
      block: "B",
      level: "19",
      unit: "09",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 278,
      left: 1649,
      width: 175,
      height: 147,
      face: "East",
    },
    {
      type: "B(M)",
      block: "B",
      level: "19",
      unit: "10",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 446,
      left: 1665,
      width: 160,
      height: 90,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "19",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 542,
      left: 1665,
      width: 157,
      height: 86,
      face: "South",
    },
    {
      type: "B(M)",
      block: "B",
      level: "19",
      unit: "12",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 631,
      left: 1666,
      width: 156,
      height: 87,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "19",
      unit: "13",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 720,
      left: 1666,
      width: 160,
      height: 89,
      face: "South",
    },
    {
      type: "G(M)",
      block: "B",
      level: "19",
      unit: "13A",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 814,
      left: 1665,
      width: 145,
      height: 166,
      face: "South",
    },
    {
      type: "B",
      block: "A",
      level: "19",
      unit: "05",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 424,
      left: 743,
      width: 92,
      height: 152,
      face: "West",
    },
    {
      type: "E(M)",
      block: "A",
      level: "19",
      unit: "06",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 444,
      left: 838,
      width: 178,
      height: 146,
      face: "West",
    },
    {
      type: "F",
      block: "A",
      level: "19",
      unit: "07",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 861,
      width: 166,
      height: 139,
      face: "East",
    },
    {
      type: "C",
      block: "A",
      level: "19",
      unit: "08",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 725,
      width: 131,
      height: 141,
      face: "East",
    },
    {
      type: "D",
      block: "A",
      level: "19",
      unit: "09",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 563,
      width: 158,
      height: 142,
      face: "East",
    },
    {
      type: "G",
      block: "A",
      level: "19",
      unit: "10",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 386,
      width: 173,
      height: 138,
      face: "East",
    },
    {
      type: "B",
      block: "A",
      level: "19",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 448,
      left: 386,
      width: 155,
      height: 92,
      face: "North",
    },
  ],
  [
    {
      type: "E(M)",
      block: "B",
      level: "20",
      unit: "01",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 797,
      left: 1484,
      width: 154,
      height: 169,
      face: "North",
    },
    {
      type: "B",
      block: "B",
      level: "20",
      unit: "02",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 713,
      left: 1483,
      width: 155,
      height: 87,
      face: "North",
    },
    {
      type: "B1(M)",
      block: "B",
      level: "20",
      unit: "03",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 622,
      left: 1485,
      width: 152,
      height: 89,
      face: "North",
    },
    {
      type: "B(M)",
      block: "B",
      level: "20",
      unit: "03A",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 444,
      left: 1375,
      width: 89,
      height: 154,
      face: "West",
    },
    {
      type: "E",
      block: "B",
      level: "20",
      unit: "05",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 441,
      left: 1193,
      width: 176,
      height: 151,
      face: "West",
    },
    {
      type: "F(M)",
      block: "B",
      level: "20",
      unit: "06",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 276,
      left: 1178,
      width: 169,
      height: 131,
      face: "East",
    },
    {
      type: "C(M)",
      block: "B",
      level: "20",
      unit: "07",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 276,
      left: 1351,
      width: 129,
      height: 142,
      face: "East",
    },
    {
      type: "D(M)",
      block: "B",
      level: "20",
      unit: "08",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 281,
      left: 1488,
      width: 153,
      height: 137,
      face: "East",
    },
    {
      type: "G(M)",
      block: "B",
      level: "20",
      unit: "09",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 278,
      left: 1649,
      width: 175,
      height: 147,
      face: "East",
    },
    {
      type: "B(M)",
      block: "B",
      level: "20",
      unit: "10",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 446,
      left: 1665,
      width: 160,
      height: 90,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "20",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 542,
      left: 1665,
      width: 157,
      height: 86,
      face: "South",
    },
    {
      type: "B(M)",
      block: "B",
      level: "20",
      unit: "12",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 631,
      left: 1666,
      width: 156,
      height: 87,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "20",
      unit: "13",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 720,
      left: 1666,
      width: 160,
      height: 89,
      face: "South",
    },
    {
      type: "G(M)",
      block: "B",
      level: "20",
      unit: "13A",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 814,
      left: 1665,
      width: 145,
      height: 166,
      face: "South",
    },
    {
      type: "B",
      block: "A",
      level: "20",
      unit: "05",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 424,
      left: 743,
      width: 92,
      height: 152,
      face: "West",
    },
    {
      type: "E(M)",
      block: "A",
      level: "20",
      unit: "06",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 444,
      left: 838,
      width: 178,
      height: 146,
      face: "West",
    },
    {
      type: "F",
      block: "A",
      level: "20",
      unit: "07",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 861,
      width: 166,
      height: 139,
      face: "East",
    },
    {
      type: "C",
      block: "A",
      level: "20",
      unit: "08",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 725,
      width: 131,
      height: 141,
      face: "East",
    },
    {
      type: "D",
      block: "A",
      level: "20",
      unit: "09",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 563,
      width: 158,
      height: 142,
      face: "East",
    },
    {
      type: "G",
      block: "A",
      level: "20",
      unit: "10",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 386,
      width: 173,
      height: 138,
      face: "East",
    },
    {
      type: "B",
      block: "A",
      level: "20",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 448,
      left: 386,
      width: 155,
      height: 92,
      face: "North",
    },
  ],
  [
    {
      type: "E(M)",
      block: "B",
      level: "21",
      unit: "01",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 797,
      left: 1484,
      width: 154,
      height: 169,
      face: "North",
    },
    {
      type: "B",
      block: "B",
      level: "21",
      unit: "02",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 713,
      left: 1483,
      width: 155,
      height: 87,
      face: "North",
    },
    {
      type: "B1(M)",
      block: "B",
      level: "21",
      unit: "03",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 622,
      left: 1485,
      width: 152,
      height: 89,
      face: "North",
    },
    {
      type: "B(M)",
      block: "B",
      level: "21",
      unit: "03A",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 444,
      left: 1375,
      width: 89,
      height: 154,
      face: "West",
    },
    {
      type: "E",
      block: "B",
      level: "21",
      unit: "05",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 441,
      left: 1193,
      width: 176,
      height: 151,
      face: "West",
    },
    {
      type: "F(M)",
      block: "B",
      level: "21",
      unit: "06",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 276,
      left: 1178,
      width: 169,
      height: 131,
      face: "East",
    },
    {
      type: "C(M)",
      block: "B",
      level: "21",
      unit: "07",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 276,
      left: 1351,
      width: 129,
      height: 142,
      face: "East",
    },
    {
      type: "D(M)",
      block: "B",
      level: "21",
      unit: "08",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 281,
      left: 1488,
      width: 153,
      height: 137,
      face: "East",
    },
    {
      type: "G(M)",
      block: "B",
      level: "21",
      unit: "09",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 278,
      left: 1649,
      width: 175,
      height: 147,
      face: "East",
    },
    {
      type: "B(M)",
      block: "B",
      level: "21",
      unit: "10",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 446,
      left: 1665,
      width: 160,
      height: 90,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "21",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 542,
      left: 1665,
      width: 157,
      height: 86,
      face: "South",
    },
    {
      type: "B(M)",
      block: "B",
      level: "21",
      unit: "12",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 631,
      left: 1666,
      width: 156,
      height: 87,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "21",
      unit: "13",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 720,
      left: 1666,
      width: 160,
      height: 89,
      face: "South",
    },
    {
      type: "G(M)",
      block: "B",
      level: "21",
      unit: "13A",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 814,
      left: 1665,
      width: 145,
      height: 166,
      face: "South",
    },
    {
      type: "B",
      block: "A",
      level: "21",
      unit: "05",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 424,
      left: 743,
      width: 92,
      height: 152,
      face: "West",
    },
    {
      type: "E(M)",
      block: "A",
      level: "21",
      unit: "06",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 444,
      left: 838,
      width: 178,
      height: 146,
      face: "West",
    },
    {
      type: "F",
      block: "A",
      level: "21",
      unit: "07",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 861,
      width: 166,
      height: 139,
      face: "East",
    },
    {
      type: "C",
      block: "A",
      level: "21",
      unit: "08",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 725,
      width: 131,
      height: 141,
      face: "East",
    },
    {
      type: "D",
      block: "A",
      level: "21",
      unit: "09",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 563,
      width: 158,
      height: 142,
      face: "East",
    },
    {
      type: "G",
      block: "A",
      level: "21",
      unit: "10",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 386,
      width: 173,
      height: 138,
      face: "East",
    },
    {
      type: "B1",
      block: "A",
      level: "21",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 448,
      left: 386,
      width: 155,
      height: 92,
      face: "North",
    },
  ],
  [
    {
      type: "E(M)",
      block: "B",
      level: "22",
      unit: "01",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 797,
      left: 1484,
      width: 154,
      height: 169,
      face: "North",
    },
    {
      type: "B",
      block: "B",
      level: "22",
      unit: "02",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 713,
      left: 1483,
      width: 155,
      height: 87,
      face: "North",
    },
    {
      type: "B1(M)",
      block: "B",
      level: "22",
      unit: "03",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 622,
      left: 1485,
      width: 152,
      height: 89,
      face: "North",
    },
    {
      type: "B(M)",
      block: "B",
      level: "22",
      unit: "03A",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 444,
      left: 1375,
      width: 89,
      height: 154,
      face: "West",
    },
    {
      type: "E",
      block: "B",
      level: "22",
      unit: "05",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 441,
      left: 1193,
      width: 176,
      height: 151,
      face: "West",
    },
    {
      type: "F(M)",
      block: "B",
      level: "22",
      unit: "06",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 276,
      left: 1178,
      width: 169,
      height: 131,
      face: "East",
    },
    {
      type: "C(M)",
      block: "B",
      level: "22",
      unit: "07",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 276,
      left: 1351,
      width: 129,
      height: 142,
      face: "East",
    },
    {
      type: "D(M)",
      block: "B",
      level: "22",
      unit: "08",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 281,
      left: 1488,
      width: 153,
      height: 137,
      face: "East",
    },
    {
      type: "G(M)",
      block: "B",
      level: "22",
      unit: "09",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 278,
      left: 1649,
      width: 175,
      height: 147,
      face: "East",
    },
    {
      type: "B(M)",
      block: "B",
      level: "22",
      unit: "10",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 446,
      left: 1665,
      width: 160,
      height: 90,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "22",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 542,
      left: 1665,
      width: 157,
      height: 86,
      face: "South",
    },
    {
      type: "B(M)",
      block: "B",
      level: "22",
      unit: "12",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 631,
      left: 1666,
      width: 156,
      height: 87,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "22",
      unit: "13",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 720,
      left: 1666,
      width: 160,
      height: 89,
      face: "South",
    },
    {
      type: "G(M)",
      block: "B",
      level: "22",
      unit: "13A",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 814,
      left: 1665,
      width: 145,
      height: 166,
      face: "South",
    },
    {
      type: "B",
      block: "A",
      level: "22",
      unit: "05",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 424,
      left: 743,
      width: 92,
      height: 152,
      face: "West",
    },
    {
      type: "E(M)",
      block: "A",
      level: "22",
      unit: "06",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 444,
      left: 838,
      width: 178,
      height: 146,
      face: "West",
    },
    {
      type: "F",
      block: "A",
      level: "22",
      unit: "07",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 861,
      width: 166,
      height: 139,
      face: "East",
    },
    {
      type: "C",
      block: "A",
      level: "22",
      unit: "08",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 725,
      width: 131,
      height: 141,
      face: "East",
    },
    {
      type: "D",
      block: "A",
      level: "22",
      unit: "09",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 563,
      width: 158,
      height: 142,
      face: "East",
    },
    {
      type: "G",
      block: "A",
      level: "22",
      unit: "10",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 386,
      width: 173,
      height: 138,
      face: "East",
    },
    {
      type: "B1",
      block: "A",
      level: "22",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 448,
      left: 386,
      width: 155,
      height: 92,
      face: "North",
    },
  ],
  [
    {
      type: "E(M)",
      block: "B",
      level: "23",
      unit: "01",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 797,
      left: 1484,
      width: 154,
      height: 169,
      face: "North",
    },
    {
      type: "B",
      block: "B",
      level: "23",
      unit: "02",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 713,
      left: 1483,
      width: 155,
      height: 87,
      face: "North",
    },
    {
      type: "B1(M)",
      block: "B",
      level: "23",
      unit: "03",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 622,
      left: 1485,
      width: 152,
      height: 89,
      face: "North",
    },
    {
      type: "B(M)",
      block: "B",
      level: "23",
      unit: "03A",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 444,
      left: 1375,
      width: 89,
      height: 154,
      face: "West",
    },
    {
      type: "E",
      block: "B",
      level: "23",
      unit: "05",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 441,
      left: 1193,
      width: 176,
      height: 151,
      face: "West",
    },
    {
      type: "F(M)",
      block: "B",
      level: "23",
      unit: "06",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 276,
      left: 1178,
      width: 169,
      height: 131,
      face: "East",
    },
    {
      type: "C(M)",
      block: "B",
      level: "23",
      unit: "07",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 276,
      left: 1351,
      width: 129,
      height: 142,
      face: "East",
    },
    {
      type: "D(M)",
      block: "B",
      level: "23",
      unit: "08",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 281,
      left: 1488,
      width: 153,
      height: 137,
      face: "East",
    },
    {
      type: "G(M)",
      block: "B",
      level: "23",
      unit: "09",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 278,
      left: 1649,
      width: 175,
      height: 147,
      face: "East",
    },
    {
      type: "B(M)",
      block: "B",
      level: "23",
      unit: "10",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 446,
      left: 1665,
      width: 160,
      height: 90,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "23",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 542,
      left: 1665,
      width: 157,
      height: 86,
      face: "South",
    },
    {
      type: "B(M)",
      block: "B",
      level: "23",
      unit: "12",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 631,
      left: 1666,
      width: 156,
      height: 87,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "23",
      unit: "13",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 720,
      left: 1666,
      width: 160,
      height: 89,
      face: "South",
    },
    {
      type: "G(M)",
      block: "B",
      level: "23",
      unit: "13A",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 814,
      left: 1665,
      width: 145,
      height: 166,
      face: "South",
    },
    {
      type: "B",
      block: "A",
      level: "23",
      unit: "05",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 424,
      left: 743,
      width: 92,
      height: 152,
      face: "West",
    },
    {
      type: "E(M)",
      block: "A",
      level: "23",
      unit: "06",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 444,
      left: 838,
      width: 178,
      height: 146,
      face: "West",
    },
    {
      type: "F",
      block: "A",
      level: "23",
      unit: "07",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 861,
      width: 166,
      height: 139,
      face: "East",
    },
    {
      type: "C",
      block: "A",
      level: "23",
      unit: "08",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 725,
      width: 131,
      height: 141,
      face: "East",
    },
    {
      type: "D",
      block: "A",
      level: "23",
      unit: "09",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 563,
      width: 158,
      height: 142,
      face: "East",
    },
    {
      type: "G",
      block: "A",
      level: "23",
      unit: "10",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 386,
      width: 173,
      height: 138,
      face: "East",
    },
    {
      type: "B1",
      block: "A",
      level: "23",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 448,
      left: 386,
      width: 155,
      height: 92,
      face: "North",
    },
  ],
  [
    {
      type: "E(M)",
      block: "B",
      level: "24",
      unit: "01",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 797,
      left: 1484,
      width: 154,
      height: 169,
      face: "North",
    },
    {
      type: "B",
      block: "B",
      level: "24",
      unit: "02",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 713,
      left: 1483,
      width: 155,
      height: 87,
      face: "North",
    },
    {
      type: "B1(M)",
      block: "B",
      level: "24",
      unit: "03",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 622,
      left: 1485,
      width: 152,
      height: 89,
      face: "North",
    },
    {
      type: "B(M)",
      block: "B",
      level: "24",
      unit: "03A",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 444,
      left: 1375,
      width: 89,
      height: 154,
      face: "West",
    },
    {
      type: "E",
      block: "B",
      level: "24",
      unit: "05",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 441,
      left: 1193,
      width: 176,
      height: 151,
      face: "West",
    },
    {
      type: "F(M)",
      block: "B",
      level: "24",
      unit: "06",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 276,
      left: 1178,
      width: 169,
      height: 131,
      face: "East",
    },
    {
      type: "C(M)",
      block: "B",
      level: "24",
      unit: "07",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 276,
      left: 1351,
      width: 129,
      height: 142,
      face: "East",
    },
    {
      type: "D(M)",
      block: "B",
      level: "24",
      unit: "08",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 281,
      left: 1488,
      width: 153,
      height: 137,
      face: "East",
    },
    {
      type: "G(M)",
      block: "B",
      level: "24",
      unit: "09",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 278,
      left: 1649,
      width: 175,
      height: 147,
      face: "East",
    },
    {
      type: "B(M)",
      block: "B",
      level: "24",
      unit: "10",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 446,
      left: 1665,
      width: 160,
      height: 90,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "24",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 542,
      left: 1665,
      width: 157,
      height: 86,
      face: "South",
    },
    {
      type: "B(M)",
      block: "B",
      level: "24",
      unit: "12",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 631,
      left: 1666,
      width: 156,
      height: 87,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "24",
      unit: "13",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 720,
      left: 1666,
      width: 160,
      height: 89,
      face: "South",
    },
    {
      type: "G(M)",
      block: "B",
      level: "24",
      unit: "13A",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 814,
      left: 1665,
      width: 145,
      height: 166,
      face: "South",
    },
    {
      type: "B",
      block: "A",
      level: "24",
      unit: "05",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 424,
      left: 743,
      width: 92,
      height: 152,
      face: "West",
    },
    {
      type: "E(M)",
      block: "A",
      level: "24",
      unit: "06",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 444,
      left: 838,
      width: 178,
      height: 146,
      face: "West",
    },
    {
      type: "F",
      block: "A",
      level: "24",
      unit: "07",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 861,
      width: 166,
      height: 139,
      face: "East",
    },
    {
      type: "C",
      block: "A",
      level: "24",
      unit: "08",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 725,
      width: 131,
      height: 141,
      face: "East",
    },
    {
      type: "D",
      block: "A",
      level: "24",
      unit: "09",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 563,
      width: 158,
      height: 142,
      face: "East",
    },
    {
      type: "G",
      block: "A",
      level: "24",
      unit: "10",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 386,
      width: 173,
      height: 138,
      face: "East",
    },
    {
      type: "B1",
      block: "A",
      level: "24",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 448,
      left: 386,
      width: 155,
      height: 92,
      face: "North",
    },
  ],
  [
    {
      type: "E(M)",
      block: "B",
      level: "25",
      unit: "01",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 797,
      left: 1484,
      width: 154,
      height: 169,
      face: "North",
    },
    {
      type: "B",
      block: "B",
      level: "25",
      unit: "02",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 713,
      left: 1483,
      width: 155,
      height: 87,
      face: "North",
    },
    {
      type: "B1(M)",
      block: "B",
      level: "25",
      unit: "03",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 622,
      left: 1485,
      width: 152,
      height: 89,
      face: "North",
    },
    {
      type: "B(M)",
      block: "B",
      level: "25",
      unit: "03A",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 444,
      left: 1375,
      width: 89,
      height: 154,
      face: "West",
    },
    {
      type: "E",
      block: "B",
      level: "25",
      unit: "05",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 441,
      left: 1193,
      width: 176,
      height: 151,
      face: "West",
    },
    {
      type: "F(M)",
      block: "B",
      level: "25",
      unit: "06",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 276,
      left: 1178,
      width: 169,
      height: 131,
      face: "East",
    },
    {
      type: "C(M)",
      block: "B",
      level: "25",
      unit: "07",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 276,
      left: 1351,
      width: 129,
      height: 142,
      face: "East",
    },
    {
      type: "D(M)",
      block: "B",
      level: "25",
      unit: "08",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 281,
      left: 1488,
      width: 153,
      height: 137,
      face: "East",
    },
    {
      type: "G(M)",
      block: "B",
      level: "25",
      unit: "09",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 278,
      left: 1649,
      width: 175,
      height: 147,
      face: "East",
    },
    {
      type: "B(M)",
      block: "B",
      level: "25",
      unit: "10",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 446,
      left: 1665,
      width: 160,
      height: 90,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "25",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 542,
      left: 1665,
      width: 157,
      height: 86,
      face: "South",
    },
    {
      type: "B(M)",
      block: "B",
      level: "25",
      unit: "12",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 631,
      left: 1666,
      width: 156,
      height: 87,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "25",
      unit: "13",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 720,
      left: 1666,
      width: 160,
      height: 89,
      face: "South",
    },
    {
      type: "G(M)",
      block: "B",
      level: "25",
      unit: "13A",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 814,
      left: 1665,
      width: 145,
      height: 166,
      face: "South",
    },
    {
      type: "B",
      block: "A",
      level: "25",
      unit: "05",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 424,
      left: 743,
      width: 92,
      height: 152,
      face: "West",
    },
    {
      type: "E(M)",
      block: "A",
      level: "25",
      unit: "06",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 444,
      left: 838,
      width: 178,
      height: 146,
      face: "West",
    },
    {
      type: "F",
      block: "A",
      level: "25",
      unit: "07",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 861,
      width: 166,
      height: 139,
      face: "East",
    },
    {
      type: "C",
      block: "A",
      level: "25",
      unit: "08",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 725,
      width: 131,
      height: 141,
      face: "East",
    },
    {
      type: "D",
      block: "A",
      level: "25",
      unit: "09",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 563,
      width: 158,
      height: 142,
      face: "East",
    },
    {
      type: "G",
      block: "A",
      level: "25",
      unit: "10",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 386,
      width: 173,
      height: 138,
      face: "East",
    },
    {
      type: "B1",
      block: "A",
      level: "25",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 448,
      left: 386,
      width: 155,
      height: 92,
      face: "North",
    },
  ],
  [
    {
      type: "E(M)",
      block: "B",
      level: "26",
      unit: "01",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 797,
      left: 1484,
      width: 154,
      height: 169,
      face: "North",
    },
    {
      type: "B",
      block: "B",
      level: "26",
      unit: "02",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 713,
      left: 1483,
      width: 155,
      height: 87,
      face: "North",
    },
    {
      type: "B1(M)",
      block: "B",
      level: "26",
      unit: "03",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 622,
      left: 1485,
      width: 152,
      height: 89,
      face: "North",
    },
    {
      type: "B(M)",
      block: "B",
      level: "26",
      unit: "03A",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 444,
      left: 1375,
      width: 89,
      height: 154,
      face: "West",
    },
    {
      type: "E",
      block: "B",
      level: "26",
      unit: "05",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 441,
      left: 1193,
      width: 176,
      height: 151,
      face: "West",
    },
    {
      type: "F(M)",
      block: "B",
      level: "26",
      unit: "06",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 276,
      left: 1178,
      width: 169,
      height: 131,
      face: "East",
    },
    {
      type: "C(M)",
      block: "B",
      level: "26",
      unit: "07",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 276,
      left: 1351,
      width: 129,
      height: 142,
      face: "East",
    },
    {
      type: "D(M)",
      block: "B",
      level: "26",
      unit: "08",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 281,
      left: 1488,
      width: 153,
      height: 137,
      face: "East",
    },
    {
      type: "G(M)",
      block: "B",
      level: "26",
      unit: "09",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 278,
      left: 1649,
      width: 175,
      height: 147,
      face: "East",
    },
    {
      type: "B(M)",
      block: "B",
      level: "26",
      unit: "10",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 446,
      left: 1665,
      width: 160,
      height: 90,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "26",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 542,
      left: 1665,
      width: 157,
      height: 86,
      face: "South",
    },
    {
      type: "B(M)",
      block: "B",
      level: "26",
      unit: "12",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 631,
      left: 1666,
      width: 156,
      height: 87,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "26",
      unit: "13",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 720,
      left: 1666,
      width: 160,
      height: 89,
      face: "South",
    },
    {
      type: "G(M)",
      block: "B",
      level: "26",
      unit: "13A",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 814,
      left: 1665,
      width: 145,
      height: 166,
      face: "South",
    },
    {
      type: "B",
      block: "A",
      level: "26",
      unit: "05",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 424,
      left: 743,
      width: 92,
      height: 152,
      face: "West",
    },
    {
      type: "E(M)",
      block: "A",
      level: "26",
      unit: "06",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 444,
      left: 838,
      width: 178,
      height: 146,
      face: "West",
    },
    {
      type: "F",
      block: "A",
      level: "26",
      unit: "07",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 861,
      width: 166,
      height: 139,
      face: "East",
    },
    {
      type: "C",
      block: "A",
      level: "26",
      unit: "08",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 725,
      width: 131,
      height: 141,
      face: "East",
    },
    {
      type: "D",
      block: "A",
      level: "26",
      unit: "09",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 563,
      width: 158,
      height: 142,
      face: "East",
    },
    {
      type: "G",
      block: "A",
      level: "26",
      unit: "10",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 386,
      width: 173,
      height: 138,
      face: "East",
    },
    {
      type: "B1",
      block: "A",
      level: "26",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 448,
      left: 386,
      width: 155,
      height: 92,
      face: "North",
    },
  ],
  [
    {
      type: "E(M)",
      block: "B",
      level: "27",
      unit: "01",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 797,
      left: 1484,
      width: 154,
      height: 169,
      face: "North",
    },
    {
      type: "B",
      block: "B",
      level: "27",
      unit: "02",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 713,
      left: 1483,
      width: 155,
      height: 87,
      face: "North",
    },
    {
      type: "B1(M)",
      block: "B",
      level: "27",
      unit: "03",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 622,
      left: 1485,
      width: 152,
      height: 89,
      face: "North",
    },
    {
      type: "B(M)",
      block: "B",
      level: "27",
      unit: "03A",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 444,
      left: 1375,
      width: 89,
      height: 154,
      face: "West",
    },
    {
      type: "E",
      block: "B",
      level: "27",
      unit: "05",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 441,
      left: 1193,
      width: 176,
      height: 151,
      face: "West",
    },
    {
      type: "F(M)",
      block: "B",
      level: "27",
      unit: "06",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 276,
      left: 1178,
      width: 169,
      height: 131,
      face: "East",
    },
    {
      type: "C(M)",
      block: "B",
      level: "27",
      unit: "07",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 276,
      left: 1351,
      width: 129,
      height: 142,
      face: "East",
    },
    {
      type: "D(M)",
      block: "B",
      level: "27",
      unit: "08",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 281,
      left: 1488,
      width: 153,
      height: 137,
      face: "East",
    },
    {
      type: "G(M)",
      block: "B",
      level: "27",
      unit: "09",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 278,
      left: 1649,
      width: 175,
      height: 147,
      face: "East",
    },
    {
      type: "B(M)",
      block: "B",
      level: "27",
      unit: "10",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 446,
      left: 1665,
      width: 160,
      height: 90,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "27",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 542,
      left: 1665,
      width: 157,
      height: 86,
      face: "South",
    },
    {
      type: "B(M)",
      block: "B",
      level: "27",
      unit: "12",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 631,
      left: 1666,
      width: 156,
      height: 87,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "27",
      unit: "13",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 720,
      left: 1666,
      width: 160,
      height: 89,
      face: "South",
    },
    {
      type: "G(M)",
      block: "B",
      level: "27",
      unit: "13A",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 814,
      left: 1665,
      width: 145,
      height: 166,
      face: "South",
    },
    {
      type: "B",
      block: "A",
      level: "27",
      unit: "05",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 424,
      left: 743,
      width: 92,
      height: 152,
      face: "West",
    },
    {
      type: "E(M)",
      block: "A",
      level: "27",
      unit: "06",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 444,
      left: 838,
      width: 178,
      height: 146,
      face: "West",
    },
    {
      type: "F",
      block: "A",
      level: "27",
      unit: "07",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 861,
      width: 166,
      height: 139,
      face: "East",
    },
    {
      type: "C",
      block: "A",
      level: "27",
      unit: "08",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 725,
      width: 131,
      height: 141,
      face: "East",
    },
    {
      type: "D",
      block: "A",
      level: "27",
      unit: "09",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 563,
      width: 158,
      height: 142,
      face: "East",
    },
    {
      type: "G",
      block: "A",
      level: "27",
      unit: "10",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 386,
      width: 173,
      height: 138,
      face: "East",
    },
    {
      type: "B1",
      block: "A",
      level: "27",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 448,
      left: 386,
      width: 155,
      height: 92,
      face: "North",
    },
  ],
  [
    {
      type: "E(M)",
      block: "B",
      level: "28",
      unit: "01",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 797,
      left: 1484,
      width: 154,
      height: 169,
      face: "North",
    },
    {
      type: "B",
      block: "B",
      level: "28",
      unit: "02",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 713,
      left: 1483,
      width: 155,
      height: 87,
      face: "North",
    },
    {
      type: "B1(M)",
      block: "B",
      level: "28",
      unit: "03",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 622,
      left: 1485,
      width: 152,
      height: 89,
      face: "North",
    },
    {
      type: "B(M)",
      block: "B",
      level: "28",
      unit: "03A",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 444,
      left: 1375,
      width: 89,
      height: 154,
      face: "West",
    },
    {
      type: "E",
      block: "B",
      level: "28",
      unit: "05",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 441,
      left: 1193,
      width: 176,
      height: 151,
      face: "West",
    },
    {
      type: "F(M)",
      block: "B",
      level: "28",
      unit: "06",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 276,
      left: 1178,
      width: 169,
      height: 131,
      face: "East",
    },
    {
      type: "C(M)",
      block: "B",
      level: "28",
      unit: "07",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 276,
      left: 1351,
      width: 129,
      height: 142,
      face: "East",
    },
    {
      type: "D(M)",
      block: "B",
      level: "28",
      unit: "08",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 281,
      left: 1488,
      width: 153,
      height: 137,
      face: "East",
    },
    {
      type: "G(M)",
      block: "B",
      level: "28",
      unit: "09",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 278,
      left: 1649,
      width: 175,
      height: 147,
      face: "East",
    },
    {
      type: "B(M)",
      block: "B",
      level: "28",
      unit: "10",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 446,
      left: 1665,
      width: 160,
      height: 90,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "28",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 542,
      left: 1665,
      width: 157,
      height: 86,
      face: "South",
    },
    {
      type: "B(M)",
      block: "B",
      level: "28",
      unit: "12",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 631,
      left: 1666,
      width: 156,
      height: 87,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "28",
      unit: "13",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 720,
      left: 1666,
      width: 160,
      height: 89,
      face: "South",
    },
    {
      type: "G(M)",
      block: "B",
      level: "28",
      unit: "13A",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 814,
      left: 1665,
      width: 145,
      height: 166,
      face: "South",
    },
    {
      type: "B",
      block: "A",
      level: "28",
      unit: "05",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 424,
      left: 743,
      width: 92,
      height: 152,
      face: "West",
    },
    {
      type: "E(M)",
      block: "A",
      level: "28",
      unit: "06",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 444,
      left: 838,
      width: 178,
      height: 146,
      face: "West",
    },
    {
      type: "F",
      block: "A",
      level: "28",
      unit: "07",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 861,
      width: 166,
      height: 139,
      face: "East",
    },
    {
      type: "C",
      block: "A",
      level: "28",
      unit: "08",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 725,
      width: 131,
      height: 141,
      face: "East",
    },
    {
      type: "D",
      block: "A",
      level: "28",
      unit: "09",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 563,
      width: 158,
      height: 142,
      face: "East",
    },
    {
      type: "G",
      block: "A",
      level: "28",
      unit: "10",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 386,
      width: 173,
      height: 138,
      face: "East",
    },
    {
      type: "B1",
      block: "A",
      level: "28",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 448,
      left: 386,
      width: 155,
      height: 92,
      face: "North",
    },
  ],
  [
    {
      type: "E(M)",
      block: "B",
      level: "29",
      unit: "01",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 797,
      left: 1484,
      width: 154,
      height: 169,
      face: "North",
    },
    {
      type: "B",
      block: "B",
      level: "29",
      unit: "02",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 713,
      left: 1483,
      width: 155,
      height: 87,
      face: "North",
    },
    {
      type: "B1(M)",
      block: "B",
      level: "29",
      unit: "03",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 622,
      left: 1485,
      width: 152,
      height: 89,
      face: "North",
    },
    {
      type: "B(M)",
      block: "B",
      level: "29",
      unit: "03A",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 444,
      left: 1375,
      width: 89,
      height: 154,
      face: "West",
    },
    {
      type: "E",
      block: "B",
      level: "29",
      unit: "05",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 441,
      left: 1193,
      width: 176,
      height: 151,
      face: "West",
    },
    {
      type: "F(M)",
      block: "B",
      level: "29",
      unit: "06",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 276,
      left: 1178,
      width: 169,
      height: 131,
      face: "East",
    },
    {
      type: "C(M)",
      block: "B",
      level: "29",
      unit: "07",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 276,
      left: 1351,
      width: 129,
      height: 142,
      face: "East",
    },
    {
      type: "D(M)",
      block: "B",
      level: "29",
      unit: "08",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 281,
      left: 1488,
      width: 153,
      height: 137,
      face: "East",
    },
    {
      type: "G(M)",
      block: "B",
      level: "29",
      unit: "09",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 278,
      left: 1649,
      width: 175,
      height: 147,
      face: "East",
    },
    {
      type: "B(M)",
      block: "B",
      level: "29",
      unit: "10",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 446,
      left: 1665,
      width: 160,
      height: 90,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "29",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 542,
      left: 1665,
      width: 157,
      height: 86,
      face: "South",
    },
    {
      type: "B(M)",
      block: "B",
      level: "29",
      unit: "12",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 631,
      left: 1666,
      width: 156,
      height: 87,
      face: "South",
    },
    {
      type: "B",
      block: "B",
      level: "29",
      unit: "13",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 720,
      left: 1666,
      width: 160,
      height: 89,
      face: "South",
    },
    {
      type: "G(M)",
      block: "B",
      level: "29",
      unit: "13A",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 814,
      left: 1665,
      width: 145,
      height: 166,
      face: "South",
    },
    {
      type: "B",
      block: "A",
      level: "29",
      unit: "05",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 424,
      left: 743,
      width: 92,
      height: 152,
      face: "West",
    },
    {
      type: "E(M)",
      block: "A",
      level: "29",
      unit: "06",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 444,
      left: 838,
      width: 178,
      height: 146,
      face: "West",
    },
    {
      type: "F",
      block: "A",
      level: "29",
      unit: "07",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 861,
      width: 166,
      height: 139,
      face: "East",
    },
    {
      type: "C",
      block: "A",
      level: "29",
      unit: "08",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 725,
      width: 131,
      height: 141,
      face: "East",
    },
    {
      type: "D",
      block: "A",
      level: "29",
      unit: "09",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 563,
      width: 158,
      height: 142,
      face: "East",
    },
    {
      type: "G",
      block: "A",
      level: "29",
      unit: "10",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 386,
      width: 173,
      height: 138,
      face: "East",
    },
    {
      type: "B1",
      block: "A",
      level: "29",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 448,
      left: 386,
      width: 155,
      height: 92,
      face: "North",
    },
  ],
  [
    {
      type: "B(M)",
      block: "B",
      level: "30",
      unit: "03A",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 444,
      left: 1375,
      width: 89,
      height: 154,
      face: "West",
    },
    {
      type: "E",
      block: "B",
      level: "30",
      unit: "05",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 441,
      left: 1193,
      width: 176,
      height: 151,
      face: "West",
    },
    {
      type: "F(M)",
      block: "B",
      level: "30",
      unit: "06",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 276,
      left: 1178,
      width: 169,
      height: 131,
      face: "East",
    },
    {
      type: "C(M)",
      block: "B",
      level: "30",
      unit: "07",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 276,
      left: 1351,
      width: 129,
      height: 142,
      face: "East",
    },
    {
      type: "D(M)",
      block: "B",
      level: "30",
      unit: "08",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 281,
      left: 1488,
      width: 153,
      height: 137,
      face: "East",
    },
    {
      type: "G(M)",
      block: "B",
      level: "30",
      unit: "09",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 278,
      left: 1649,
      width: 175,
      height: 147,
      face: "East",
    },
    {
      type: "B(M)",
      block: "B",
      level: "30",
      unit: "10",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 446,
      left: 1665,
      width: 160,
      height: 90,
      face: "South",
    },
    {
      type: "B",
      block: "A",
      level: "30",
      unit: "05",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 424,
      left: 743,
      width: 92,
      height: 152,
      face: "West",
    },
    {
      type: "E(M)",
      block: "A",
      level: "30",
      unit: "06",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 444,
      left: 838,
      width: 178,
      height: 146,
      face: "West",
    },
    {
      type: "F",
      block: "A",
      level: "30",
      unit: "07",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 861,
      width: 166,
      height: 139,
      face: "East",
    },
    {
      type: "C",
      block: "A",
      level: "30",
      unit: "08",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 725,
      width: 131,
      height: 141,
      face: "East",
    },
    {
      type: "D",
      block: "A",
      level: "30",
      unit: "09",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 563,
      width: 158,
      height: 142,
      face: "East",
    },
    {
      type: "G",
      block: "A",
      level: "30",
      unit: "10",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 386,
      width: 173,
      height: 138,
      face: "East",
    },
    {
      type: "B1",
      block: "A",
      level: "30",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 448,
      left: 386,
      width: 155,
      height: 92,
      face: "North",
    },
  ],
  [
    {
      type: "B(M)",
      block: "B",
      level: "30",
      unit: "03A",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 444,
      left: 1375,
      width: 89,
      height: 154,
      face: "West",
    },
    {
      type: "E",
      block: "B",
      level: "30",
      unit: "05",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 441,
      left: 1193,
      width: 176,
      height: 151,
      face: "West",
    },
    {
      type: "F(M)",
      block: "B",
      level: "30",
      unit: "06",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 276,
      left: 1178,
      width: 169,
      height: 131,
      face: "East",
    },
    {
      type: "C(M)",
      block: "B",
      level: "30",
      unit: "07",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 276,
      left: 1351,
      width: 129,
      height: 142,
      face: "East",
    },
    {
      type: "D(M)",
      block: "B",
      level: "30",
      unit: "08",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 281,
      left: 1488,
      width: 153,
      height: 137,
      face: "East",
    },
    {
      type: "G(M)",
      block: "B",
      level: "30",
      unit: "09",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 278,
      left: 1649,
      width: 175,
      height: 147,
      face: "East",
    },
    {
      type: "B(M)",
      block: "B",
      level: "30",
      unit: "10",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 446,
      left: 1665,
      width: 160,
      height: 90,
      face: "South",
    },
    {
      type: "B",
      block: "A",
      level: "30",
      unit: "05",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 424,
      left: 743,
      width: 92,
      height: 152,
      face: "West",
    },
    {
      type: "E(M)",
      block: "A",
      level: "30",
      unit: "06",
      sqft: "1,124 Sq.ft",
      bedroom: "3+1",
      bathroom: "3",
      top: 444,
      left: 838,
      width: 178,
      height: 146,
      face: "West",
    },
    {
      type: "F",
      block: "A",
      level: "30",
      unit: "07",
      sqft: "1,136 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 861,
      width: 166,
      height: 139,
      face: "East",
    },
    {
      type: "C",
      block: "A",
      level: "30",
      unit: "08",
      sqft: "974 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 725,
      width: 131,
      height: 141,
      face: "East",
    },
    {
      type: "D",
      block: "A",
      level: "30",
      unit: "09",
      sqft: "1,026 Sq.ft",
      bedroom: "3",
      bathroom: "2",
      top: 280,
      left: 563,
      width: 158,
      height: 142,
      face: "East",
    },
    {
      type: "G",
      block: "A",
      level: "30",
      unit: "10",
      sqft: "1,184 Sq.ft",
      bedroom: "4",
      bathroom: "2",
      top: 282,
      left: 386,
      width: 173,
      height: 138,
      face: "East",
    },
    {
      type: "B1",
      block: "A",
      level: "30",
      unit: "11",
      sqft: "739 Sq.ft",
      bedroom: "2",
      bathroom: "2",
      top: 448,
      left: 386,
      width: 155,
      height: 92,
      face: "North",
    },
  ],
]

export default UnitPoint