import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import {
  Row,
  Badge,
  Col,
  CardBody,
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  Alert,
  Container,
  Modal,
  Collapse,
  Spinner,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// actions
import {
  registerUser,
  loginUser,
  apiError,
  socialLogin,
  showJoinNowAction,
} from "store/actions"

//hapivity asset
import Slider from "react-rangeslider"
import "react-rangeslider/lib/index.css"

const imagesDay = require.context("assets/images/rainfora/facade/day", true)
const imageDayList = imagesDay.keys().map(image => imagesDay(image))
const imagesNight = require.context("assets/images/rainfora/facade/night", true)
const imageNightList = imagesNight.keys().map(image => imagesNight(image))

const Facade = props => {
  const history = useHistory()
  
  useEffect(() => {
    if(props.leftSideBarType == "default")
      setLeftSideBarSize(200)
    else
      setLeftSideBarSize(70)
  }, [props.leftSideBarType])

  const [leftSideBarSize, setLeftSideBarSize] = useState(70)
  
  const [def, setdef] = useState(50)
  const [dayNight, setDayNight] = useState(true)

  return (
    <React.Fragment>
      <MetaTags>
        <title>Rainfora</title>
      </MetaTags>
      <div
        className="page-content p-0"
        style={{ backgroundImage: "radial-gradient(#164A3D, #01221F)" }}
      >
        {/* <div className="container-fluid"> */}
        {/* {pageData &&
          pageData.map((object, index) => componentHandler(object, index))} */}
        {/* <div className="embed-responsive embed-responsive-16by9 ratio ratio-16x9"> */}
        {imageDayList.map((item, index) => (
          <img
            src={imageDayList[index]}
            style={{ display: "none" }}
            key={"preload-day-" + index}
          />
        ))}
        {imageNightList.map((item, index) => (
          <img
            src={imageNightList[index]}
            style={{ display: "none" }}
            key={"preload-night-" + index}
          />
        ))}
        <Row className="">
          <Col className="">
            <Card className="p-0 mb-0">
              <CardBody className="p-0">
                <CardImg
                  src={dayNight ? imageDayList[def] : imageNightList[def]}
                  style={{
                    // height: "295px",
                    height: "100vh",
                    objectFit: "cover",
                  }}
                />
                <Row
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    paddingTop: 16,
                    paddingLeft: 32,
                    paddingRight: 32,
                    // right: 32,
                    backgroundImage:
                      "linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0))",
                  }}
                  className="justify-content-between"
                >
                  <Col>
                    <span className="text-primary font-size-56 font-weight-bold ">
                      {"FACADE"}
                    </span>
                  </Col>
                  {/* <Col className="align-self-center col-auto">
                    <div
                      className="form-check form-switch form-switch-lg"
                      // style={{ position: "absolute", top: 0, right: 0 }}
                    >
                      <input
                        type="checkbox"
                        className="form-check-input"
                        id="customSwitchsizelg"
                        checked={dayNight}
                        onChange={e => {
                          console.log(e.target.checked)
                          setDayNight(e.target.checked)
                        }}
                      />
                      <label
                        className="form-check-label text-white font-size-16"
                        htmlFor="customSwitchsizelg"
                      >
                        {dayNight ? "Day" : "Night"}
                      </label>
                    </div>
                  </Col> */}
                </Row>
                <Row
                  style={{
                    position: "fixed",
                    bottom: 30,
                    left: 500,
                    right: 500,
                  }}
                >
                  <Col>
                    <Slider
                      value={def}
                      orientation="horizontal"
                      min={0}
                      max={99}
                      onChange={value => {
                        setdef(value)
                      }}
                      tooltip={false}
                    />
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Card
          style={{
            backgroundColor: "#002521CC",
            width: "218px",
            backdropFilter: "blur(20px)",
            borderRadius: "20px",
            position: "fixed",
            bottom: 0,
            right: 32 + leftSideBarSize,
          }}
          className="px-4 py-0 mb-3"
        >
          <Row>
            <Col>
              <Row className="my-2">
                <button
                  className={
                    "btn btn-outline-light btn-rounded font-weight-normal font-size-18 px-3 border-none text-start text-color-919695"
                  }
                  style={{
                    borderRadius: "20px",
                    height: 50,
                    whiteSpace: "nowrap",
                    // height: "50px",
                  }}
                  onClick={() => {
                    history.push("/surrounding")
                  }}
                >
                  <span>{"Surroundings"}</span>
                </button>
              </Row>
              <Row className="my-2">
                <button
                  className={
                    "btn btn-outline-light btn-rounded font-weight-normal font-size-18 px-3 border-none text-start text-color-919695"
                  }
                  style={{
                    borderRadius: "20px",
                    height: 50,
                    whiteSpace: "nowrap",
                    // height: "50px",
                  }}
                  onClick={() => {
                    history.push("/map")
                  }}
                >
                  <span>{"Map"}</span>
                </button>
              </Row>
              <Row className="my-2">
                <button
                  className={
                    "btn font-weight-normal font-size-18 px-3 text-start text-primary border-none navigation-option mm-active"
                  }
                  style={{
                    borderRadius: "20px",
                    height: 50,
                    whiteSpace: "nowrap",
                    // height: "50px",
                  }}
                  onClick={() => {
                    history.push("/facade")
                  }}
                >
                  <span>{"Facade"}</span>
                </button>
              </Row>
            </Col>
          </Row>
        </Card>
        <Card
          style={{
            backgroundColor: "#002521CC",
            width: "125px",
            backdropFilter: "blur(20px)",
            borderRadius: "34px",
            position: "fixed",
            bottom: 0,
            right: 32 + leftSideBarSize + 218 + 16,
          }}
          className="px-4 py-0 mb-3"
        >
          <Row className="justify-content-around py-2">
            <Col className="col-auto px-0">
              <button
                className={
                  "btn btn-outline-light btn-rounded font-weight-normal font-size-18 border-none text-center text-color-919695" +
                  (dayNight ? " navigation-option mm-active" : "")
                }
                style={{
                  // borderRadius: "20px",
                  height: 50,
                  width: 50,
                  whiteSpace: "nowrap",
                  // height: "50px",
                }}
                onClick={() => {
                  setDayNight(true)
                }}
              >
                <i className="ph ph-sun"></i>
              </button>
            </Col>
            <Col className="col-auto px-0">
              <button
                className={
                  "btn font-weight-normal font-size-18 text-center text-color-919695 border-none" +
                  (dayNight ? "" : " navigation-option mm-active")
                }
                style={{
                  // borderRadius: "20px",
                  height: 50,
                  width: 50,
                  whiteSpace: "nowrap",
                  // height: "50px",
                }}
                onClick={() => {
                  setDayNight(false)
                }}
              >
                <i className="ph ph-moon"></i>
              </button>
            </Col>
          </Row>
        </Card>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { leftMenu, leftSideBarType } = state.Layout
  return { leftMenu, leftSideBarType }
}

export default withRouter(
  connect(mapStateToProps, { showJoinNowAction })(Facade)
)

Facade.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
}
