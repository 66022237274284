import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState } from "react"

import {
  Row,
  Badge,
  Col,
  CardBody,
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  Alert,
  Container,
  Modal,
  Collapse,
  Spinner,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// actions
import {
  registerUser,
  loginUser,
  apiError,
  socialLogin,
  showJoinNowAction,
} from "store/actions"

//hapivity asset
import { asset } from "helpers/asset_helper"
import ScrollContainer from "react-indiana-drag-scroll"
import * as url from "helpers/url_helper"

//rainfora
import Masonry from "react-responsive-masonry"
import Measure from "react-measure"
import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

const imagesGallery = require.context(
  "assets/images/rainfora/gallery",
  true
)
const imageGalleryList = imagesGallery.keys().map(image => imagesGallery(image))
const imageCaption = [
  "FACADE",
  "RAINFOREST CANOPY",
  "RAINFOREST CANOPY",
  "GLAMPING FOREST",
  "JOGGING TRAIL",
  "STUDY GARDEN",
  "AERIAL VIEW",
  "CO-WORKING SPACE",
  "EDIBLE GARDEN",
  "GYM",
  "LOBBY",
  "NATURE PLAYSCAPE",
  "RETAIL",
]

const Gallery = props => {
  const history = useHistory()

  useEffect(() => {
    if (props.leftSideBarType == "default") setLeftSideBarSize(200)
    else setLeftSideBarSize(70)
  }, [props.leftSideBarType])

  const [leftSideBarSize, setLeftSideBarSize] = useState(70)

  useEffect(() => {
    function onFullscreenChange() {
      setIsFullscreen(Boolean(document.fullscreenElement))
    }

    document.addEventListener("fullscreenchange", onFullscreenChange)

    return () =>
      document.removeEventListener("fullscreenchange", onFullscreenChange)
  }, [])

  const [isFullscreen, setIsFullscreen] = useState(false)
  const [displayBlock, setDisplayBlock] = useState("")

  useEffect(() => {
    if (isFullscreen) {
      setDisplayBlock("none")
    } else {
      setDisplayBlock("")
    }

    // setDisplayBlock("")
  }, [isFullscreen])

  const [photoIndex, setphotoIndex] = useState(0)
  const [isGallery, setisGallery] = useState(false)

  return (
    <React.Fragment>
      <MetaTags>
        <title>Rainfora</title>
      </MetaTags>
      <div
        style={{
          backgroundImage: "radial-gradient(#164A3D, #01221F)",
        }}
      >
        {isGallery ? (
          <Lightbox
            mainSrc={imageGalleryList[photoIndex]}
            nextSrc={
              imageGalleryList[(photoIndex + 1) % imageGalleryList.length]
            }
            prevSrc={
              imageGalleryList[
                (photoIndex + imageGalleryList.length - 1) %
                  imageGalleryList.length
              ]
            }
            enableZoom={false}
            onCloseRequest={() => {
              setisGallery(false)
            }}
            onMovePrevRequest={() => {
              setphotoIndex(
                (photoIndex + imageGalleryList.length - 1) %
                  imageGalleryList.length
              )
            }}
            onMoveNextRequest={() => {
              setphotoIndex((photoIndex + 1) % imageGalleryList.length)
            }}
            imageTitle={imageCaption[photoIndex]}
            imageCaption={"*Artist Impression Only"}
          />
        ) : null}
        {/* <div className="container-fluid"> */}
        <Row className="px-3">
          <Col>
            <span className="text-primary font-size-56 font-weight-bold ">
              {"GALLERY"}
            </span>
          </Col>
        </Row>
        <Row style={{ paddingRight: 32 + leftSideBarSize }}>
          <Col>
            <Masonry columnsCount={3} gutter="16px" className="px-3">
              {imageGalleryList.map((image, i) => (
                <Measure key={i}>
                  {({ measureRef }) => (
                    <div ref={measureRef} style={{ position: "relative" }}>
                      <CardImg
                        src={image}
                        onClick={() => {
                          setisGallery(true)
                          setphotoIndex(i)
                        }}
                        style={{
                          // height: "295px",
                          objectFit: "contain",
                          borderRadius: 16,
                          // borderTopLeftRadius: "16px",
                          // borderTopRightRadius: "16px",
                          // borderBottomLeftRadius: "16px",
                          // borderBottomRightRadius: "16px",
                          cursor: "pointer",
                        }}
                      />
                      {/* <div style={{ position: "absolute", bottom: 4, left: 4 }}>
                        <span className="text-white">
                          <em>*Artist Impression Only</em>
                        </span>
                      </div> */}
                    </div>
                  )}
                </Measure>
              ))}
            </Masonry>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  // const { error } = state.Home
  const { leftSideBarType } = state.Layout
  return { leftSideBarType }
}

export default withRouter(
  connect(mapStateToProps, { showJoinNowAction })(Gallery)
)

Gallery.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
  leftSideBarType: PropTypes.any,
}
