import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState, useRef } from "react"

import SimpleBar from "simplebar-react"

import {
  Row,
  Badge,
  Col,
  CardBody,
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardText,
  Alert,
  Container,
  Modal,
  Collapse,
  Spinner,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
  UncontrolledPopover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// actions
import {
  registerUser,
  loginUser,
  apiError,
  socialLogin,
  showJoinNowAction,
} from "store/actions"

//hapivity asset
import Slider from "react-rangeslider"
import "react-rangeslider/lib/index.css"

import imageRainforestVR from "assets/images/rainfora/facilities/vr/rainforest_vr.webp"
import imageSkyrunVR from "assets/images/rainfora/facilities/vr/skyrun_vr.webp"
import { ReactPhotoSphereViewer } from "react-photo-sphere-viewer"

import facilitiesPinBlue from "assets/images/rainfora/facilities/facilities-pin.png"
import facilitiesPinRed from "assets/images/rainfora/facilities/facilities-pin-important.png"

import FacilitiesPoint from "./FacilitiesPoint"

import Lightbox from "react-image-lightbox"
import "react-image-lightbox/style.css"

import imageEdibleGarden from "assets/images/rainfora/gallery/EdibleGarden.webp"
import imageGlampingForest from "assets/images/rainfora/gallery/4GlampingForest.webp"
import imageNaturePlayscape from "assets/images/rainfora/gallery/NaturePlayscape.webp"
import imageStudyGarden from "assets/images/rainfora/gallery/6StudyGarden.webp"
import imageRainforest from "assets/images/rainfora/gallery/2RainforestCanopy01.webp"
import imageSkyrun from "assets/images/rainfora/gallery/5JoggingTrail.webp"

import compassNorth from "assets/images/rainfora/north.png"

const facilitiesImages = {
  "edible": imageEdibleGarden,
  "glamping": imageGlampingForest,
  "playscape": imageNaturePlayscape,
  "study": imageStudyGarden,
  "skyrun": imageSkyrun,
  "rainforest": imageRainforest
}

import zoomAquaGym from "assets/images/rainfora/facilities/zoom/Aqua Gym.webp"
import zoomBBQTerrace from "assets/images/rainfora/facilities/zoom/BBQ Terrace.webp"
import zoomCabana from "assets/images/rainfora/facilities/zoom/Cabana.webp"
import zoomCoworkingSpace from "assets/images/rainfora/facilities/zoom/Co-Working Space.webp"
import zoomDiningTerrace from "assets/images/rainfora/facilities/zoom/Dining Terrace.webp"
import zoomGlampingForest from "assets/images/rainfora/facilities/zoom/Glamping Forest.webp"
import zoomGourmetKitchen from "assets/images/rainfora/facilities/zoom/Gourmet Kitchen.webp"
import zoomGym from "assets/images/rainfora/facilities/zoom/Gym.webp"
import zoomHammockNook from "assets/images/rainfora/facilities/zoom/Hammock Nook.webp"
import zoomInfinityPool from "assets/images/rainfora/facilities/zoom/Infinity Pool.webp"
import zoomJacuzzi from "assets/images/rainfora/facilities/zoom/Jacuzzi.webp"
import zoomKitchen from "assets/images/rainfora/facilities/zoom/Kitchen.webp"
import zoomLaundry from "assets/images/rainfora/facilities/zoom/Laundry.webp"
import zoomLoungerDeck from "assets/images/rainfora/facilities/zoom/Lounger Deck.webp"
import zoom20CommunityGarden from "assets/images/rainfora/facilities/zoom/Lvl20_Community Garden.webp"
import zoom20EdibleGarden from "assets/images/rainfora/facilities/zoom/Lvl20_Edible Garden.webp"
import zoom20PicnicCourtyard from "assets/images/rainfora/facilities/zoom/Lvl20_Picnic Courtyard.webp"
import zoom30CommunityGarden from "assets/images/rainfora/facilities/zoom/Lvl30_Community Garden.webp"
import zoom30EdibleGarden from "assets/images/rainfora/facilities/zoom/Lvl30_Edible Garden.webp"
import zoom30PicnicCourtyard from "assets/images/rainfora/facilities/zoom/Lvl30_Picnic Courtyard.webp"
import zoomMoonlitSkyrun from "assets/images/rainfora/facilities/zoom/Moonlit Skyrun.webp"
import zoomMPH from "assets/images/rainfora/facilities/zoom/MPH.webp"
import zoomMusicAndDanceRoom from "assets/images/rainfora/facilities/zoom/Music & Dance Room.webp"
import zoomNaturePlayscape from "assets/images/rainfora/facilities/zoom/Nature Playscape.webp"
import zoomOutdoorFitness from "assets/images/rainfora/facilities/zoom/Outdoor Fitness.webp"
import zoomPoolDeck from "assets/images/rainfora/facilities/zoom/Pool Deck.webp"
import zoomPoolPilates from "assets/images/rainfora/facilities/zoom/Pool Pilates.webp"
import zoomPoolShower from "assets/images/rainfora/facilities/zoom/Pool Shower.webp"
import zoomPoolTerrace from "assets/images/rainfora/facilities/zoom/Pool Terrace.webp"
import zoomRainforestCanopy from "assets/images/rainfora/facilities/zoom/Rainforest Canopy.webp"
import zoomReflexologyGarden from "assets/images/rainfora/facilities/zoom/Reflexology Garden.webp"
import zoomRestCorner from "assets/images/rainfora/facilities/zoom/Rest Corner.webp"
import zoomSauna from "assets/images/rainfora/facilities/zoom/Sauna.webp"
import zoomStretchLoop from "assets/images/rainfora/facilities/zoom/Stretch Loop.webp"
import zoomStudyGarden from "assets/images/rainfora/facilities/zoom/Study Garden.webp"
import zoomSwingNook from "assets/images/rainfora/facilities/zoom/Swing Nook.webp"
import zoomTabataPad from "assets/images/rainfora/facilities/zoom/Tabata Pad.webp"
import zoomWadingPool from "assets/images/rainfora/facilities/zoom/Wading Pool.webp"
import zoomYogaDeck from "assets/images/rainfora/facilities/zoom/Yoga Deck.webp"

const zoomImage = {
  "Rainforest Canopy": zoomRainforestCanopy,
  "Nature Playscape": zoomNaturePlayscape,
  "Study Garden": zoomStudyGarden,
  "Glamping Forest": zoomGlampingForest,
  "Gourmet Kitchen": zoomGourmetKitchen,
  "Pool Terrace": zoomPoolTerrace,
  "Wading Pool": zoomWadingPool,
  "Pool Shower": zoomPoolShower,
  "Infinity Pool *25m lap": zoomInfinityPool,
  "Music & Dance Room": zoomMusicAndDanceRoom,
  "Pool Deck": zoomPoolDeck,
  "Jacuzzi": zoomJacuzzi,
  "Lounger Deck": zoomLoungerDeck,
  "Pool Pilates": zoomPoolPilates,
  "Aqua Gym": zoomAquaGym,
  "Cabana": zoomCabana,
  "Hammock Nook": zoomHammockNook,
  "BBQ Terrace": zoomBBQTerrace,
  "Dining Terrace": zoomDiningTerrace,
  "Swing Nook": zoomSwingNook,
  "Yoga Deck": zoomYogaDeck,
  "Sauna": zoomSauna,
  "Kitchen": zoomKitchen,
  "Laundry": zoomLaundry,
  "MPH": zoomMPH,
  "Co-Working Space": zoomCoworkingSpace,
  "Gym": zoomGym,
  "⁠Moonlit Skyrun *150m": zoomMoonlitSkyrun,
  "Outdoor Fitness": zoomOutdoorFitness,
  "Tabata Pad": zoomTabataPad,
  "Rest Corner": zoomRestCorner,
  "Reflexology Garden": zoomReflexologyGarden,
  "Stretch Loop": zoomStretchLoop,
  "Edible Garden Lv20": zoom20EdibleGarden,
  "Edible Garden Lv30": zoom30EdibleGarden,
  "Picnic Courtyard Lv20": zoom20PicnicCourtyard,
  "Picnic Courtyard Lv30": zoom30PicnicCourtyard,
  "Community Garden Lv20": zoom20CommunityGarden,
  "Community Garden Lv30": zoom30CommunityGarden
}

const imageRooftop = require.context("assets/images/rainfora/facilities/rooftop", true)
const imageRooftopList = imageRooftop.keys().map(image => imageRooftop(image))
const imagePodium = require.context("assets/images/rainfora/facilities/podium", true)
const imagePodiumList = imagePodium.keys().map(image => imagePodium(image))

import imageFullRooftop from "assets/images/rainfora/facilities/Facilities_ZoomIn_Rooftop_FullHD.webp"
import imageFullPodium from "assets/images/rainfora/facilities/Facilities_ZoomIn_Podium_FullHD.webp"

const Facilities = props => {
  const history = useHistory()

  useEffect(() => {
    if(props.leftSideBarType == "default")
      setLeftSideBarSize(200)
    else
      setLeftSideBarSize(70)
  }, [props.leftSideBarType])

  const [leftSideBarSize, setLeftSideBarSize] = useState(70)
  
  const [def, setdef] = useState(50)
  const [dayNight, setDayNight] = useState(false)
  
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [facilitiesFloor, setFacilitiesFloor] = useState("Podium")
  const [facilitiesPin, setFacilitiesPin] = useState(FacilitiesPoint.podium)
  const [facilitiesClicked, setFacilitiesClicked] = useState(-1)

  const [facilitiesVR, setFacilitiesVR] = useState(false)
  const [facilitiesVRContent, setFacilitiesVRContent] = useState(
    imageRainforestVR
  )

  const [isFits, setisFits] = useState(false)
  const [lightBoxSrc, setLightboxSrc] = useState(null)
  const [lightBoxCaption, setLightboxCaption] = useState(null)

  const [isZoom, setIsZoom] = useState(false)

  const [optionShow, setOptionShow] = useState(false)

  const [floorWidth, setFloorWidth] = useState(0)
  const [floorHeight, setFloorHeight] = useState(0)
  const ref = useRef(null)

  const refSimpleBar = useRef()

  useEffect(() => {
    refSimpleBar.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        refSimpleBar.current.getScrollElement().scrollTop =
          currentPosition - 300
      }
    }
  }

  useEffect(() => {
    if (!ref.current) return
    setTimeout(() => {
      const { width, height } = ref.current.getBoundingClientRect()
      console.log("init", height)
      setFloorHeight(height / 1080)
      setFloorWidth(width / 1920)
    }, 1000)
  }, [])

  // useEffect(() => {
  //   if (!ref.current) return
  //   setTimeout(() => {
  //     const { width, height } = ref.current.getBoundingClientRect()
  //     console.log("init", height)
  //     setFloorHeight(height / 2160)
  //     setFloorWidth(width / 3840)
  //   }, 1000)
  // }, [leftSideBarSize])

  const simulateClick = elem => {
    let evt = new MouseEvent("click", {
      bubbles: true,
      view: window,
    })
    elem.dispatchEvent(evt)
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Rainfora</title>
      </MetaTags>
      <div
        className="page-content p-0"
        style={{
          backgroundImage: "radial-gradient(#164A3D, #01221F)",
          height: "100vh",
        }}
      >
        {imageRooftopList.map((item, index) => (
          <img
            src={imageRooftopList[index]}
            style={{ display: "none" }}
            key={"preload-rooftop-" + index}
          />
        ))}
        <img src={imageRainforestVR} style={{ display: "none" }} />
        <img src={imageSkyrunVR} style={{ display: "none" }} />
        {imagePodiumList.map((item, index) => (
          <img
            src={imagePodiumList[index]}
            style={{ display: "none" }}
            key={"preload-podium-" + index}
          />
        ))}
        {isFits ? (
          <Lightbox
            mainSrc={lightBoxSrc}
            enableZoom={false}
            imageTitle={lightBoxCaption}
            onCloseRequest={() => {
              setisFits(!isFits)
            }}
          />
        ) : null}
        {isZoom ? (
          <Lightbox
            mainSrc={
              facilitiesFloor == "Rooftop"
                ? imageRooftopList[def]
                : imagePodiumList[def]
            }
            enableZoom={true}
            onCloseRequest={() => {
              setIsZoom(!isZoom)
            }}
          />
        ) : null}
        <Row className="">
          <Col className="">
            <Card
              className="p-0 mb-0"
              style={{ backgroundColor: "rgba(52, 52, 52, 0.0)" }}
            >
              {!facilitiesVR && (
                <CardBody
                  className="p-4"
                  style={{
                    height: "100vh",
                  }}
                >
                  <Row className="m-0">
                    <Col style={{ paddingTop: "0px" }}>
                      <div
                        style={{ position: "relative", paddingBottom: "24px" }}
                        ref={ref}
                      >
                        {facilitiesPin.map(
                          (item, index) =>
                            item.top != 0 &&
                            item.left != 0 && (
                              <Row
                                key={"facilities-pin-" + index}
                                style={{
                                  position: "absolute",
                                  top: item.top * floorHeight - 30,
                                  left: item.left * floorWidth,
                                }}
                              >
                                <Col className="align-self-center p-0">
                                  <img
                                    src={
                                      item.perspective
                                        ? facilitiesPinBlue
                                        : facilitiesPinRed
                                    }
                                    style={{
                                      border: "none",
                                      cursor: "pointer",
                                      // opacity: 0.7,
                                    }}
                                    id={"pin" + index}
                                    className={
                                      facilitiesClicked == index
                                        ? "zoom-in-out-box"
                                        : ""
                                    }
                                    onClick={() => {
                                      setFacilitiesClicked(index)
                                    }}
                                  />
                                </Col>
                                {!isFits && (
                                  <UncontrolledPopover
                                    trigger="legacy"
                                    target={"pin" + index}
                                    placement="bottom"
                                  >
                                    <PopoverHeader>{item.title}</PopoverHeader>

                                    <PopoverBody>
                                      <button
                                        className={
                                          "btn text-white btn-outline-light btn-rounded font-weight-normal font-size-20 me-2"
                                        }
                                        onClick={() => {
                                          setisFits(true)
                                          setLightboxSrc(zoomImage[item.title])
                                          setLightboxCaption(item.title)
                                        }}
                                      >
                                        <i className="ph ph-magnifying-glass-plus"></i>
                                      </button>
                                      {item.perspective && (
                                        <button
                                          className={
                                            "btn text-white btn-outline-light btn-rounded font-weight-normal font-size-20 me-2"
                                          }
                                          onClick={() => {
                                            if (item.perspective) {
                                              setisFits(true)
                                              setLightboxSrc(
                                                facilitiesImages[
                                                  item.perspective
                                                ]
                                              )
                                              setLightboxCaption(item.title)
                                            }
                                          }}
                                        >
                                          <i className="ph ph-image-square"></i>
                                        </button>
                                      )}
                                      {item.full360 && (
                                        <button
                                          className={
                                            "btn text-white btn-outline-light btn-rounded font-weight-normal font-size-20 m-0"
                                          }
                                          onClick={() => {
                                            if (item.full360 == "skyrun") {
                                              setFacilitiesVR(true)
                                              setFacilitiesVRContent(
                                                imageSkyrunVR
                                              )
                                            }
                                            if (item.full360 == "rainforest") {
                                              setFacilitiesVR(true)
                                              setFacilitiesVRContent(
                                                imageRainforestVR
                                              )
                                            }
                                          }}
                                        >
                                          <i className="ph ph-cube-focus"></i>
                                        </button>
                                      )}
                                    </PopoverBody>
                                  </UncontrolledPopover>
                                )}
                              </Row>
                            )
                        )}

                        <CardImg
                          src={
                            facilitiesFloor == "Podium"
                              ? imageFullPodium //imagePodiumList[def]
                              : imageFullRooftop //imageRooftopList[def]
                          }
                          style={{
                            // height: "295px",
                            // height: "100vh",
                            objectFit: "contain",
                          }}
                        />
                        <Row
                          style={{
                            position: "absolute",
                            top: 0,
                            right: 0,
                            paddingTop: 0,
                            paddingRight: 0,
                            // right: 32,
                            // backgroundImage:
                            //   "linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0))",
                          }}
                          className="justify-content-end"
                        >
                          <Col className="col-auto">
                            <img
                              src={compassNorth}
                              style={{
                                width: 80,
                                height: 80,
                                rotate: "-90deg",
                              }}
                            />
                          </Col>
                        </Row>
                      </div>
                    </Col>
                  </Row>
                  <Row
                    style={{
                      position: "fixed",
                      top: 0,
                      left: 0,
                      right: 0,
                      paddingTop: 16,
                      paddingLeft: 32,
                      paddingRight: 32,
                      // right: 32,
                      // backgroundImage:
                      //   "linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0))",
                    }}
                  >
                    <Col>
                      <span className="text-primary font-size-56 font-weight-bold ">
                        {"FACILITIES"}
                      </span>
                    </Col>
                  </Row>
                  {/* <Row
                    style={{
                      position: "fixed",
                      bottom: 56,
                      left: leftSideBarSize + 300,
                      right: 300,
                    }}
                  >
                    <Col>
                      <Slider
                        value={def}
                        orientation="horizontal"
                        min={0}
                        max={100}
                        onChange={value => {
                          setdef(value)
                        }}
                        style={{
                          position: "fixed",
                          bottom: 30,
                          right: 10,
                        }}
                      />
                    </Col>
                  </Row> */}
                </CardBody>
              )}
              {facilitiesVR && (
                <ReactPhotoSphereViewer
                  src={facilitiesVRContent}
                  height={"100vh"}
                  width={"100%"}
                  maxFov={90}
                  minFov={50}
                  defaultZoomLvl={0}
                  navbar={false}
                ></ReactPhotoSphereViewer>
              )}
              {facilitiesVR && (
                <button
                  className={
                    "btn btn-primary text-color-919695 font-weight-normal font-size-16 border-none"
                  }
                  style={{
                    backgroundColor: "#002521CC",
                    backdropFilter: "blur(20px)",
                    height: "50px",
                    // width: "60px",
                    position: "fixed",
                    bottom: 16,
                    right: 32 + leftSideBarSize,
                    borderRadius: "30px",
                  }}
                  onClick={() => {
                    setFacilitiesVR(false)
                  }}
                >
                  Close <i className="ph ph-x"></i>
                </button>
              )}
              {facilitiesVR && (
                <Row
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    paddingTop: 16,
                    paddingLeft: 32,
                    paddingRight: 32,
                  }}
                >
                  <Col>
                    <span className="text-primary font-size-56 font-weight-bold text-uppercase">
                      {facilitiesPin[facilitiesClicked].title}
                    </span>
                  </Col>
                </Row>
              )}
              {/* {!isZoom && !optionShow && (
                <button
                  className={
                    "btn text-white btn-primary font-weight-normal font-size-22"
                  }
                  style={{
                    height: "60px",
                    width: "60px",
                    position: "fixed",
                    top: 16,
                    right: 108,
                    borderRadius: "30px",
                  }}
                  onClick={() => {
                    setIsZoom(!isZoom)
                  }}
                >
                  <i className="ph ph-magnifying-glass-plus"></i>
                </button>
              )} */}
            </Card>
          </Col>
        </Row>
        {!facilitiesVR && (
          <Card
            style={{
              backgroundColor: "#002521CC",
              width: "218px",
              backdropFilter: "blur(20px)",
              borderRadius: "20px",
              position: "fixed",
              bottom: 200,
              right: 32 + leftSideBarSize,
            }}
            className="px-3 py-2 mb-3"
          >
            <SimpleBar style={{ maxHeight: "300px" }} ref={refSimpleBar}>
              <Row className="px-0">
                <Col className="px-3">
                  {facilitiesPin.map(
                    (item, index) =>
                      item.listed && (
                        <Row key={"option-facilities-" + index}>
                          <Col className="col-auto">
                            <button
                              className={
                                "btn btn-link font-size-16 p-0 text-decoration-none text-start"
                              }
                              onClick={() => {
                                let pinControl = document.getElementById(
                                  "pin" + index
                                )
                                simulateClick(pinControl)
                                setFacilitiesClicked(index)
                              }}
                            >
                              <span
                                className={
                                  "font-size-16" +
                                  (item.perspective
                                    ? " text-primary"
                                    : " text-white")
                                }
                              >
                                <i className="ph-fill ph-circle me-2"></i>
                              </span>{" "}
                              <span
                                className={
                                  "font-size-16 " +
                                  (facilitiesClicked == index
                                    ? "text-primary"
                                    : "text-white")
                                }
                              >
                                {item.title}
                              </span>
                            </button>
                          </Col>
                        </Row>
                      )
                  )}
                </Col>
              </Row>
            </SimpleBar>
          </Card>
        )}
        {!facilitiesVR && (
          <Card
            style={{
              backgroundColor: "#002521CC",
              width: "218px",
              backdropFilter: "blur(20px)",
              borderRadius: "20px",
              position: "fixed",
              bottom: 0,
              right: 32 + leftSideBarSize,
            }}
            className="px-4 py-0 mb-3"
          >
            <Row>
              <Col>
                <Row className="my-2">
                  <button
                    className={
                      facilitiesFloor == "Podium"
                        ? "btn font-weight-normal font-size-18 px-3 text-start text-primary border-none navigation-option mm-active"
                        : "btn btn-outline-light btn-rounded font-weight-normal font-size-18 px-3 border-none text-start text-color-919695"
                    }
                    style={{
                      borderRadius: "20px",
                      height: 50,
                      whiteSpace: "nowrap",
                      // height: "50px",
                    }}
                    onClick={() => {
                      if (facilitiesFloor != "Podium") {
                        setFacilitiesFloor("Podium")
                        setFacilitiesPin(FacilitiesPoint.podium)
                        setFacilitiesClicked(null)
                      }
                    }}
                  >
                    <span>{"Podium"}</span>
                  </button>
                </Row>
                <Row className="my-2">
                  <button
                    className={
                      facilitiesFloor == "Level 20 & 30"
                        ? "btn font-weight-normal font-size-18 px-3 text-start text-primary border-none navigation-option mm-active"
                        : "btn btn-outline-light btn-rounded font-weight-normal font-size-18 px-3 border-none text-start text-color-919695"
                    }
                    style={{
                      borderRadius: "20px",
                      height: 50,
                      whiteSpace: "nowrap",
                      // height: "50px",
                    }}
                    onClick={() => {
                      if (facilitiesFloor != "Level 20 & 30") {
                        setFacilitiesFloor("Level 20 & 30")
                        setFacilitiesPin(FacilitiesPoint.level20)
                        setFacilitiesClicked(null)
                      }
                    }}
                  >
                    <span>{"Level 20/30"}</span>
                  </button>
                </Row>
                <Row className="my-2">
                  <button
                    className={
                      facilitiesFloor == "Rooftop"
                        ? "btn font-weight-normal font-size-18 px-3 text-start text-primary border-none navigation-option mm-active"
                        : "btn btn-outline-light btn-rounded font-weight-normal font-size-18 px-3 border-none text-start text-color-919695"
                    }
                    style={{
                      borderRadius: "20px",
                      height: 50,
                      whiteSpace: "nowrap",
                      // height: "50px",
                    }}
                    onClick={() => {
                      if (facilitiesFloor != "Rooftop") {
                        setFacilitiesFloor("Rooftop")
                        setFacilitiesPin(FacilitiesPoint.rooftop)
                        setFacilitiesClicked(null)
                      }
                    }}
                  >
                    <span>{"Rooftop"}</span>
                  </button>
                </Row>
              </Col>
            </Row>
          </Card>
        )}
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { leftMenu, leftSideBarType } = state.Layout
  return { leftMenu, leftSideBarType }
}

export default withRouter(
  connect(mapStateToProps, { showJoinNowAction })(Facilities)
)

Facilities.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
}
