import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect, useState, useRef, useLayoutEffect } from "react"

import {
  Row,
  Badge,
  Col,
  CardBody,
  Card,
  CardImg,
  CardImgOverlay,
  CardTitle,
  CardSubtitle,
  CardText,
  Alert,
  Container,
  Modal,
  Collapse,
  Spinner,
  Button,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Dropdown,
} from "reactstrap"

// Redux
import { connect } from "react-redux"
import { withRouter, Link, useHistory } from "react-router-dom"

// actions
import {
  registerUser,
  loginUser,
  apiError,
  socialLogin,
  showJoinNowAction,
} from "store/actions"

//hapivity asset
import Slider from "react-rangeslider"
import "react-rangeslider/lib/index.css"

import level9BalconyDay from "assets/images/rainfora/unit/balcony_view/09_Day.webp"
import level10BalconyDay from "assets/images/rainfora/unit/balcony_view/10_Day.webp"
import level11BalconyDay from "assets/images/rainfora/unit/balcony_view/11_Day.webp"
import level15BalconyDay from "assets/images/rainfora/unit/balcony_view/15_Day.webp"
import level20BalconyDay from "assets/images/rainfora/unit/balcony_view/20_Day.webp"
import level25BalconyDay from "assets/images/rainfora/unit/balcony_view/25_Day.webp"
import level30BalconyDay from "assets/images/rainfora/unit/balcony_view/30_Day.webp"
import level9BalconyNight from "assets/images/rainfora/unit/balcony_view/09_Night.webp"
import level10BalconyNight from "assets/images/rainfora/unit/balcony_view/10_Night.webp"
import level11BalconyNight from "assets/images/rainfora/unit/balcony_view/11_Night.webp"
import level15BalconyNight from "assets/images/rainfora/unit/balcony_view/15_Night.webp"
import level20BalconyNight from "assets/images/rainfora/unit/balcony_view/20_Night.webp"
import level25BalconyNight from "assets/images/rainfora/unit/balcony_view/25_Night.webp"
import level30BalconyNight from "assets/images/rainfora/unit/balcony_view/30_Night.webp"

import { ReactPhotoSphereViewer } from "react-photo-sphere-viewer"
import { CompassPlugin } from "@photo-sphere-viewer/compass-plugin"
import "@photo-sphere-viewer/compass-plugin/index.css"

import UnitPoint from "./unitPoint"

import iconTap from "assets/images/rainfora/unit/button-tap-icon.png"

import bReflectPlan from "assets/images/rainfora/unit/floor_plan/b-reflect.png"
import bPlan from "assets/images/rainfora/unit/floor_plan/b.png"
import b1ReflectPlan from "assets/images/rainfora/unit/floor_plan/b1-reflect.png"
import b1Plan from "assets/images/rainfora/unit/floor_plan/b1.png"
import cReflectPlan from "assets/images/rainfora/unit/floor_plan/c-reflect.png"
import cPlan from "assets/images/rainfora/unit/floor_plan/c.png"
import dReflectPlan from "assets/images/rainfora/unit/floor_plan/d-reflect.png"
import dPlan from "assets/images/rainfora/unit/floor_plan/d.png"
import eReflectPlan from "assets/images/rainfora/unit/floor_plan/e-reflect.png"
import ePlan from "assets/images/rainfora/unit/floor_plan/e.png"
import fReflectPlan from "assets/images/rainfora/unit/floor_plan/f-reflect.png"
import fPlan from "assets/images/rainfora/unit/floor_plan/f.png"
import gReflectPlan from "assets/images/rainfora/unit/floor_plan/g-reflect.png"
import gPlan from "assets/images/rainfora/unit/floor_plan/g.png"

import compassNorth from "assets/images/rainfora/north.png"

const imageUnit = require.context("assets/images/rainfora/unit/floor", true)
const imageUnitList = imageUnit.keys().map(image => imageUnit(image))
const image360List = [level9BalconyDay, level9BalconyNight, level10BalconyDay, level10BalconyNight, level11BalconyDay, level11BalconyNight, level15BalconyDay, level15BalconyNight, level20BalconyDay, level20BalconyNight, level25BalconyDay, level25BalconyNight, level30BalconyDay, level30BalconyNight];

const Unit = props => {
  const history = useHistory()

  const imagePlan = [
    bReflectPlan,
    bPlan,
    b1ReflectPlan,
    b1Plan,
    cReflectPlan,
    cPlan,
    dReflectPlan,
    dPlan,
    eReflectPlan,
    ePlan,
    fReflectPlan,
    fPlan,
    gReflectPlan,
    gPlan,
  ]

  useEffect(() => {
    if(props.leftSideBarType == "default")
      setLeftSideBarSize(200)
    else
      setLeftSideBarSize(70)
  }, [props.leftSideBarType])

  const [leftSideBarSize, setLeftSideBarSize] = useState(70)
  
  const [def, setdef] = useState(0)
  const [step, setStep] = useState(0)
  const [dayNight, setDayNight] = useState(true)

  const floorLabel = {
    0: "09",
    1: "10",
    2: "11",
    3: "12",
    4: "13",
    5: "14",
    6: "15",
    7: "16",
    8: "17",
    9: "18",
    10: "19",
    11: "20",
    12: "21",
    13: "22",
    14: "23",
    15: "24",
    16: "25",
    17: "26",
    18: "27",
    19: "28",
    20: "29",
    21: "30",
    22: "31",
  }

  const full360Step = ["30","25","20","15","11","10","09"]
  const full360ViewContent = {
    "09": { day: level9BalconyDay, night: level9BalconyNight },
    "10": { day: level10BalconyDay, night: level10BalconyNight },
    "11": { day: level11BalconyDay, night: level11BalconyNight },
    "15": { day: level15BalconyDay, night: level15BalconyNight },
    "20": { day: level20BalconyDay, night: level20BalconyNight },
    "25": { day: level25BalconyDay, night: level25BalconyNight },
    "30": { day: level30BalconyDay, night: level30BalconyNight },
  }

  const balconyLevel = {
    "09": "9",
    "10": "10",
    "11": "11",
    "12": "11",
    "13": "11",
    "14": "15",
    "15": "15",
    "16": "15",
    "17": "15",
    "18": "15",
    "19": "20",
    "20": "20",
    "21": "20",
    "22": "20",
    "23": "20",
    "24": "25",
    "25": "25",
    "26": "25",
    "27": "25",
    "28": "25",
    "29": "30",
    "30": "30",
    "31": "30",
  }

  const balconyViewContent = {
    "09": { day: level9BalconyDay, night: level9BalconyNight },
    "10": { day: level9BalconyDay, night: level9BalconyNight },
    "11": { day: level9BalconyDay, night: level9BalconyNight },
    "12": { day: level9BalconyDay, night: level9BalconyNight },
    "13": { day: level9BalconyDay, night: level9BalconyNight },
    "14": { day: level15BalconyDay, night: level15BalconyNight },
    "15": { day: level15BalconyDay, night: level15BalconyNight },
    "16": { day: level15BalconyDay, night: level15BalconyNight },
    "17": { day: level15BalconyDay, night: level15BalconyNight },
    "18": { day: level15BalconyDay, night: level15BalconyNight },
    "19": { day: level20BalconyDay, night: level20BalconyNight },
    "20": { day: level20BalconyDay, night: level20BalconyNight },
    "21": { day: level20BalconyDay, night: level20BalconyNight },
    "22": { day: level20BalconyDay, night: level20BalconyNight },
    "23": { day: level20BalconyDay, night: level20BalconyNight },
    "24": { day: level25BalconyDay, night: level25BalconyNight },
    "25": { day: level25BalconyDay, night: level25BalconyNight },
    "26": { day: level25BalconyDay, night: level25BalconyNight },
    "27": { day: level25BalconyDay, night: level25BalconyNight },
    "28": { day: level25BalconyDay, night: level25BalconyNight },
    "29": { day: level30BalconyDay, night: level30BalconyNight },
    "30": { day: level30BalconyDay, night: level30BalconyNight },
    "31": { day: level30BalconyDay, night: level30BalconyNight },
  }

  const [balconyViewVR, setbalconyViewVR] = useState(false)
  const [balconyViewVRContent, setbalconyViewVRContent] = useState(
    level9BalconyDay
  )
  const [balconyViewVRLevel, setbalconyViewVRLevel] = useState("09")
  const [balconyViewVRDirection, setbalconyViewVRDirection] = useState("South")

  const [full360ViewVR, setfull360ViewVR] = useState(false)
  const [full360ViewVRContent, setfull360ViewVRContent] = useState(
    level30BalconyDay
  )
  const [full360ViewVRLevel, setfull360ViewVRLevel] = useState("30")

  const [comparisonView, setComparisonView] = useState(false)

  const [currentCompareItem, setCurrentCompareItem] = useState(0)
  const [comparisonItem1, setComparisonItem1] = useState(null)
  const [comparisonItem2, setComparisonItem2] = useState(null)

  const floorDropDown = ["09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31"];
  const [selectedFloor1, setSelectedFloor1] = useState("09")
  const [selectedFloor2, setSelectedFloor2] = useState("09")
  const [dropdownOpenFloor1, setDropdownOpenFloor1] = useState(false)
  const [dropdownOpenFloor2, setDropdownOpenFloor2] = useState(false)
  const [unitDropdown1, setUnitDropdown1] = useState(UnitPoint[0])
  const [unitDropdown2, setUnitDropdown2] = useState(UnitPoint[0])
  const [dropdownOpenUnit1, setDropdownOpenUnit1] = useState(false)
  const [dropdownOpenUnit2, setDropdownOpenUnit2] = useState(false)

  const [floorWidth, setFloorWidth] = useState(0)
  const [floorHeight, setFloorHeight] = useState(0)
  const ref = useRef(null)
  const pSRef = useRef(null)
  const bvRef = useRef(null)

  const plugins = [
    [
      CompassPlugin,
      {
        size: "90px",
        position: 'top right'
        // hotspots: [
        //   { longitude: "0deg" },
        //   { longitude: "90deg" },
        //   { longitude: "180deg" },
        //   { longitude: "270deg" },
        // ],
      },
    ],
  ]

  useLayoutEffect(() => {
    if (!ref.current) return
    setTimeout(() => {
      const { width, height } = ref.current.getBoundingClientRect()
      console.log("init", height)
      setFloorHeight(height / 1238)
      setFloorWidth(width / 2200)
    }, 1000)
  }, [])

  useEffect(() => {    
    if (!ref.current) return
    setTimeout(() => {
      const { width, height } = ref.current.getBoundingClientRect()
      console.log("init", height)
      setFloorHeight(height / 1238)
      setFloorWidth(width / 2200)
    }, 1000)
  }, [leftSideBarSize])

  const getDirectionDegree = (direction) => {
    switch (direction) {
      case "East": return "90deg"
      case "West": return "270deg"
      case "North": return "0deg"
      case "South": return "180deg"
    }
  }

  const getVRfilename = (level, direction) => {
    setbalconyViewVRContent(
      "https://rainfora.studiomultiply.com/tv/balcony_view/" +
        "LEVEL_" +
        balconyLevel[level] +
        (dayNight ? "_DAY_" : "_NIGHT_") +
        direction.toUpperCase() +
        "/LEVEL_" +
        balconyLevel[level] +
        (dayNight ? "_DAY.html" : "_NIGHT.html")
    )
  }

  useEffect(() => {
    var iframeElement = document.getElementById("balconyViewIframe")
    
    getVRfilename(balconyViewVRLevel, balconyViewVRDirection)
    
    if (iframeElement && balconyViewVRContent && balconyViewVRContent != "") {
      document.getElementById("balconyViewIframe").src = balconyViewVRContent
    }
  }, [balconyViewVRContent, dayNight])

  const getFloorPlan = (type) => {
    switch (type) {
      case "B":
        return bPlan
        break
      case "B(M)":
        return bReflectPlan
        break
      case "B1":
        return b1Plan
        break
      case "B1(M)":
        return b1ReflectPlan
        break
      case "C":
        return cPlan
        break
      case "C(M)":
        return cReflectPlan
        break
      case "D":
        return dPlan
        break
      case "D(M)":
        return dReflectPlan
        break
      case "E":
        return ePlan
        break
      case "E(M)":
        return eReflectPlan
        break
      case "F":
        return fPlan
        break
      case "F(M)":
        return fReflectPlan
        break
      case "G":
        return gPlan
        break
      case "G(M)":
        return gReflectPlan
        break
    }
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Rainfora</title>
      </MetaTags>
      <div
        className="page-content p-0"
        style={{
          backgroundImage: "radial-gradient(#164A3D, #01221F)",
          height: "100vh",
        }}
      >
        {imageUnitList.map((item, index) => (
          <img
            src={imageUnitList[index]}
            style={{ display: "none" }}
            key={"preload-unit-" + index}
          />
        ))}

        {image360List.map((item, index) => (
          <img
            src={imageUnitList[index]}
            style={{ display: "none" }}
            key={"preload-360-" + index}
          />
        ))}

        {imagePlan.map((item, index) => (
          <img
            src={imagePlan[index]}
            style={{ display: "none" }}
            key={"preload-plan-" + index}
          />
        ))}
        <Row className="">
          <Col className="">
            <Card
              className="p-0 mb-0"
              style={{
                // backgroundImage: "radial-gradient(#164A3D, #01221F)",
                backgroundColor: "rgba(52, 52, 52, 0.0)",
              }}
            >
              {!balconyViewVR && !comparisonView && !full360ViewVR && (
                <CardBody
                  className="p-0"
                  style={{
                    height: "100%",
                  }}
                >
                  <Row className="justify-content-center">
                    <Col
                      className="col-9"
                      // style={{ overflowY: "-moz-hidden-unscrollable" }}
                    >
                      <Row className="m-0">
                        <Col style={{ paddingTop: "15vh" }}>
                          <div style={{ position: "relative" }} ref={ref}>
                            {UnitPoint &&
                              UnitPoint[def].map(
                                (item, index) =>
                                  
                                    <button
                                      key={"point" + index}
                                      className={
                                        "btn btn-primary font-weight-normal font-size-16 px-3 bg-transparent"
                                      }
                                      style={{
                                        position: "absolute",
                                        top:
                                          (item.top - def * 10) * floorHeight,
                                        left: item.left * floorWidth,
                                        borderWidth: 0,
                                        width: item.width * floorWidth,
                                        height: item.height * floorHeight,
                                      }}
                                      onClick={() => {
                                        if (currentCompareItem == 0) {
                                          setComparisonItem1(item)
                                          setSelectedFloor1(item.level)
                                          setCurrentCompareItem(1)
                                        } else {
                                          setComparisonItem2(item)
                                          setSelectedFloor2(item.level)
                                          setCurrentCompareItem(0)
                                        }
                                      }}
                                    >
                                      <span>{""}</span>
                                    </button>
                                  
                              )}
                            <CardImg
                              src={imageUnitList[def]}
                              // className="p-5"
                              style={{
                                // height: "295px",
                                // height: "100vh",
                                // maxHeight: "80vh",
                                objectFit: "contain",
                              }}
                            />
                          </div>
                          <Row
                            style={{
                              position: "absolute",
                              bottom: 100,
                              top: "30%",
                              // left: leftSideBarSize + 300,
                              right: 0,
                            }}
                          >
                            <Col>
                              <Slider
                                value={def}
                                orientation="vertical"
                                min={0}
                                max={22}
                                onChange={value => {
                                  setdef(value)
                                }}
                                tooltip={false}
                                // labels={floorLabel}
                                // handleLabel={floorLabel[def]}
                                // style={{
                                //   position: "absolute",
                                //   bottom: 100,
                                //   right: 10,
                                // }}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                      <Row
                        style={{
                          position: "fixed",
                          top: 0,
                          left: 0,
                          right: 0,
                          paddingTop: 16,
                          paddingLeft: 32,
                          paddingRight: 32,
                          zIndex: 1,
                          // right: 32,
                          // backgroundImage:
                          //   "linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0))",
                        }}
                        className="justify-content-between align-items-center"
                      >
                        <Col className="col-auto">
                          <span className="text-primary font-size-56 font-weight-bold me-4">
                            {"UNIT TYPE"}
                          </span>
                        </Col>
                      </Row>
                      <Row
                        style={{
                          position: "absolute",
                          top: 0,
                          left: 0,
                          right: 0,
                          paddingTop: "8vh",
                          paddingLeft: 32,
                          paddingRight: 32,
                          zIndex: 1,
                          // right: 32,
                          // backgroundImage:
                          //   "linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0))",
                        }}
                        className="justify-content-center align-items-center"
                      >
                        <Col className="col-auto">
                          <span className="text-white font-size-40 font-weight-bold ">
                            {"Level " + floorLabel[def]}
                          </span>
                        </Col>
                      </Row>
                      <Card
                        style={{
                          backgroundColor: "#002521CC",
                          width: "168px",
                          backdropFilter: "blur(20px)",
                          borderRadius: "20px",
                          position: "fixed",
                          bottom: 0,
                          right: 32 + leftSideBarSize,
                          zIndex: 10,
                        }}
                        className="px-4 py-0 mb-3"
                      >
                        <Row>
                          <Col>
                            <Row className="my-2">
                              <button
                                className={
                                  "btn font-weight-normal font-size-18 px-3 text-start text-primary border-none navigation-option mm-active"
                                }
                                style={{
                                  borderRadius: "20px",
                                  height: 50,
                                  whiteSpace: "nowrap",
                                  // height: "50px",
                                }}
                                onClick={() => {}}
                              >
                                <span>{"Overview"}</span>
                              </button>
                            </Row>
                            <Row className="my-2">
                              <button
                                className={
                                  "btn btn-outline-light btn-rounded font-weight-normal font-size-18 px-3 border-none text-start text-color-919695"
                                }
                                style={{
                                  borderRadius: "20px",
                                  height: 50,
                                  whiteSpace: "nowrap",
                                  // height: "50px",
                                }}
                                onClick={() => {
                                  setComparisonView(true)
                                }}
                              >
                                <span>{"Comparison"}</span>
                              </button>
                            </Row>
                            <Row className="my-2">
                              <button
                                className={
                                  "btn btn-outline-light btn-rounded font-weight-normal font-size-18 px-3 border-none text-start text-color-919695"
                                }
                                style={{
                                  borderRadius: "20px",
                                  height: 50,
                                  whiteSpace: "nowrap",
                                  // height: "50px",
                                }}
                                onClick={() => {
                                  setfull360ViewVR(true)
                                }}
                              >
                                <span>{"Full 360 View"}</span>
                              </button>
                            </Row>
                          </Col>
                        </Row>
                      </Card>
                      <Row
                        style={{
                          position: "absolute",
                          top: 0,
                          right: 0,
                          paddingTop: 16,
                          // right: 32,
                          // backgroundImage:
                          //   "linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0))",
                        }}
                        className="justify-content-end"
                      >
                        <Col className="col-auto">
                          <img
                            src={compassNorth}
                            style={{ width: 80, height: 80, rotate: "-90deg" }}
                          />
                        </Col>
                      </Row>
                    </Col>
                    <Row
                      style={{
                        position: "fixed",
                        bottom: 0,
                        left: 0,
                        right: 32 + leftSideBarSize,
                        zIndex: 1,
                        // right: 32,
                        // backgroundImage:
                        //   "linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0))",
                      }}
                      className="justify-content-center align-items-center"
                    >
                      <Col className="col-auto">
                        {comparisonItem1 ? (
                          <Card
                            className="m-3"
                            style={{
                              backgroundColor: "#002521CC",
                              width: 350,
                              height: 166,
                              backdropFilter: "blur(20px)",
                              borderRadius: "20px",
                            }}
                          >
                            <CardBody
                              className="p-3 px-4 mb-3"
                              style={{
                                borderRadius: "20px",
                              }}
                            >
                              <Row className="justify-content-center">
                                <Col className="col-auto">
                                  <CardTitle className="my-0 text-primary font-size-20 ">
                                    {"Type " +
                                      comparisonItem1.type +
                                      " (" +
                                      comparisonItem1.block +
                                      "-" +
                                      comparisonItem1.level +
                                      "-" +
                                      comparisonItem1.unit +
                                      ")"}
                                  </CardTitle>
                                </Col>
                              </Row>
                              <Row className="justify-content-between">
                                <Col className="col-auto">
                                  <CardSubtitle className="mt-3 font-size-16 text-white">
                                    <span className="text-start">
                                      {comparisonItem1.sqft}
                                    </span>
                                  </CardSubtitle>
                                </Col>
                                <Col className="col-auto">
                                  <CardSubtitle className="mt-3 font-size-16 text-white">
                                    <span>
                                      {comparisonItem1.bedroom} Bedroom{" "}
                                      {comparisonItem1.bathroom} Bathroom
                                    </span>
                                  </CardSubtitle>
                                </Col>
                              </Row>

                              <Row className="justify-content-center">
                                <Col className="col-auto">
                                  <button
                                    className={
                                      "btn btn-primary btn-rounded font-weight-normal font-size-14 px-3 mt-3"
                                    }
                                    style={
                                      {
                                        // position: "absolute",
                                        // left: def + "%",
                                        // borderRadius: "25px",
                                        // whiteSpace: "nowrap",
                                        // height: "50px",
                                      }
                                    }
                                    onClick={() => {
                                      setbalconyViewVR(true)
                                      // setbalconyViewVRContent(
                                      //   balconyViewContent[
                                      //     comparisonItem1.level
                                      //   ].day
                                      // )
                                      getVRfilename(
                                        comparisonItem1.level,
                                        comparisonItem1.face
                                      )
                                      setbalconyViewVRLevel(
                                        comparisonItem1.level
                                      )
                                      setbalconyViewVRDirection(
                                        comparisonItem1.face
                                      )
                                    }}
                                  >
                                    <span>{"Balcony View"}</span>
                                  </button>
                                </Col>
                                <Col className="col-auto">
                                  <button
                                    className={
                                      "btn btn-primary btn-rounded font-weight-normal font-size-14 px-3 mt-3"
                                    }
                                    style={
                                      {
                                        // position: "absolute",
                                        // left: def + "%",
                                        // borderRadius: "25px",
                                        // whiteSpace: "nowrap",
                                        // height: "50px",
                                      }
                                    }
                                    onClick={() => {
                                      setComparisonView(true)
                                    }}
                                  >
                                    <span>{"Compare"}</span>
                                  </button>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        ) : (
                          <Card
                            className="m-3"
                            style={{
                              backgroundColor: "#002521CC",
                              width: 350,
                              height: 166,
                              backdropFilter: "blur(20px)",
                              borderRadius: "20px",
                            }}
                          >
                            <CardBody
                              className="p-3 px-4 mb-3 align-items-center"
                              style={{
                                borderRadius: "20px",
                                // marginTop: 20,
                              }}
                            >
                              <Row className="justify-content-center mb-3">
                                <Col className="col-auto">
                                  <CardSubtitle className="mt-3 font-size-16 text-white">
                                    <span className="text-center">
                                      {"Select Unit On The Building"}
                                    </span>
                                  </CardSubtitle>
                                </Col>
                              </Row>
                              <CardImg
                                src={iconTap}
                                style={{
                                  // height: "295px",
                                  // height: "100vh",
                                  maxHeight: 50,
                                  objectFit: "contain",
                                }}
                              />
                            </CardBody>
                          </Card>
                        )}
                      </Col>
                      <Col className="col-auto">
                        {comparisonItem2 ? (
                          <Card
                            className="m-3"
                            style={{
                              backgroundColor: "#002521CC",
                              width: 350,
                              height: 166,
                              backdropFilter: "blur(20px)",
                              borderRadius: "20px",
                            }}
                          >
                            <CardBody
                              className="p-3 px-4 mb-3"
                              style={{
                                borderRadius: "20px",
                              }}
                            >
                              <Row className="justify-content-center">
                                <Col className="col-auto">
                                  <CardTitle className="my-0 text-primary font-size-20 ">
                                    {"Type " +
                                      comparisonItem2.type +
                                      " (" +
                                      comparisonItem2.block +
                                      "-" +
                                      comparisonItem2.level +
                                      "-" +
                                      comparisonItem2.unit +
                                      ")"}
                                  </CardTitle>
                                </Col>
                              </Row>
                              <Row className="justify-content-between">
                                <Col className="col-auto">
                                  <CardSubtitle className="mt-3 font-size-16 text-white">
                                    <span className="text-start">
                                      {comparisonItem2.sqft}
                                    </span>
                                  </CardSubtitle>
                                </Col>
                                <Col className="col-auto">
                                  <CardSubtitle className="mt-3 font-size-16 text-white">
                                    <span>
                                      {comparisonItem2.bedroom} Bedroom{" "}
                                      {comparisonItem2.bathroom} Bathroom
                                    </span>
                                  </CardSubtitle>
                                </Col>
                              </Row>

                              <Row className="justify-content-center">
                                <Col className="col-auto">
                                  <button
                                    className={
                                      "btn btn-primary btn-rounded font-weight-normal font-size-14 px-3 mt-3"
                                    }
                                    style={
                                      {
                                        // position: "absolute",
                                        // left: def + "%",
                                        // borderRadius: "25px",
                                        // whiteSpace: "nowrap",
                                        // height: "50px",
                                      }
                                    }
                                    onClick={() => {
                                      setbalconyViewVR(true)
                                      // setbalconyViewVRContent(
                                      //   balconyViewContent[
                                      //     comparisonItem2.level
                                      //   ].day
                                      // )
                                      getVRfilename(
                                        comparisonItem2.level,
                                        comparisonItem2.face
                                      )
                                      setbalconyViewVRLevel(
                                        comparisonItem2.level
                                      )
                                      setbalconyViewVRDirection(
                                        comparisonItem2.face
                                      )
                                    }}
                                  >
                                    <span>{"Balcony View"}</span>
                                  </button>
                                </Col>
                                <Col className="col-auto">
                                  <button
                                    className={
                                      "btn btn-primary btn-rounded font-weight-normal font-size-14 px-3 mt-3"
                                    }
                                    style={
                                      {
                                        // position: "absolute",
                                        // left: def + "%",
                                        // borderRadius: "25px",
                                        // whiteSpace: "nowrap",
                                        // height: "50px",
                                      }
                                    }
                                    onClick={() => {
                                      setComparisonView(true)
                                    }}
                                  >
                                    <span>{"Compare"}</span>
                                  </button>
                                </Col>
                              </Row>
                            </CardBody>
                          </Card>
                        ) : (
                          <Card
                            className="m-3"
                            style={{
                              backgroundColor: "#002521CC",
                              width: 350,
                              height: 166,
                              backdropFilter: "blur(20px)",
                              borderRadius: "20px",
                            }}
                          >
                            <CardBody
                              className="p-3 px-4 mb-3 align-items-center"
                              style={{
                                borderRadius: "20px",
                                // marginTop: 20,
                              }}
                            >
                              <Row className="justify-content-center mb-3">
                                <Col className="col-auto">
                                  <CardSubtitle className="mt-3 font-size-16 text-white">
                                    <span className="text-center">
                                      {"Select Unit On The Building"}
                                    </span>
                                  </CardSubtitle>
                                </Col>
                              </Row>
                              <CardImg
                                src={iconTap}
                                style={{
                                  // height: "295px",
                                  // height: "100vh",
                                  maxHeight: 50,
                                  objectFit: "contain",
                                }}
                              />
                            </CardBody>
                          </Card>
                        )}
                      </Col>
                    </Row>
                  </Row>
                </CardBody>
              )}
              {balconyViewVR && (
                <div
                  className="embed-responsive embed-responsive-16by9 ratio ratio-16x9"
                  style={{
                    maxHeight: "100vh",
                    backgroundImage: "radial-gradient(#164A3D, #01221F)",
                  }}
                >
                  <iframe
                    width="560"
                    height="315"
                    src={balconyViewVRContent}
                    id="balconyViewIframe"
                  ></iframe>
                </div>
                // <ReactPhotoSphereViewer
                //   src={balconyViewVRContent}
                //   height={"100vh"}
                //   width={"100%"}
                //   maxFov={90}
                //   minFov={50}
                //   defaultYaw={getDirectionDegree(balconyViewVRDirection)}
                //   defaultZoomLvl={0}
                //   navbar={false}
                //   ref={bvRef}
                //   // panoData={(image, xmpData) => ({
                //   //   croppedWidth: image.width / 2,
                //   //   croppedX: (image.width / 8) * getDirectionCrop(balconyViewVRDirection),
                //   // })}
                // ></ReactPhotoSphereViewer>
              )}
              {balconyViewVR && (
                <Row
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    paddingTop: 16,
                    paddingLeft: 32,
                    paddingRight: 32,
                    // right: 32,
                    backgroundImage:
                      "linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0))",
                  }}
                >
                  <Col>
                    <span className="text-primary font-size-56 font-weight-bold ">
                      {"Level " + balconyViewVRLevel}
                    </span>
                  </Col>
                </Row>
              )}
              {balconyViewVR && (
                <Card
                  style={{
                    backgroundColor: "#002521CC",
                    width: "125px",
                    backdropFilter: "blur(20px)",
                    borderRadius: "34px",
                    position: "fixed",
                    bottom: 0,
                    right: 32 + leftSideBarSize,
                  }}
                  className="px-4 py-0 mb-3"
                >
                  <Row className="justify-content-around py-2">
                    <Col className="col-auto px-0">
                      <button
                        className={
                          "btn btn-outline-light btn-rounded font-weight-normal font-size-18 border-none text-center text-color-919695" +
                          (dayNight ? " navigation-option mm-active" : "")
                        }
                        style={{
                          // borderRadius: "20px",
                          height: 50,
                          width: 50,
                          whiteSpace: "nowrap",
                          // height: "50px",
                        }}
                        onClick={() => {
                          setDayNight(true)
                          getVRfilename(balconyViewVRLevel,balconyViewVRDirection)
                          // bvRef.current.setPanorama(
                          //   balconyViewContent[balconyViewVRLevel].day,
                          //   {
                          //     showLoader: false,
                          //   }
                          // )
                        }}
                      >
                        <i className="ph ph-sun"></i>
                      </button>
                    </Col>
                    <Col className="col-auto px-0">
                      <button
                        className={
                          "btn font-weight-normal font-size-18 text-center text-color-919695 border-none" +
                          (dayNight ? "" : " navigation-option mm-active")
                        }
                        style={{
                          // borderRadius: "20px",
                          height: 50,
                          width: 50,
                          whiteSpace: "nowrap",
                          // height: "50px",
                        }}
                        onClick={() => {
                          setDayNight(false)
                          getVRfilename(
                            balconyViewVRLevel,
                            balconyViewVRDirection
                          )
                          // bvRef.current.setPanorama(
                          //   balconyViewContent[balconyViewVRLevel].night,
                          //   {
                          //     showLoader: false,
                          //   }
                          // )
                        }}
                      >
                        <i className="ph ph-moon"></i>
                      </button>
                    </Col>
                  </Row>
                </Card>
              )}
              {balconyViewVR && (
                <button
                  className={
                    "btn btn-primary text-color-919695 font-weight-normal font-size-16 border-none px-3"
                  }
                  style={{
                    backgroundColor: "#002521CC",
                    backdropFilter: "blur(20px)",
                    height: "50px",
                    // width: "60px",
                    position: "fixed",
                    bottom: 16,
                    right: 32 + leftSideBarSize + 125 + 16,
                    borderRadius: "30px",
                  }}
                  onClick={() => {
                    setbalconyViewVR(false)
                  }}
                >
                  Close <i className="ph ph-x"></i>
                </button>
              )}
              {full360ViewVR && (
                <ReactPhotoSphereViewer
                  ref={pSRef}
                  src={full360ViewVRContent}
                  height={"100vh"}
                  width={"100%"}
                  maxFov={90}
                  minFov={50}
                  defaultYaw={"90deg"}
                  defaultZoomLvl={0}
                  // sphereCorrection={{pan:Math.PI/2, tilt: 0, roll:0}}
                  // hideNavbarButton={true}
                  // moveSpeed={0}
                  plugins={plugins}
                  navbar={false}
                  // mousemove={false}
                ></ReactPhotoSphereViewer>
              )}
              {full360ViewVR && (
                <Row
                  style={{
                    position: "fixed",
                    top: 0,
                    left: 0,
                    right: 0,
                    paddingTop: 16,
                    paddingLeft: 32,
                    paddingRight: 32,
                    // right: 32,
                    backgroundImage:
                      "linear-gradient(rgba(0,0,0,0.6), rgba(0,0,0,0))",
                  }}
                  className="align-items-center"
                >
                  <Col>
                    <span
                      className="text-primary font-size-56 font-weight-bold "
                      style={{ whiteSpace: "nowrap" }}
                    >
                      {"Level " + full360ViewVRLevel}
                    </span>
                  </Col>
                </Row>
              )}
              {full360ViewVR && (
                <Card
                  style={{
                    backgroundColor: "#002521CC",
                    width: "125px",
                    backdropFilter: "blur(20px)",
                    borderRadius: "34px",
                    position: "fixed",
                    bottom: 0,
                    right: 32 + leftSideBarSize + 128 + 16,
                  }}
                  className="px-4 py-0 mb-3"
                >
                  <Row className="justify-content-around py-2">
                    <Col className="col-auto px-0">
                      <button
                        className={
                          "btn btn-outline-light btn-rounded font-weight-normal font-size-18 border-none text-center text-color-919695" +
                          (dayNight ? " navigation-option mm-active" : "")
                        }
                        style={{
                          // borderRadius: "20px",
                          height: 50,
                          width: 50,
                          whiteSpace: "nowrap",
                          // height: "50px",
                        }}
                        onClick={() => {
                          setDayNight(true)
                          pSRef.current.setPanorama(
                            full360ViewContent[full360ViewVRLevel].day,
                            {
                              showLoader: false,
                            }
                          )
                        }}
                      >
                        <i className="ph ph-sun"></i>
                      </button>
                    </Col>
                    <Col className="col-auto px-0">
                      <button
                        className={
                          "btn font-weight-normal font-size-18 text-center text-color-919695 border-none" +
                          (dayNight ? "" : " navigation-option mm-active")
                        }
                        style={{
                          // borderRadius: "20px",
                          height: 50,
                          width: 50,
                          whiteSpace: "nowrap",
                          // height: "50px",
                        }}
                        onClick={() => {
                          setDayNight(false)
                          pSRef.current.setPanorama(
                            full360ViewContent[full360ViewVRLevel].night,
                            {
                              showLoader: false,
                            }
                          )
                        }}
                      >
                        <i className="ph ph-moon"></i>
                      </button>
                    </Col>
                  </Row>
                </Card>
              )}
              {full360ViewVR && (
                <Card
                  style={{
                    backgroundColor: "#002521CC",
                    width: "128px",
                    backdropFilter: "blur(20px)",
                    borderRadius: "20px",
                    position: "fixed",
                    bottom: 0,
                    right: 32 + leftSideBarSize,
                  }}
                  className="px-4 py-0 mb-3"
                >
                  <Row>
                    <Col>
                      {full360Step.map((item, index) => (
                        <Row className="my-2">
                          <button
                            key={"level-360-" + index}
                            className={
                              full360ViewVRLevel == item
                                ? "btn font-weight-normal font-size-18 px-3 text-start text-primary border-none navigation-option mm-active"
                                : "btn btn-outline-light btn-rounded font-weight-normal font-size-18 px-3 border-none text-start text-color-919695"
                            }
                            style={{
                              borderRadius: "20px",
                              height: 50,
                              whiteSpace: "nowrap",
                              // height: "50px",
                            }}
                            onClick={() => {
                              pSRef.current.setPanorama(
                                dayNight
                                  ? full360ViewContent[item].day
                                  : full360ViewContent[item].night,
                                {
                                  showLoader: false,
                                }
                              )
                              setfull360ViewVRLevel(item)
                            }}
                          >
                            <span>{"Level " + item}</span>
                          </button>
                        </Row>
                      ))}
                    </Col>
                  </Row>
                </Card>
              )}
              {full360ViewVR && (
                <button
                  className={
                    "btn btn-primary text-color-919695 font-weight-normal font-size-16 border-none px-3"
                  }
                  style={{
                    backgroundColor: "#002521CC",
                    backdropFilter: "blur(20px)",
                    height: "50px",
                    // width: "60px",
                    position: "fixed",
                    bottom: 16,
                    right: 32 + leftSideBarSize + 128 + 16 + 125 + 16,
                    borderRadius: "30px",
                  }}
                  onClick={() => {
                    setfull360ViewVR(false)
                  }}
                >
                  Close <i className="ph ph-x"></i>
                </button>
              )}
              {comparisonView && !full360ViewVR && (
                <CardBody
                  className="p-0"
                  style={{
                    height: "100vh",
                  }}
                >
                  <Row
                    style={{
                      position: "fixed",
                      top: 0,
                      left: 0,
                      right: 0,
                      paddingTop: 16,
                      paddingLeft: 32,
                      paddingRight: 32,
                      // right: 32,
                    }}
                    className="align-items-center"
                  >
                    <Col>
                      <span className="text-primary font-size-56 font-weight-bold ">
                        {"UNIT TYPE"}
                      </span>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "100px" }}>
                    <Col>
                      <Row className="justify-content-center">
                        <Col className="col-4">
                          <Card
                            className="m-3"
                            style={{
                              background: "rgb(97, 100, 99, 0.35)",
                              borderRadius: "20px",
                            }}
                          >
                            <CardBody
                              className="p-3 px-4 mb-3"
                              style={{
                                borderRadius: "20px",
                                minHeight: "80vh",
                              }}
                            >
                              <Row className="justify-content-center">
                                <Col className="col-auto">
                                  <CardTitle className="mt-0 text-primary font-size-24 ">
                                    {comparisonItem1 &&
                                      "Type " + comparisonItem1.type}
                                  </CardTitle>
                                </Col>
                              </Row>
                              <Row
                                style={{
                                  position: "absolute",
                                  bottom: 16,
                                  left: 0,
                                  right: 0,
                                }}
                                className="justify-content-center"
                              >
                                <Col className="col-auto">
                                  <Dropdown
                                    isOpen={dropdownOpenFloor1}
                                    toggle={() => {
                                      setDropdownOpenFloor1(!dropdownOpenFloor1)
                                    }}
                                    direction="up"
                                  >
                                    <DropdownToggle
                                      caret
                                      className={
                                        "btn btn-outline-light btn-rounded text-white font-weight-normal font-size-14 px-3"
                                      }
                                    >
                                      {selectedFloor1
                                        ? "Level " + selectedFloor1
                                        : "Select Floor"}{" "}
                                      <i className="mdi mdi-chevron-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      {floorDropDown.map((item, index) => (
                                        <DropdownItem
                                          onClick={() => {
                                            setUnitDropdown1(UnitPoint[index])
                                            setSelectedFloor1(item)
                                          }}
                                        >
                                          {"Level " + item}
                                        </DropdownItem>
                                      ))}
                                    </DropdownMenu>
                                  </Dropdown>
                                </Col>
                                <Col className="col-auto">
                                  <Dropdown
                                    isOpen={dropdownOpenUnit1}
                                    toggle={() => {
                                      setDropdownOpenUnit1(!dropdownOpenUnit1)
                                    }}
                                    direction="up"
                                  >
                                    <DropdownToggle
                                      caret
                                      className={
                                        "btn btn-outline-light btn-rounded text-white font-weight-normal font-size-14 px-3"
                                      }
                                    >
                                      {comparisonItem1
                                        ? comparisonItem1.block +
                                          "-" +
                                          comparisonItem1.level +
                                          "-" +
                                          comparisonItem1.unit
                                        : "Select Unit"}{" "}
                                      <i className="mdi mdi-chevron-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      {unitDropdown1.map(
                                        (item, index) =>
                                          
                                            <DropdownItem
                                              onClick={() => {
                                                setComparisonItem1(item)
                                                setSelectedFloor1(item.level)
                                              }}
                                            >
                                              {item.block +
                                                "-" +
                                                item.level +
                                                "-" +
                                                item.unit}
                                            </DropdownItem>
                                          
                                      )}
                                    </DropdownMenu>
                                  </Dropdown>
                                </Col>
                              </Row>
                              <Row className="justify-content-center">
                                <Col className="col-auto">
                                  <CardSubtitle className="mt-2 font-size-16 text-white">
                                    <span className="text-start">
                                      {comparisonItem1 && comparisonItem1.sqft}
                                    </span>
                                  </CardSubtitle>
                                </Col>
                                <Col className="col-auto">
                                  <CardSubtitle className="mt-2 font-size-16 text-white">
                                    {comparisonItem1 && (
                                      <span>
                                        {comparisonItem1.bedroom} Bedroom{" "}
                                        {comparisonItem1.bathroom} Bathroom
                                      </span>
                                    )}
                                  </CardSubtitle>
                                </Col>
                              </Row>
                              {comparisonItem1 && (
                                <CardImg
                                  src={getFloorPlan(comparisonItem1.type)}
                                  style={{
                                    // height: "295px",
                                    // height: "100vh",
                                    maxHeight: "63vh",
                                    objectFit: "contain",
                                  }}
                                />
                              )}
                            </CardBody>
                          </Card>
                        </Col>
                        <Col className="col-4">
                          <Card
                            className="m-3"
                            style={{
                              background: "rgb(97, 100, 99, 0.35)",
                              borderRadius: "20px",
                            }}
                          >
                            <CardBody
                              className="p-3 px-4 mb-3"
                              style={{
                                borderRadius: "20px",
                                minHeight: "80vh",
                              }}
                            >
                              <Row className="justify-content-center">
                                <Col className="col-auto">
                                  <CardTitle className="mt-0 text-primary font-size-24">
                                    {comparisonItem2 &&
                                      "Type " + comparisonItem2.type}
                                  </CardTitle>
                                </Col>
                              </Row>
                              <Row
                                style={{
                                  position: "absolute",
                                  bottom: 16,
                                  left: 0,
                                  right: 0,
                                }}
                                className="justify-content-center"
                              >
                                <Col className="col-auto">
                                  <Dropdown
                                    isOpen={dropdownOpenFloor2}
                                    toggle={() => {
                                      setDropdownOpenFloor2(!dropdownOpenFloor2)
                                    }}
                                    direction="up"
                                  >
                                    <DropdownToggle
                                      caret
                                      className={
                                        "btn btn-outline-light btn-rounded text-white font-weight-normal font-size-14 px-3"
                                      }
                                    >
                                      {selectedFloor2
                                        ? "Level " + selectedFloor2
                                        : "Select Floor"}{" "}
                                      <i className="mdi mdi-chevron-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      {floorDropDown.map((item, index) => (
                                        <DropdownItem
                                          onClick={() => {
                                            setUnitDropdown2(UnitPoint[index])
                                            setSelectedFloor1(item)
                                          }}
                                        >
                                          {"Level " + item}
                                        </DropdownItem>
                                      ))}
                                    </DropdownMenu>
                                  </Dropdown>
                                </Col>
                                <Col className="col-auto">
                                  <Dropdown
                                    isOpen={dropdownOpenUnit2}
                                    toggle={() => {
                                      setDropdownOpenUnit2(!dropdownOpenUnit2)
                                    }}
                                    direction="up"
                                  >
                                    <DropdownToggle
                                      caret
                                      className={
                                        "btn btn-outline-light btn-rounded text-white font-weight-normal font-size-14 px-3"
                                      }
                                    >
                                      {comparisonItem2
                                        ? comparisonItem2.block +
                                          "-" +
                                          comparisonItem2.level +
                                          "-" +
                                          comparisonItem2.unit
                                        : "Select Unit"}{" "}
                                      <i className="mdi mdi-chevron-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      {unitDropdown2.map(
                                        (item, index) =>
                                          
                                            <DropdownItem
                                              onClick={() => {
                                                setComparisonItem2(item)
                                                setSelectedFloor2(item.level)
                                              }}
                                            >
                                              {item.block +
                                                "-" +
                                                item.level +
                                                "-" +
                                                item.unit}
                                            </DropdownItem>
                                          
                                      )}
                                    </DropdownMenu>
                                  </Dropdown>
                                </Col>
                              </Row>
                              <Row className="justify-content-center">
                                <Col className="col-auto">
                                  <CardSubtitle className="mt-2 font-size-16 text-white">
                                    <span className="text-start">
                                      {comparisonItem2 && comparisonItem2.sqft}
                                    </span>
                                  </CardSubtitle>
                                </Col>
                                <Col className="col-auto">
                                  <CardSubtitle className="mt-2 font-size-16 text-white">
                                    {comparisonItem2 && (
                                      <span>
                                        {comparisonItem2.bedroom} Bedroom{" "}
                                        {comparisonItem2.bathroom} Bathroom
                                      </span>
                                    )}
                                  </CardSubtitle>
                                </Col>
                              </Row>
                              {comparisonItem2 && (
                                <CardImg
                                  src={getFloorPlan(comparisonItem2.type)}
                                  style={{
                                    // height: "295px",
                                    // height: "100vh",
                                    maxHeight: "63vh",
                                    objectFit: "contain",
                                  }}
                                />
                              )}
                            </CardBody>
                          </Card>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                  <Card
                    style={{
                      backgroundColor: "#002521CC",
                      width: "168px",
                      backdropFilter: "blur(20px)",
                      borderRadius: "20px",
                      position: "fixed",
                      bottom: 0,
                      right: 32 + leftSideBarSize,
                    }}
                    className="px-4 py-0 mb-3"
                  >
                    <Row>
                      <Col>
                        <Row className="my-2">
                          <button
                            className={
                              "btn btn-outline-light btn-rounded font-weight-normal font-size-18 px-3 border-none text-start text-color-919695"
                            }
                            style={{
                              borderRadius: "20px",
                              height: 50,
                              whiteSpace: "nowrap",
                              // height: "50px",
                            }}
                            onClick={() => {
                              setComparisonView(false)
                            }}
                          >
                            <span>{"Overview"}</span>
                          </button>
                        </Row>
                        <Row className="my-2">
                          <button
                            className={
                              "btn font-weight-normal font-size-18 px-3 text-start text-primary border-none navigation-option mm-active"
                            }
                            style={{
                              borderRadius: "20px",
                              height: 50,
                              whiteSpace: "nowrap",
                              // height: "50px",
                            }}
                            onClick={() => {
                              setComparisonView(true)
                            }}
                          >
                            <span>{"Comparison"}</span>
                          </button>
                        </Row>
                        <Row className="my-2">
                          <button
                            className={
                              "btn btn-outline-light btn-rounded font-weight-normal font-size-18 px-3 border-none text-start text-color-919695"
                            }
                            style={{
                              borderRadius: "20px",
                              height: 50,
                              whiteSpace: "nowrap",
                              // height: "50px",
                            }}
                            onClick={() => {
                              setfull360ViewVR(true)
                            }}
                          >
                            <span>{"Full 360 View"}</span>
                          </button>
                        </Row>
                      </Col>
                    </Row>
                  </Card>
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  )
}

const mapStateToProps = state => {
  const { leftMenu, leftSideBarType } = state.Layout
  return { leftMenu, leftSideBarType }
}

export default withRouter(
  connect(mapStateToProps, { showJoinNowAction })(Unit)
)

Unit.propTypes = {
  error: PropTypes.any,
  history: PropTypes.object,
  loginUser: PropTypes.func,
  socialLogin: PropTypes.func,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
}
