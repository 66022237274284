import PropTypes from "prop-types"
import React, { useEffect, useRef, useState } from "react"
import { connect } from "react-redux"
// //Import Scrollbar
import SimpleBar from "simplebar-react"

// MetisMenu
import MetisMenu from "metismenujs"
import { withRouter } from "react-router-dom"
import { Link } from "react-router-dom"

// Redux Store
import { toggleLeftmenu, changeSidebarType } from "../../store/actions"

//i18n
import { withTranslation } from "react-i18next"

const SidebarContent = props => {
  const ref = useRef()
  // Use ComponentDidMount and ComponentDidUpdate method symultaniously

  const [isFullscreen, setIsFullscreen] = useState(false)

  // Watch for fullscreenchange
  useEffect(() => {
    function onFullscreenChange() {
      setIsFullscreen(Boolean(document.fullscreenElement))
    }

    document.addEventListener("fullscreenchange", onFullscreenChange)

    return () =>
      document.removeEventListener("fullscreenchange", onFullscreenChange)
  }, [])

  useEffect(() => {
    const pathName = props.location.pathname

    const initMenu = () => {
      new MetisMenu("#side-menu")
      let matchingMenuItem = null
      const ul = document.getElementById("side-menu")
      const items = ul.getElementsByTagName("a")
      for (let i = 0; i < items.length; ++i) {
        if (pathName === items[i].pathname) {
          matchingMenuItem = items[i]
          break
        }
      }
      if (matchingMenuItem) {
        activateParentDropdown(matchingMenuItem)
      }
    }
    initMenu()
  }, [props.location.pathname])

  useEffect(() => {
    ref.current.recalculate()
  })

  function scrollElement(item) {
    if (item) {
      const currentPosition = item.offsetTop
      if (currentPosition > window.innerHeight) {
        ref.current.getScrollElement().scrollTop = currentPosition - 300
      }
    }
  }

  function activateParentDropdown(item) {
    item.classList.add("active")
    const parent = item.parentElement
    const parent2El = parent.childNodes[1]
    if (parent2El && parent2El.id !== "side-menu") {
      parent2El.classList.add("mm-show")
    }

    if (parent) {
      parent.classList.add("mm-active")
      const parent2 = parent.parentElement

      if (parent2) {
        parent2.classList.add("mm-show") // ul tag

        const parent3 = parent2.parentElement // li tag

        if (parent3) {
          parent3.classList.add("mm-active") // li
          parent3.childNodes[0].classList.add("mm-active") //a
          const parent4 = parent3.parentElement // ul
          if (parent4) {
            parent4.classList.add("mm-show") // ul
            const parent5 = parent4.parentElement
            if (parent5) {
              parent5.classList.add("mm-show") // li
              parent5.childNodes[0].classList.add("mm-active") // a tag
            }
          }
        }
      }
      scrollElement(item)
      return false
    }
    scrollElement(item)
    return false
  }

  function tToggle() {
    props.toggleLeftmenu(!props.leftMenu)
    if (props.leftSideBarType === "default") {
      // props.changeSidebarType("condensed", isMobile)
      props.changeSidebarType("condensed", true)
    } else if (props.leftSideBarType === "condensed") {
      // props.changeSidebarType("default", isMobile)
      props.changeSidebarType("default", true)
    }
  }

  return (
    <React.Fragment>
      <SimpleBar style={{ maxHeight: "100%", padding: 4 }} ref={ref}>
        <div id="sidebar-menu">
          <ul className="metismenu list-unstyled" id="side-menu">
            <li className="sidebar-bordered">
              <a
                onClick={() => {
                  tToggle()
                }}
              >
                {props.leftSideBarType === "condensed" ? (
                  <i className="ph ph-arrows-out-line-horizontal"></i>
                ) : (
                  <i className="ph ph-arrows-in-line-horizontal"></i>
                )}
              </a>
            </li>
            <li className="sidebar-bordered">
              <Link to="/home" className="waves-effect">
                <i className="ph ph-house-simple"></i>
                <span className="font-size-18">{"Home"}</span>
              </Link>
            </li>
            <li className="sidebar-bordered">
              <Link to="/surrounding">
                <i className="ph ph-map-trifold"></i>
                <span className="font-size-18">{"Location"}</span>
              </Link>
            </li>
            {/* <li className="sidebar-bordered">
              <Link to="/facade">
                <i className="ph ph-house-line"></i>
                <span className="font-size-18">{"Facade View"}</span>
              </Link>
            </li> */}
            <li className="sidebar-bordered">
              <Link to="/facilities">
                <i className="ph ph-tipi"></i>
                <span className="font-size-18">{"Facilities"}</span>
              </Link>
            </li>
            <li className="sidebar-bordered">
              <Link to="/unit_type">
                <i className="ph ph-elevator"></i>
                <span className="font-size-18">{"Unit Type"}</span>
              </Link>
            </li>
            <li className="sidebar-bordered">
              <Link to="/interior_tour">
                <i className="ph ph-cube-transparent"></i>
                <span className="font-size-18">{"Interior Tour"}</span>
              </Link>
            </li>
            {/* <li className="sidebar-bordered">
              <Link to="/retail">
                <i className="ph ph-stairs"></i>
                <span className="font-size-18">{"Retail"}</span>
              </Link>
            </li> */}
            <li className="sidebar-bordered">
              <Link to="/gallery">
                <i className="ph ph-images-square"></i>
                <span className="font-size-18">{"Gallery"}</span>
              </Link>
            </li>
            <li className="sidebar-bordered">
              <Link to="/info">
                <i className="ph ph-info"></i>
                <span className="font-size-18">{"About"}</span>
              </Link>
            </li>
            {/* <li style={{ position: "fixed", bottom: 0, width: 80 }}>
              <a
                onClick={() => {
                  if (isFullscreen) {
                    document.exitFullscreen()
                    setIsFullscreen(false)
                  } else {
                    document.body.requestFullscreen()
                    setIsFullscreen(true)
                  }
                }}
              >
                {isFullscreen ? (
                  <i className="ph ph-arrows-in"></i>
                ) : (
                  <i className="ph ph-arrows-out"></i>
                )}
              </a>
            </li> */}
          </ul>
        </div>
      </SimpleBar>
    </React.Fragment>
  )
}

SidebarContent.propTypes = {
  location: PropTypes.object,
  t: PropTypes.any,
  leftMenu: PropTypes.any,
  leftSideBarType: PropTypes.any,
  changeSidebarType: PropTypes.func,
}

const mapStatetoProps = state => {
  const {
    leftMenu,
    leftSideBarType,
  } = state.Layout
  return { leftMenu, leftSideBarType }
}

export default withRouter(
  connect(mapStatetoProps, {
    toggleLeftmenu,
    changeSidebarType,
  })(withTranslation()(SidebarContent))
)

// export default withRouter(withTranslation()(SidebarContent))
